import React from "react";
import styled from "styled-components";
import { Box } from "styles/layout";
import { media } from "styles/media";

interface PopUpProps {
    contents: any;
    id: boolean;
    handler: any;
    hasBackButton?: boolean | undefined;
}

interface ContainerProps {
    open: boolean;
}

const Container = styled.div<ContainerProps>`
    display: ${({ open }) => (open === true ? "block" : "none")};
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    padding: 0px;
`;

const BackgroundLayer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
`;

const Contents = styled(Box)`
    position: absolute;
    width: 1175px;
    height: 90%;
    top: 0px;
    right: 0px;
    padding: 0px;
    z-index: 100;
    border-radius: 16px 0 0 16px;
    background-color: #f5f5f5;
    ${media.tablet} {
        width: 1175px;
        height: 100%;
        right: 0px;
    }
`;

const PopUpBody = styled.div``;

export const FullPopUp: React.FC<PopUpProps> = ({ contents, id, handler }) => {
    return (
        <Container open={id}>
            <BackgroundLayer
                onClick={() => {
                    handler(false);
                }}
            />
            <Contents>
                <PopUpBody>{contents}</PopUpBody>
            </Contents>
        </Container>
    );
};
