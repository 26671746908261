import React, { useState } from "react";
import styled from "styled-components";
import { BoardTitle, BoardBox, StudentList, Student, Name } from "./OnBoard";
import _ from "lodash";
import ToolTipVector from "assets/images/icons/tooltip_box_vector.svg";

interface OffBoardProps {
    stationId: number;
    studentId: number | undefined;
    detail?: any;
}

export const OffBoard: React.FC<OffBoardProps> = ({
    stationId,
    studentId,
    detail,
}) => {
    const [showPeriod, setShowPeriod] = useState(false);
    const [studentName, setStudentName] = useState<string>("");
    let offBoardCounts = 0;

    if (_.isEmpty(detail) === false) {
        detail.map((student: any) => {
            if (stationId === Number(student?.takeOffStationId)) {
                offBoardCounts = offBoardCounts + 1;
            }
            return null;
        });
    }

    const hoverStudentName = (name: string) => {
        setShowPeriod(true);
        setStudentName(name);
    };

    const leaveStudentName = () => {
        setShowPeriod(false);
        setStudentName("");
    };

    return offBoardCounts > 0 ? (
        <BoardBox>
            <OffBoardTitle>
                <span>하차</span>
            </OffBoardTitle>
            <StudentList>
                {detail?.map((student: any, index: any) => {
                    if (stationId === Number(student?.takeOffStationId)) {
                        return (
                            <div key={student.id}>
                                <Student
                                    thisStudentId={student?.id}
                                    studentId={studentId}
                                    onMouseEnter={() =>
                                        hoverStudentName(student.name)
                                    }
                                    onMouseLeave={leaveStudentName}
                                >
                                    <Name
                                        to={`/students/${student?.id}`}
                                        title={"학생 페이지로 이동"}
                                        target={"blank"}
                                    >
                                        {student?.nickName === "" ||
                                        student?.nickName === "-"
                                            ? ""
                                            : `${student?.nickName} / `}
                                        {`${
                                            student?.name
                                        }(${student.parentPhone.slice(-4)})`}
                                    </Name>
                                    <div style={{ position: "relative" }}>
                                        {showPeriod &&
                                            studentName === student.name && (
                                                <>
                                                    <ToolTipBoxVector />
                                                    <ToolTip>
                                                        <PeriodSpan>
                                                            승차 일자 :
                                                        </PeriodSpan>
                                                        <br />
                                                        {student?.tickets?.map(
                                                            (
                                                                item: any,
                                                                idx: number
                                                            ) => {
                                                                return (
                                                                    <PeriodSpan
                                                                        key={
                                                                            idx
                                                                        }
                                                                    >
                                                                        {item}
                                                                    </PeriodSpan>
                                                                );
                                                            }
                                                        )}
                                                    </ToolTip>
                                                </>
                                            )}
                                    </div>
                                    {/* B2C학생 생길 경우 활성화 필요 */}
                                    {/* {schedule.isB2CUser ? (
                                                    <p>
                                                        {showNickName
                                                            ? schedule.student?.nickName === ""
                                                                ? `${schedule.student?.name}(B2C)`
                                                                : `${schedule.student?.name} - ${schedule.student?.nickName}(B2C)`
                                                            : `${schedule.student?.name}(B2C)`}
                                                    </p>
                                                ) : (
                                    )} */}
                                </Student>
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
            </StudentList>
        </BoardBox>
    ) : null;
};

const OffBoardTitle = styled(BoardTitle)`
    background-color: ${({ theme }) => theme.colors.blue};
    span {
    }
`;

const ToolTip = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    flex-direction: column;
    position: absolute;
    width: 163px;
    padding: 12px;
    border-radius: 4px;
    background: #333;
    top: 8px;
    left: -35px;
    z-index: 9;
`;

const ToolTipBoxVector = styled.div`
    position: absolute;
    top: 3px;
    left: 40px;
    width: 8px;
    height: 5px;
    background-image: url(${ToolTipVector});
`;

const PeriodSpan = styled.span`
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
`;
