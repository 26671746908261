import axios from "axios";
import { createBrowserHistory } from "history";
export let history = createBrowserHistory({ basename: "" });

const freeKey = "9dcb7f8f-b42e-49e6-8b74-2df47b56798b";
const payKey = "63004139-6ab4-4adf-b555-594c30ef0dc7";

const tMapAxiosInstance = axios.create({
    baseURL: "https://apis.openapi.sk.com/tmap",
    timeout: 5000,
    headers: {
        appKey: freeKey,
        "Content-Type": "application/json",
    },
});

tMapAxiosInstance.interceptors.request.use((request) => {
    if (request.headers.appKey === payKey) {
        request.url = "/routes/routeOptimization30?version=1&format=json";
    }
    return request;
});
tMapAxiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        /**
        {
            error: {
                id: "429",
                category: "gw",
                code: "QUOTA_EXCEEDED",
                message: "Limit Exceeded"
            }
        }
        무료 제공 API 종료 시 error.response.data에 위와 같은 데이터로 넘어옴
         */
        const tMapErrorData = error.response.data.error;
        if (
            Number(tMapErrorData.id) === 429 &&
            tMapErrorData.message === "Limit Exceeded"
        ) {
            if (tMapAxiosInstance.defaults.headers.appKey === freeKey) {
                tMapAxiosInstance.defaults.headers.appKey = payKey;
                error.response.config.headers.appKey = payKey;

                // 유료 appKey사용 시 T-Map 계약이 '경유지 순서 최적화 30'으로 되어 있어서 해당 API로 변경
                error.response.config.url =
                    "/routes/routeOptimization30?version=1&format=json";

                return tMapAxiosInstance.request(error.response.config);
            } else {
                return error.response;
            }
        }
        return Promise.reject(error);
    }
);

class Request {
    constructor() {
        this.token = "";
    }
    getHeader = (code, type = "application/json") => {
        const headers = {
            "Client-Type": "web",
            "Content-Type": type,
            "Access-Control-Allow-Origin": "*",
        };

        const accessToken = localStorage.getItem("accessToken");
        // console.log(accessToken);
        if (
            accessToken !== null &&
            accessToken !== "" &&
            ["COMMON"].includes(code) === false
        ) {
            this.token = "Bearer " + localStorage.getItem("accessToken");
            headers["Authorization"] =
                this.token !== undefined ? this.token : "";
        }
        return headers;
    };

    executeError = (error) => {
        console.log(error);
        if (error.response !== undefined) {
            console.log(error.response.data);
            //  toast.error({ title: `[${error.response.data.code}] ${error.response.data.message}` }, { autoClose: 1500 })
            if (error.response.status === 401) {
                //  toast.error({ title: `로그인 실패`, msg: `로그인 세션이 만료되었습니다.` }, { autoClose: "N" })
                localStorage.removeItem("accessToken");
                history.push("/login");
            }
        }
    };

    getApiHost = (url) => {
        let arr = url.split("/");
        let apiHost = process.env.REACT_APP_API_HOST;
        let code = "DEFAULT";

        if (arr.length > 0 && ["login"].includes(arr[1])) {
            apiHost = process.env.REACT_APP_AUTH_API_HOST;
            code = "AUTH";
        }

        return { apiHost, code };
    };

    getAuthApiHost = (url) => {
        let apiHost = process.env.REACT_APP_AUTH_API_HOST;
        let code = "DEFAULT";

        return { apiHost, code };
    };

    getTMapRoutesOptimization = (request) => {
        const path = request.path;
        let callback = request.callback;
        let errorCallback = request.errorCallback;
        let data = request.data;

        tMapAxiosInstance
            .post(path, !!data ? data : null)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                this.executeError(error);
                if (errorCallback !== undefined) {
                    errorCallback(error);
                }
            });
    };

    get = (url, request) => {
        const rs = this.getApiHost(url);
        const apiHost = rs.apiHost;
        let callback = request.callback;
        let errorCallback = request.errorCallback;
        let params = request.params;

        const includeAcademyId = !!request.includeAcademyId;
        if (includeAcademyId) {
            if (params) {
                params.academyId = Number(localStorage.getItem("academyId"));
            } else {
                params = {
                    academyId: Number(localStorage.getItem("academyId")),
                };
            }
        }

        axios
            .get(apiHost + url, {
                headers: this.getHeader(rs.code),
                params: params !== undefined ? params : {},
            })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                this.executeError(error);
                if (errorCallback !== undefined) {
                    errorCallback(error);
                }
                if (error.response !== undefined) {
                    if (error.response.status !== 401) {
                        //  toast.error({ title: "서버요청 오류가 발생했습니다 : error code[" + error.response.data.error_code + "]" }, { autoClose: "N" })
                    }
                } else {
                    //  toast.error({ title: "서버요청 오류가 발생했습니다." }, { autoClose: "N" })
                }
            });
    };

    getAuth = (url, request) => {
        const rs = this.getAuthApiHost(url);
        const apiHost = rs.apiHost;
        let callback = request.callback;
        let errorCallback = request.errorCallback;
        let params = request.params;
        axios
            .get(apiHost + url, {
                headers: this.getHeader(rs.code),
                params: params !== undefined ? params : {},
            })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                this.executeError(error);
                if (errorCallback !== undefined) {
                    errorCallback(error);
                }
                if (error.response !== undefined) {
                    if (error.response.status !== 401) {
                        //  toast.error({ title: "서버요청 오류가 발생했습니다 : error code[" + error.response.data.error_code + "]" }, { autoClose: "N" })
                    }
                } else {
                    //  toast.error({ title: "서버요청 오류가 발생했습니다." }, { autoClose: "N" })
                }
            });
    };

    post = (url, request, type = "application/json") => {
        const rs = this.getApiHost(url);
        const apiHost = rs.apiHost;
        let data = request.data;
        let callback = request.callback;
        let errorCallback = request.errorCallback;

        const includeAcademyId = !!request.includeAcademyId;
        if (includeAcademyId) {
            if (data) {
                data.academyId = Number(localStorage.getItem("academyId"));
            } else {
                data = { academyId: Number(localStorage.getItem("academyId")) };
            }
        }

        axios
            .post(apiHost + url, data !== undefined ? data : null, {
                headers: this.getHeader(rs.code, type),
            })
            .then((response) => {
                callback(response);
                console.log(response);
            })
            .catch((error) => {
                this.executeError(error);
                if (errorCallback !== undefined) {
                    errorCallback(error);
                }
                if (error.response !== undefined) {
                    if (error.response.status !== 401) {
                        //  toast.error({ title: "서버요청 오류가 발생했습니다 : error code[" + error.response.data.error_code + "]" }, { autoClose: "N" });
                    }
                } else {
                    //  toast.error({ title: "서버요청 오류가 발생했습니다." }, { autoClose: "N" });
                }
            });
    };

    put = (url, request) => {
        const rs = this.getApiHost(url);
        const apiHost = rs.apiHost;
        let data = request.data;
        let callback = request.callback;
        let errorCallback = request.errorCallback;

        const includeAcademyId = !!request.includeAcademyId;
        if (includeAcademyId) {
            if (data) {
                data.academyId = Number(localStorage.getItem("academyId"));
            } else {
                data = { academyId: Number(localStorage.getItem("academyId")) };
            }
        }

        axios
            .put(apiHost + url, data !== undefined ? data : null, {
                headers: this.getHeader(rs.code),
            })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                this.executeError(error);
                if (errorCallback !== undefined) {
                    errorCallback(error);
                }
                if (error.response !== undefined) {
                    if (error.response.status !== 401) {
                        //  toast.error({ title: "서버요청 오류가 발생했습니다 : error code[" + error.response.data.error_code + "]" }, { autoClose: "N" })
                    }
                } else {
                    //  toast.error({ title: "서버요청 오류가 발생했습니다." }, { autoClose: "N" })
                }
            });
    };

    del = (url, request) => {
        const rs = this.getApiHost(url);
        const apiHost = rs.apiHost;
        let data = request.data;
        let callback = request.callback;
        let errorCallback = request.errorCallback;
        axios
            .delete(apiHost + url, {
                data: data !== undefined ? data : {},
                headers: this.getHeader(rs.code),
            })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                this.executeError(error);
                if (errorCallback !== undefined) {
                    errorCallback(error);
                }
                if (error.response !== undefined) {
                    if (error.response.status !== 401) {
                        //  toast.error({ title: "서버요청 오류가 발생했습니다 : error code[" + error.response.data.error_code + "]" }, { autoClose: "N" })
                    }
                } else {
                    //  toast.error({ title: "서버요청 오류가 발생했습니다." }, { autoClose: "N" })
                }
            });
    };
}

export default Request;
