import { BusServiceContext } from "context/busService";
import { GlobalContext } from "context/global";
import StudentRequest from "http/student";
import React, { useContext } from "react";
import { styled } from "styles/theme";
import { Button, ButtonGrey, ButtonGroup } from "styles/ui";
const studentRequest = new StudentRequest();

interface ConfirmBoardSwitchProps {
    schedule: any;
    boardSwitch: number;
    handler: any;
}

const Title = styled.h3`
    margin-bottom: 1em;
    width: 100%;
    text-align: center;
`;
const Text = styled.p`
    font-weight: normal;
    width: 100%;
    text-align: center;
    line-height: 1.6;
    font-size: 14px;
    margin-bottom: 20px;
    span {
        color: ${({ theme }) => theme.colors.orange};
        font-weight: 800;
    }
`;

export const ConfirmBoardSwitch: React.FC<ConfirmBoardSwitchProps> = ({ schedule, boardSwitch, handler }) => {
    const { setToast } = useContext(GlobalContext);
    const { reloadTrigger, setReloadTrigger } = useContext(BusServiceContext);
    const updateBoardSwitch = (boardSwitch: number) => {
        const data = {
            boardSwitch: boardSwitch
        };

        studentRequest.updateBoardSwitch(
            Number(schedule.scheduleId),
            Number(schedule.student.id),
            data,
            (response: any) => {
                console.log(response);
                if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                    console.log(`[${response.data.code}] ${response.data.message}`);
                    setToast({
                        toggle: true,
                        toastStatus: "fail",
                        message: `오류가 발생했습니다. ${response.data.message}`
                    });
                } else {
                    // alert("변경을 완료하였습니다.");
                    setReloadTrigger(reloadTrigger + 1);
                    handler(false);
                    setToast({
                        toggle: true,
                        toastStatus: "success",
                        message: `변경되었습니다.`
                    });
                }
            },
            (error: any) => {
                console.log(error);
            }
        );
    };

    return (
        <div>
            <Title>승차 여부 변경</Title>
            <Text>
                {schedule.student.name} 학생을
                <br />
                <span>'{boardSwitch === 1 ? "타요" : "안타요"}'</span>로 변경하시겠습니까?
            </Text>
            <ButtonGroup>
                <ButtonGrey
                    onClick={e => {
                        e.preventDefault();
                        setReloadTrigger(reloadTrigger + 1);
                        handler(false);
                    }}
                >
                    취소
                </ButtonGrey>
                <Button
                    onClick={e => {
                        e.preventDefault();
                        console.log(boardSwitch);
                        updateBoardSwitch(boardSwitch);
                    }}
                >
                    변경
                </Button>
            </ButtonGroup>
        </div>
    );
};
