import React, { useContext, useState, useEffect } from "react";
import { BoxTitle, Count } from "./BusServiceStatus";
import { Box } from "styles/layout";
import { Icon } from "styles/ui";
import AllStudentsIcon from "assets/images/icons/all_students_icon.svg";
import NewStudentsIcon from "assets/images/icons/new_students_icon.svg";
import styled from "styled-components";
import dayjs from "dayjs";
import { AuthContext } from "context/auth";
import { media } from "styles/media";
import { Link } from "react-router-dom";
import StudentRequest from "http/student";
const studentRequest = new StudentRequest();

interface StudentsStatusProps {}

const StudentsStatusWrap = styled.div`
    ${Box} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        :last-child {
            margin-top: 5px;
            ${Count} {
                color: ${({ theme }) => theme.colors.orange};
            }
        }
        ${BoxTitle} {
            h3 {
                margin-left: 8px;
            }
            ${media.tablet} {
                h3 {
                    position: relative;
                    top: auto;
                    left: auto;
                }
            }
        }
    }
    ${media.tablet} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        ${Box} {
            margin: 0;
            flex-basis: calc(50% - 10px);
            padding: 12px 38px;
            ${Icon} {
                width: 42px;
                height: 42px;
            }
            h3 {
                position: relative;
                top: auto;
                left: auto;
                margin-left: 12px;
            }
            ${Count} {
                font-size: 24px;
                margin-bottom: 0;
            }
            :last-child {
                margin: 0;
            }
        }
    }
`;

interface dataInterface {
    studentCnt?: number;
}
export const StudentsStatus: React.FC<StudentsStatusProps> = () => {
    const { academyId } = useContext(AuthContext);
    const today = dayjs(new Date()).format("YYYY-MM-DD");
    const [data, setData] = useState<dataInterface>({
        studentCnt: 0
    });
    const [newData, setNewData] = useState<dataInterface>({
        studentCnt: 0
    });

    // 30초 마다 전체학생 & 오늘신규학생 갱신
    useEffect(() => {
        const getData = async () => {
            // 전체 학생
            await studentRequest.getStudentsCount(
                (response: any) => {
                    if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                        console.log(`[${response.data.code}] ${response.data.message}`);
                    } else {
                        setData(response.data.data);
                    }
                },
                (error: any) => {}
            );

            // 오늘 신규 학생
            await studentRequest.getNewStudentsCount(
                (response: any) => {
                    if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                        console.log(`[${response.data.code}] ${response.data.message}`);
                    } else {
                        setNewData(response.data.data);
                    }
                },
                (error: any) => {}
            );
        };
        getData();

        const timer = setInterval(getData, 30000);

        return () => {
            clearInterval(timer);
        };
    }, [today, academyId]);

    return (
        <StudentsStatusWrap>
            <Box>
                <BoxTitle>
                    <Icon img={AllStudentsIcon} width={"32px"} height={"32px"} />
                    <Link to="/students">
                        <h3>전체 학생</h3>
                    </Link>
                </BoxTitle>
                <Count>{data?.studentCnt}</Count>
            </Box>
            <Box>
                <BoxTitle>
                    <Icon img={NewStudentsIcon} width={"32px"} height={"32px"} />
                    <Link to="/students">
                        <h3>오늘 신규 학생</h3>
                    </Link>
                </BoxTitle>
                <Count>+ {newData?.studentCnt}</Count>
            </Box>
        </StudentsStatusWrap>
    );
};
