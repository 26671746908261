import React, { Fragment, useContext, useEffect, useState } from "react";
import { BoxHeader } from "styles/layout";
import styled from "styled-components";
import AcademyInfoIcon from "assets/images/icons/academy_info_icon.svg";
import { AuthContext } from "context/auth";
import { media } from "styles/media";
import ProfileRequest from "http/profile";
const profileRequest = new ProfileRequest();

interface AcademyInfoProps {}

const BoxBody = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const Logo = styled.img``;

const InfoList = styled.ul`
    margin: 12px 0 0 24px;
    flex: 1;
    ${media.tablet} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    ${media.desktop} {
        flex-wrap: nowrap;
    }
`;

const Info = styled.li`
    margin-bottom: 20px;
    font-weight: bold;
    :last-child {
        margin: 0;
    }
    ${media.tablet} {
        flex-basis: 50%;
        :last-child {
            flex-basis: 100%;
        }
    }
`;

const Title = styled.div`
    font-size: 12px;

    color: ${({ theme }) => theme.colors.blue};
    margin-bottom: 8px;
`;

const Content = styled.div`
    font-size: 16px;
    line-height: 1.6;
`;

interface dataInterface {
    name?: string;
    phone?: string;
    address?: string;
}
export const AcademyInfo: React.FC<AcademyInfoProps> = () => {
    const { academyId } = useContext(AuthContext);
    const [data, setData] = useState<dataInterface>({
        name: "",
        phone: "",
        address: ""
    });

    // 학원 정보 가져오기
    useEffect(() => {
        const getData = async () => {
            await profileRequest.getProfile(
                (response: any) => {
                    if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                        alert(`[${response.data.code}] ${response.data.message}`);
                    } else {
                        setData(response.data.data);
                    }
                },
                (error: any) => {
                    setData({});
                    /*
                        테스트용
                        const tmp: dataInterface = {
                            name: 'TEST ACADEMY',
                            phone: '010-0000-0000',
                            address:
                                '(06628) 서울특별시 서초구 강남대로 311지번 1717호',
                        };
                        setData(tmp);
                    */
                }
            );
        };

        getData();
    }, [academyId]);

    return (
        <Fragment>
            <BoxHeader>
                <h2>학원 기본정보</h2>
            </BoxHeader>
            <BoxBody>
                <Logo src={AcademyInfoIcon} />
                <InfoList>
                    <Info>
                        <Title>이름</Title>
                        <Content>{data?.name}</Content>
                    </Info>
                    <Info>
                        <Title>전화번호</Title>
                        <Content>{data?.phone}</Content>
                    </Info>
                    <Info>
                        <Title>주소</Title>
                        <Content>{data?.address}</Content>
                    </Info>
                </InfoList>
            </BoxBody>
        </Fragment>
    );
};
