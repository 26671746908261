import React, { useState, useEffect} from "react";
import styled from "styled-components";
import TimeField from "react-simple-timefield";


const StyledTimePicker = styled(TimeField)`     
    background: #FFFFFF;
    border: 1px solid #CACACA;
    box-sizing: border-box;
    border-radius: 60px;

    width: 74px !important;
    height: 30px;
    padding: 0 5px;
    text-align: center;
    font-family: NanumSquareRound;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    outline: 0;
`;

export const TimeInput = ({onChange=(value)=>{},validation, value, onKeyPress=(e)=>{}}) => {
    const [val, setVal] = useState();

    useEffect(()=>{
        setVal(value);
    },[value]);

    const changeVal = (event,val) => {
        setVal(val);
        onChange(val);
    }

    return (
        <StyledTimePicker
            value={val}
            onChange={changeVal}
            format="HH:mm"
            openclockonfocus="false"
            validation={validation}
            onKeyPress={onKeyPress}
        />
    )

}