import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import _ from "lodash";
import { BoardSwitch } from "./BoardSwitch";
import { Draggable } from "react-beautiful-dnd";
import { FiMenu } from "react-icons/fi";

interface OnBoardProps {
    stationId: number;
    schedules: any;
    studentId: number | undefined;
    scheduleEdit: boolean;
    showNickName: boolean;
}

export const BoardBox = styled.div`
    display: flex;
`;

export const Name = styled(Link)`
    display: block;
    line-height: 1.8;
`;

// export const P = styled.p`
//     display: block;
//     line-height: 1.8;
// `;

export const BoardTitle = styled.div`
    min-width: 40px;
    margin-right: 10px;
    span {
        display: inline-flex;
        border-radius: 30px;
        background-color: ${({ theme }) => theme.colors.green};
        color: ${({ theme }) => theme.colors.white};
        padding: 0 8px;
        font-weight: bold;
        line-height: 1.8;
    }
`;
export const StudentList = styled.ul`
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
`;

const EditStudent = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 6px;
    height: 30px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(208, 208, 208, 0.6);
    border-radius: 4px;
    font-size: 12px;
    span {
        padding: 7px 5px 5px 5px;
        font-family: NanumSquareRound;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;
    }
`;

export const DisabledEditStudent = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 6px;
    height: 30px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(208, 208, 208, 0.6);
    border-radius: 4px;
    font-size: 12px;
    background-color: #e7e7e7;
    opacity: 0.6;
    span {
        padding: 7px 5px 5px 5px;
        font-family: NanumSquareRound;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;
    }
`;

interface StudentProps {
    thisStudentId: number | undefined;
    studentId: number | undefined;
}

export const Student = styled.li<StudentProps>`
    margin-bottom: 4px;
    line-height: 1;
    :last-child {
        margin-bottom: 0;
    }
    ${({ studentId, thisStudentId }) =>
        studentId &&
        Number(thisStudentId) !== studentId &&
        css`
            color: #dedede;
            a,
            span {
                color: #dedede !important;
            }
        `};
    p {
        display: block;
        line-height: 1.8;
    }
`;

export const NoShow = styled.span`
    color: ${({ theme }) => theme.colors.orange};
    margin-left: 4px;
`;

export const OnBoard: React.FC<OnBoardProps> = ({ stationId, schedules, studentId, scheduleEdit, showNickName }) => {
    let onBoardCounts = 0;

    if (_.isEmpty(schedules) === false) {
        schedules.map((schedule: any) => {
            if (stationId === Number(schedule.pickUpStation?.id) && schedule.status !== 4) {
                onBoardCounts = onBoardCounts + 1;
            }
            return null;
        });
    }

    return onBoardCounts > 0 ? (
        <BoardBox>
            <BoardTitle>
                <span>승차</span>
            </BoardTitle>
            <StudentList>
                {schedules.map((schedule: any, index: any) => {
                    if (stationId === Number(schedule.pickUpStation?.id) && schedule.status !== 4) {
                        return (
                            <Student
                                key={schedule.scheduleId}
                                thisStudentId={schedule.student?.id}
                                studentId={studentId}
                            >
                                {!scheduleEdit ? (
                                    <div>
                                        {schedule.isB2CUser ? (
                                            <p>
                                                {showNickName
                                                    ? schedule.student?.nickName === ""
                                                        ? `${schedule.student?.name}(B2C)`
                                                        : `${schedule.student?.name} - ${schedule.student?.nickName}(B2C)`
                                                    : `${schedule.student?.name}(B2C)`}
                                            </p>
                                        ) : (
                                            <Name
                                                to={`/students/${schedule.student?.id}`}
                                                title={"학생 페이지로 이동"}
                                                target={"blank"}
                                            >
                                                {showNickName
                                                    ? schedule.student?.nickName === ""
                                                        ? `${schedule.student?.name}`
                                                        : `${schedule.student?.name} - ${schedule.student?.nickName}`
                                                    : `${schedule.student?.name}`}
                                            </Name>
                                        )}
                                        <div className="board-switch">
                                            <BoardSwitch schedule={schedule} boardType={"onBoard"} />
                                            {schedule.boardSwitch !== 1 ? (
                                                ""
                                            ) : schedule.status === 0 && schedule.boardSwitch === 1 ? (
                                                " 탑승 대기"
                                            ) : schedule.status === 3 ? (
                                                <NoShow>미승차</NoShow>
                                            ) : schedule.status === 4 ? (
                                                " 미탑승 예약"
                                            ) : schedule.status === 5 ? (
                                                <NoShow>학원 복귀</NoShow>
                                            ) : (
                                                ` ${schedule.timeOnBoard}`
                                            )}
                                        </div>
                                    </div>
                                ) : schedule.isB2CUser ? (
                                    <DisabledEditStudent>
                                        <FiMenu size="12" color="#999" />
                                        <span>{schedule.student.name}(B2C)</span>
                                    </DisabledEditStudent>
                                ) : (
                                    <Draggable
                                        key={"inbound-draggable-" + schedule.student.id}
                                        draggableId={"inbound-" + schedule.student.id}
                                        data-studentId={schedule.student.id}
                                        index={index}
                                    >
                                        {provided => (
                                            <EditStudent
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                            >
                                                <FiMenu size="12" color="#999" />
                                                <span>{schedule.student.name}</span>
                                            </EditStudent>
                                        )}
                                    </Draggable>
                                )}
                            </Student>
                        );
                    } else {
                        return null;
                    }
                })}
            </StudentList>
        </BoardBox>
    ) : null;
};
