import React from "react";
import styled from "styled-components";
import { CloseButton } from "assets/images";

type PopUpProps = {
    width?: string;
    height?: string;
    handler?: any;
    confirm?: any;
    right?: string;
    data?: any;
};

const OverBookingPopUp = ({
    width,
    height,
    handler,
    confirm,
    right,
    data,
}: PopUpProps) => {
    return (
        <>
            <Container right={right} style={{ width: width, height: height }}>
                <CancelImg
                    onClick={() => handler(false)}
                    src={CloseButton}
                    alt="cancel"
                />
                <TitleWrapper>
                    <Title>좌석 초과</Title>
                </TitleWrapper>
                <ContentsWrapper>
                    <table>
                        <thead>
                            <tr>
                                <th>연도</th>
                                <th style={{ maxWidth: "200px" }}>날짜</th>
                                <th>점유율</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item: any) =>
                                item.seatOccupancyRates?.map(
                                    (obj: any, idx: number) => (
                                        <tr key={idx}>
                                            <td style={{ width: 45}}>
                                                    {item?.year}
                                            </td>
                                            <td
                                                style={{
                                                    width: "120px",
                                                    padding: "8px 20px",
                                                   
                                                }}
                                            >
                                                {obj?.ticketDates?.map(
                                                    (el: any, idx: number) => (
                                                        <Span key={idx}>
                                                            {`${el.slice(
                                                                5,
                                                                7
                                                            )}/${el.slice(
                                                                8,
                                                                10
                                                            )}${
                                                                obj?.ticketDates
                                                                    ?.length -
                                                                    1 ===
                                                                idx
                                                                    ? ""
                                                                    : ","
                                                            }`}
                                                            &nbsp;
                                                        </Span>
                                                    )
                                                )}
                                            </td>
                                            <td style={{ width: "60px" }}>
                                                <Align>
                                                    <Span
                                                        style={{
                                                            color: "#E74D41",
                                                            fontWeight: 800,
                                                        }}
                                                    >
                                                        {obj.occupySeat}
                                                    </Span>

                                                    <Span
                                                        style={{
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        /{obj.totalSeat}
                                                    </Span>
                                                </Align>
                                            </td>
                                        </tr>
                                    )
                                )
                            )}
                        </tbody>
                    </table>
                </ContentsWrapper>

                <ButtonWrapper>
                    <ApplyButton
                        onClick={() => confirm()}
                        style={{ backgroundColor: "#FFD100" }}
                    >
                        <ButtonSpan
                            style={{ color: "#332A00", fontWeight: 700 }}
                        >
                            확인
                        </ButtonSpan>
                    </ApplyButton>
                </ButtonWrapper>
            </Container>
        </>
    );
};

export default OverBookingPopUp;

type ContainerProps = {
    right?: string;
};

const Container = styled.div<ContainerProps>`
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    border-radius: 12px;
    background-color: #fff;
    padding: 20px 0px;
    border-radius: 22px;
    justify-content: center;
    text-align: center;
    top: 50%;
    right: ${(props) => (props.right ? props.right : "-10%")};
    z-index: 120;
    transform: translate3d(-50%, -50%, 0);
`;

const CancelImg = styled.img`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
`;

const TitleWrapper = styled.div`
    margin-bottom: 20px;
`;

const Title = styled.span`
    color: #1e2939;
    text-align: center;
    font-family: NanumSquareRound;
    font-size: 16px;
    font-weight: 800;
    line-height: 18px;
`;

const ContentsWrapper = styled.div`
    margin-bottom: 20px;
    padding: 18px 30px;
    background-color: #f6f8fb;
    justify-content: center;
    width: 438px;

    table {
        width: 100%;
        column-gap: 20px;
        background-color: #fff;

        thead {
            background-color: #fff;
            border-top: 1px solid #e1e1e1;
            border-bottom: 1px solid #e1e1e1;
            tr {
                color: #666;
                text-align: center;
                font-family: NanumSquareRound;
                font-size: 12px;
                font-weight: 400;
            }
            th {
                padding: 12px 16px;
                text-align: center;
                justify-content: center;
            }
        }
        tbody {
            background-color: #fff;
            tr {
                padding: 8px 16px;
                color: #000;
                text-align: center;
                font-family: NanumSquareRound;
                font-size: 12px;
                font-weight: 700;
            }
            td {
                padding: 8px 0px;
                justify-content: center;
                font-weight: 700;
                text-align: center;
                border-bottom: 1px solid #e1e1e1;
                word-break: break-word;
                vertical-align: middle;
            }
        }
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fff;
`;

const ApplyButton = styled.button`
    width: 98px;
    height: 36px;
    border-radius: 30px;
    background-color: #fff1b3;
    border: 1px solid #fff1b3;
`;

const ButtonSpan = styled.span`
    color: #000;
    font-size: 14px;
    line-height: 16px;
    font-weight: 800;
    font-family: NanumSquareRound;
`;

const Span = styled.span`
    line-height: 14px;
`;

const Align = styled.div`
    margin: auto 0px;
`;
