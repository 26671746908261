import React, { Fragment, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { media } from "styles/media";
import {
    Column,
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useRowSelect,
} from "react-table";
import { Box, BoxHeader } from "styles/layout";
import { BusSeatStatus } from "./BusSeatStatus";
import ToolTipImg from "assets/images/icons/tooltip_icon.svg";
import ToolTipVector from "assets/images/icons/tooltip_box_vector.svg";
import SelectDown from "components/molecules/SelectDown";

function Table({
    columns,
    data,
    selectedRow,
    getRowProps = () => ({}),
}: TableProps) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    }: any = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useRowSelect
    );

    return (
        <Fragment>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup: any) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th
                                    {...column.getHeaderProps([
                                        column.getSortByToggleProps(),
                                        { className: column.className },
                                    ])}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                {rows.length === 1 && rows[0].original.search ? (
                    <tbody
                        style={{
                            backgroundColor: "#fff",
                            height: "10px",
                            width: "100%",
                            position: "absolute",
                        }}
                    ></tbody>
                ) : (
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row: any, i: any) => {
                            prepareRow(row);
                            return (
                                <Tr
                                    status={row.original.status}
                                    {...row.getRowProps(getRowProps(row))}
                                    selected={selectedRow === i}
                                >
                                    {row.cells.map((cell: any) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </tbody>
                )}
            </table>
            {rows.length === 0 && <EmptyDiv>검색 결과가 없습니다.</EmptyDiv>}
        </Fragment>
    );
}

type BusListProps = {
    busTicketList?: string[];
    setDispatchCode?: React.Dispatch<React.SetStateAction<string | undefined>>;
    setDispatchName?: React.Dispatch<React.SetStateAction<string | undefined>>;
    setSelectedIndex?: React.Dispatch<React.SetStateAction<string | undefined>>;
    setDetailActive?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setBusEndDate: React.Dispatch<React.SetStateAction<string>>;
};

export const AcademyBusList = ({
    busTicketList,
    setDispatchCode,
    setDispatchName,
    setSelectedIndex,
    setDetailActive,
    setBusEndDate,
}: BusListProps) => {
    const [selectedRow, setSelectedRow] = useState(-1);
    const [filteredBusList, setFilteredBusList] = useState([]);
    const [filteredTimeList, setFilteredTimeList] = useState([]);
    const [showToolTip, setShowToolTip] = useState(false);
    const [selectedBus, setSelectedBus] = useState("");
    const [selectedTime, setSelectedTime] = useState("");
    const [tableData, setTableData] = useState<string[]>(busTicketList);

    useEffect(() => {
        setTableData(busTicketList);
    }, [busTicketList]);

    // 호차 리스트, 시간 리스트
    useEffect(() => {
        const currentBusList = busTicketList?.map((el: any, idx: number) => ({
            id: idx,
            value: el.bus.name,
        }));

        const currentTimeList = busTicketList?.map((el: any, idx: number) => ({
            id: idx,
            value: el.bus.time,
        }));

        // 버스 중복제거
        const reduceDuplicationBus = currentBusList.filter(
            (bus: any, i: number) => {
                return (
                    currentBusList.findIndex((bus2: any, j: number) => {
                        return bus.value === bus2.value;
                    }) === i
                );
            }
        );
        setFilteredBusList(reduceDuplicationBus);

        // 시간 중복제거
        const reduceDuplicationTime = currentTimeList.filter(
            (time: any, i: number) => {
                return (
                    currentTimeList.findIndex((time2: any, j: number) => {
                        return time.value === time2.value;
                    }) === i
                );
            }
        );

        const sortTime = reduceDuplicationTime.sort(
            (a, b) => a.value.slice(0, 2) - b.value.slice(0, 2)
        );

        setFilteredTimeList(sortTime);
    }, [busTicketList]);

    const BusPeriod = ({ data }) => {
        return (
            <div style={{ maxHeight: "32px" }}>
                {data.dispatch.startDate}
                <br />~{data.dispatch.endDate}
            </div>
        );
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                columns: [
                    {
                        Header: "운행 기간",
                        accessor: "period",
                        Cell: (info: any) => {
                            return (
                                <BusPeriod data={info.data[info.row.index]} />
                            );
                        },
                    },
                    {
                        Header: "운행 버스",
                        accessor: "dispatch.name",
                    },
                    // {
                    //     Header: "점유율",
                    //     accessor: "usedSeat",
                    //     className: "small",
                    //     Cell: (info: any) => {
                    //         return (
                    //             <BusSeatStatus
                    //                 data={info.data[info.row.index]}
                    //             />
                    //         );
                    //     },
                    // },
                ],
            },
        ],
        []
    );

    // 셀렉트 박스 호차, 시간 검색
    useEffect(() => {
        if (selectedBus === undefined && selectedTime === undefined) {
            setTableData(busTicketList);
        }

        if (selectedTime !== undefined && selectedBus === undefined) {
            const filterTimeList = busTicketList?.filter((item: any) =>
                item?.dispatch?.name?.includes(selectedTime)
            );
            setTableData(filterTimeList);
        } else if (selectedBus !== undefined && selectedTime === undefined) {
            const filterBusList = busTicketList?.filter((item: any) =>
                item?.dispatch?.name?.includes(selectedBus)
            );
            setTableData(filterBusList);
        } else if (selectedBus !== undefined && selectedTime !== undefined) {
            const busList = busTicketList?.filter((item: any) =>
                item?.dispatch?.name?.includes(selectedBus)
            );
            const timeList = busList?.filter((item: any) =>
                item?.dispatch?.name?.includes(selectedTime)
            );
            setTableData(timeList);
        }
    }, [selectedBus, selectedTime]);

    return (
        <Box>
            <BoxHeader style={{ display: "block" }}>
                <BusSearch>
                    <span className={"text"}>버스</span>
                    <SelectDown
                        width="210px"
                        options={filteredBusList}
                        initialText="전체"
                        type="bus"
                        disabled={busTicketList?.length === 0 ? true : false}
                        selectedBus={selectedBus}
                        setSelectedBus={setSelectedBus}
                    />
                    <span className={"text"} style={{ marginLeft: "12px" }}>
                        시간
                    </span>
                    <SelectDown
                        width="90px"
                        options={filteredTimeList}
                        initialText="전체"
                        type="time"
                        disabled={busTicketList?.length === 0 ? true : false}
                        selectedTime={selectedTime}
                        setSelectedTime={setSelectedTime}
                    />
                </BusSearch>
            </BoxHeader>
            <BoxBody>
                <TableBox>
                    <Table
                        columns={columns}
                        data={tableData}
                        selectedRow={selectedRow}
                        getRowProps={(row: any) => ({
                            onClick: () => {
                                setBusEndDate(row.original.dispatch?.endDate);
                                row.toggleRowSelected();
                                setSelectedRow(row.index);
                                setSelectedIndex(row.index);
                                setDispatchCode(row.original.dispatch?.code);
                                setDispatchName(row.original.dispatch?.name);
                                setDetailActive(true);
                            },
                        })}
                    />
                    {/* <ToolTip
                        onMouseEnter={() => setShowToolTip(true)}
                        onMouseLeave={() => setShowToolTip(false)}
                    ></ToolTip>
                    {showToolTip && (
                        <>
                            <ToolTipBoxVector />
                            <ToolTipContents>
                                <span style={{ lineHeight: "16px" }}>
                                    선택하신 기간 중 가장 많은 좌석 점유율을
                                    기준으로 보여드립니다.
                                </span>
                            </ToolTipContents>
                        </>
                    )} */}
                </TableBox>
            </BoxBody>
        </Box>
    );
};

interface TableProps {
    columns: Array<Column>;
    data: any;
    selectedRow: number;
    getRowProps: any;
}

interface BusServiceStatusSelectedProps {
    status: number;
    selected: boolean;
}

const Tr = styled.tr<BusServiceStatusSelectedProps>`
    background-color: #fff;
    ${({ status }) =>
        status > 0
            ? css`
                  cursor: pointer;
              `
            : css`
                  cursor: pointer;
              `};
    ${({ selected }) =>
        selected
            ? css`
                  background-color: #f7f7f7;
              `
            : css`
                  background-color: #fff;
              `};
`;

const EmptyDiv = styled.div`
    padding-top: 20vh;
    background-color: #fff;
    width: 100%;
    height: calc(80vh - 308px);
    font-family: NanumSquareRound;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    align-items: center;
    text-align: center;
    letter-spacing: -0.03em;
`;

const BoxBody = styled.div`
    position: relative;
`;

const TableBox = styled.div`
    /* overflow: auto;
    ${media.tablet} {
        overflow: visible;
    } */
    overflow: visible;
    padding: 0 15px;
    width: calc(100% + 30px);
    margin-left: -15px;
    position: relative;
    ${media.tablet} {
        height: calc(100vh - 398px);
        overflow-x: visible;
        overflow-y: auto;
    }
    table {
        width: 100%;
        border-spacing: 0;
        border-top: 1px solid #e1e1e1;
        ${media.tablet} {
            min-width: auto;
        }
        thead {
            tr {
                color: ${({ theme }) => theme.colors.blue};
                font-weight: 800;
                :nth-child(1) {
                    display: none;
                }
                th {
                    background-color: white;
                    top: 70px;
                    box-shadow: 0px 1px 0px 0 rgba(0, 0, 0, 0.1);
                    padding: 12px;
                }
            }
        }
        tr {
            border-bottom: 1px solid #e1e1e1;
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }
        tbody {
            tr {
                transition: 0.3s all ease-out;
                :hover {
                    border-radius: 10px;
                    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                }
            }
        }

        th,
        td {
            text-align: center;
            margin: 0;
            padding: 20px 12px;
            border-bottom: 1px solid #e1e1e1;
            line-height: 1.6;
            vertical-align: middle;
        }

        .small {
            width: 76px;
        }
    }
`;

const BusSearch = styled.div`
    display: flex;
    width: 100%;
    padding: 12px 14px;
    background: #f7f7f7;
    border-radius: 24px;
    .text {
        font-family: NanumSquareRound;
        font-style: normal;
        font-weight: 800;
        font-size: 13px;
        color: #174490;
        line-height: 15px;
        display: flex;
        padding: 2px;
        align-items: center;
        min-width: 30px;
    }

    .select-arrow {
        width: 9px;
        height: 9px;
        display: inline-block;
        position: absolute;
        top: 14px;
        right: 14px;
        span {
            width: 6px;
            height: 2px;
            position: absolute;
            background-color: #c4c4c4;
            display: inline-block;
            border-radius: 6px;
            transition: all 0.2s ease;
            &:first-of-type {
                right: 0;
                transform: rotate(-45deg);
            }
            &:last-of-type {
                left: 0;
                transform: rotate(45deg);
            }
        }
    }
`;

const ToolTip = styled.div`
    position: absolute;
    top: 15.5px;
    right: 20px;
    background: url(${ToolTipImg}) center center no-repeat;
    width: 14px;
    height: 14px;
    cursor: pointer;
`;

const ToolTipContents = styled.div`
    position: absolute;
    top: 41px;
    right: 0px;
    width: 186px;
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    font-size: 12px;
    padding: 12px 10px;
    font-weight: 700;
    border: 1px solid #333;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18);
`;

const ToolTipBoxVector = styled.div`
    position: absolute;
    top: 36px;
    right: 23px;
    width: 8px;
    height: 5px;
    background-image: url(${ToolTipVector});
`;
