import Request from "./request";

class DispatchRequest extends Request {
    search = (academyId, selectDate, data, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback,
            includeAcademyId: true
        };
        let param = `date=${selectDate}`;
        param += `&arrivalStationId=${data.arrivalStationId}`;
        param += `&departureStationId=${data.departureStationId}`;
        param += `&weekday=${data.weekday}`;
        param += `&inbound=true`;
        this.get(`/dispatches/times?` + param, request);
    }

}

export default DispatchRequest;
