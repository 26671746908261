import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

// molecules
import Select from "components/atoms/select";
import DropdownMenu from "components/atoms/dropdownMenu";
// store
import { useMasterDashboardStore } from "store/masterDashboardStore";

type DataProps = {
    id: number;
    name: string;
};

type DropdownProps = {
    // data: DataProps[];
    disabled?: boolean;
    width?: string;
};
const Dropdown = ({
    // data=[],
    disabled,
    width
}: DropdownProps) => {
    const {
        academies,
        clickedAcademyId,
        setClickedAcademyId,
        clickedAcademyInfo,
        setClickedAcademyInfo,
        recentAcademies,
        setRecentAcademies
    } = useMasterDashboardStore();

    const selectListBoxRef = useRef<HTMLUListElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<DataProps>(academies[0] ?? { id: 0, name: "" });
    // const [recentAcademies, setRecentAcademies] = useState<DataProps[]>(JSON.parse(localStorage.getItem("recentAcademies") ?? "[]"));

    useEffect(() => {
        if (open === false) {
            return;
        }
        const handleClick = (e: MouseEvent) => {
            setOpen(false);

            // 어차피 뭘 click 해도 닫히는거면 굳이 어떤 요소를 click헀는지 check 해야할 필요가 있을까?
            // 일단은 남겨논 주석
            // if((e.target as HTMLElement)?.parentNode !== selectListBoxRef.current)
            //     setOpen(false)
        };
        window.addEventListener("click", handleClick);

        return () => window.removeEventListener("click", handleClick);
    }, [open]);

    useEffect(() => {
        if (open) {
            (selectListBoxRef?.current as HTMLElement).style.display = "block";
            return;
        }
        const selectBoxTimeout = setTimeout(() => {
            (selectListBoxRef?.current as HTMLElement).style.display = "none";
        }, 200);

        return () => clearTimeout(selectBoxTimeout);
    }, [open]);

    useEffect(() => {
        setSelectedItem(JSON.parse(localStorage.getItem("academyInfo") ?? `{"id":0,"name":""}`));
    }, []);

    useEffect(() => {
        localStorage.setItem("recentAcademies", JSON.stringify(recentAcademies));
    }, [recentAcademies]);

    const handleSelect = () => setOpen(prev => !prev);

    const handleDropdownMenu = (data: DataProps) => {
        setSelectedItem(data);
        setOpen(false);
        handleRecentAcademyFetch(data);

        setClickedAcademyInfo(data);
        setClickedAcademyId(data.id);
        localStorage.setItem("academyId", JSON.stringify(data.id));
        localStorage.setItem("academyInfo", JSON.stringify(data));

        window.location.reload();
    };

    // 최근 선택 학원 추가
    const handleRecentAcademyFetch = (data: DataProps) => {
        const academyExists = recentAcademies.some((academy: DataProps) => academy.id === data.id);
        if (academyExists === false) {
            const cuttingRecentAcademies = [data, ...recentAcademies.slice(0, 2)];
            setRecentAcademies(cuttingRecentAcademies);
        }
    };

    return (
        <DropdownContainer width={width}>
            <Select
                disabled={disabled}
                open={open}
                title={selectedItem.name}
                onClick={disabled ? () => {} : handleSelect}
            ></Select>

            <DropdownMenuContainer aria-label={"dropdown-ul"} ref={selectListBoxRef} open={open}>
                {recentAcademies.length ? (
                    <>
                        <CategoryHeader>최근 선택 학원</CategoryHeader>
                        {recentAcademies.map((item: DataProps) => {
                            return (
                                <DropdownMenu
                                    key={`recent-${item.id}`}
                                    isSelected={item.id === selectedItem.id}
                                    onClick={() => handleDropdownMenu(item)}
                                >
                                    {item.name}
                                </DropdownMenu>
                            );
                        })}
                    </>
                ) : (
                    <></>
                )}

                <CategoryHeader>전체 학원</CategoryHeader>
                {academies.map((item: DataProps) => {
                    return (
                        <DropdownMenu
                            key={item.id}
                            isSelected={item.id === selectedItem.id}
                            onClick={() => handleDropdownMenu(item)}
                        >
                            {item.name}
                        </DropdownMenu>
                    );
                })}
            </DropdownMenuContainer>
        </DropdownContainer>
    );
};
export default Dropdown;

type DropdownContainerProps = {
    width?: string;
};
const DropdownContainer = styled.div<DropdownContainerProps>`
    position: relative;
    width: ${({ width }) => width || "100%"};
    height: 100%;
`;
type DropdownMenuContainerProps = {
    open: boolean;
};
const DropdownMenuContainer = styled.ul<DropdownMenuContainerProps>`
    z-index: 100;
    position: absolute;
    top: 100%;
    left: 0;

    display: none;

    width: 100%;
    height: 472px;
    /* padding: 8px 12px; */
    margin-top: 2px;

    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
    overflow: auto;

    & > li:not(:last-child) {
        margin-bottom: 8px;
    }

    @keyframes fade-in {
        to {
            max-height: 100vh;
        }
    }
    @keyframes fade-out {
        to {
            opacity: 0;
        }
    }

    animation: ${({ open }) => (open ? "fade-in 0.4s ease-in-out" : "fade-out 0.4s ease")};
    animation-fill-mode: forwards;
`;
const CategoryHeader = styled.div`
    display: flex;
    align-items: center;

    height: 28px;
    padding: 0 8px;
    margin-bottom: 8px;

    color: #666666;
    font-size: 11px;
    font-weight: 700;
    background-color: #ebebeb;
`;
