import React from "react";
import successIcon from "assets/images/icons/toast_success_icon.svg";
import failIcon from "assets/images/icons/toast_fail_icon.svg";
import { styled } from "styles/theme";
import { css } from "styles/theme-components";

interface ToastProps {
    toggle: boolean;
    toastStatus: string;
    message: string;
}

interface ToastWrapProps {
    toggle: boolean;
    toastStatus: string;
}

const ToastWrap = styled.div<ToastWrapProps>`
    transition: all 0.5s ease-in-out;
    z-index: 100000;
    ${({ toggle }) =>
        toggle === true
            ? css`
                  opacity: 1;
              `
            : css`
                  opacity: 0;
                  z-index: -1;
                  pointer-events: none;
              `}
    position: fixed;
    top: 60px;
    left: 50%;
    border-radius: 58px;
    align-items: center;
    font-size: 14px;
    padding: 0 16px 0 42px;
    transform: translateX(-50%);
    display: flex;
    height: 52px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.24);
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.black} center left 16px no-repeat;
    ${({ toastStatus }) =>
        toastStatus === "success"
            ? css`
                  background-image: url(${successIcon});
              `
            : toastStatus === "fail"
            ? css`
                  background-image: url(${failIcon});
              `
            : ""}
`;

export const Toast: React.FC<ToastProps> = ({ toggle, toastStatus, message }) => {
    return (
        <ToastWrap toggle={toggle} toastStatus={toastStatus}>
            <div>{message}</div>
        </ToastWrap>
    );
};
