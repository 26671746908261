import React, { useState, Fragment } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

// pages
import MasterDashboard from "pages/masterDashboard";

import { Dashboard } from "pages/Dashboard";
import { Login } from "pages/Login";
import { Header } from "components/layout/Header";
import { getAccessToken, getUserInfo } from "accessToken";
import { Students } from "pages/Students";
import { BusService } from "pages/BusService";
import { TicketService } from "pages/TicketService";
import { Board } from "pages/Board";
import { Profile } from "pages/Profile";
import { AuthContext } from "context/auth";
import { StudentDetail } from "components/students/StudentDetail";
import { SideNav } from "components/layout/SideNav";
import MasterAccountSideNav from "components/layout/MasterAccountSideNav";
import { GlobalContext } from "context/global";
import _ from "lodash";
import BoardDetail from "components/board/boardDetail/BoardDetail";

const Routes: React.FC = () => {
  const isAuth = getAccessToken() ? true : false;
  const existUserInfo = _.isEmpty(getUserInfo()) ? false : true;
  const [loggedIn, setLoggedIn] = useState(isAuth);
  const [academyId, setAcademyId] = useState(
    Number(localStorage.getItem("academyId"))
  );
  const [isMaster, setIsMaster] = useState(
    Number(localStorage.getItem("isMaster"))
  );
  const [isViewStudentRegistrationButton, setIsViewStudentRegistrationButton] =
    useState(true);

  const [navToggle, setNavToggle] = useState<boolean>();
  const [currentLocation, setCurrentLocation] = useState<string>();
  const [registerStudentPopUp, setRegisterStudentPopUp] =
    useState<boolean>(false);

  const [toast, setToast] = useState({
    toggle: false,
    toastStatus: "",
    message: "",
  });

  return (
    <BrowserRouter>
      <AuthContext.Provider
        value={{
          loggedIn,
          setLoggedIn,
          academyId,
          setAcademyId,
        }}
      >
        <GlobalContext.Provider
          value={{
            navToggle,
            setNavToggle,
            currentLocation,
            setCurrentLocation,
            registerStudentPopUp,
            setRegisterStudentPopUp,
            toast,
            setToast,
            isMaster,
            setIsMaster,
            isViewStudentRegistrationButton,
            setIsViewStudentRegistrationButton,
          }}
        >
          {loggedIn && existUserInfo ? (
            <Fragment>
              <Header />
              {isMaster ? ( // Master 계정
                <MasterAccountSideNav />
              ) : (
                <SideNav />
              )}
            </Fragment>
          ) : (
            <Redirect to="/login" />
          )}
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                if (!!loggedIn === false) {
                  return <Redirect to="/login" />;
                }
                return (
                  <Redirect
                    to={isMaster ? "/master-dashboard" : "/dashboard"}
                  />
                );
              }}
            />
            <Route exact path="/master-dashboard" component={MasterDashboard} />

            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/students" component={Students} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/busService" component={BusService} />
            <Route exact path="/ticketservice" component={TicketService} />
            <Route exact path="/board" component={Board} />
            <Route exact path="/board/:id" component={BoardDetail} />
            <Route exact path="/students/:id" component={StudentDetail} />
          </Switch>
        </GlobalContext.Provider>
      </AuthContext.Provider>
    </BrowserRouter>
  );
};

export default Routes;
