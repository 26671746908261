import React, {ReactElement, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {useHistory} from "react-router";
import TableList, {SortConfig} from "components/common/TableList";
import {isNil} from "components/ValidataionUtil";
import {CBoard, CPagination} from "model/board";
import {media} from "styles/media";
import Pagination from "components/common/Pagination";
import {Optional} from "type/Common";
import {getRequests} from "service/board/Board";
import Select, {toSelectOptions} from "components/atoms/checkSelect/Select";
import {flexRow} from "styles/CommonStyle";
import {BoardStatusType} from "enum/BoardStatusType";

const TITLE_MAP = {
    id: "번호",
    category: "종류",
    title: "제목",
    writer: "작성자",
    createdAt: "작성일",
    comment: "댓글",
    status: "처리상태",
};

type Props = {
    title: string;
    writer: string;
    requestCount: number;
    setRequestCount: React.Dispatch<React.SetStateAction<number>>;
};

function BoardList(props: Props): ReactElement {
    const history = useHistory();
    const academyID = localStorage.getItem("academyId");
    const [sortingType, setSortingType] = useState<SortConfig>(null);
    const [boardState, setBoardState] = useState<CBoard[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [status, setStatus] = useState<number>(null);
    const [pagination, setPagination] = useState<Optional<CPagination>>(null);

    const listItems = useMemo((): Map<string, ReactNode>[] => {
        return boardState.map((d) => {
            const node = new Map<string, ReactNode>();
            node.set(TITLE_MAP.id, <div style={{width: "36px"}}>{d.id}</div>);
            node.set(TITLE_MAP.category, <div style={{width: "120px"}}>{d.category.name}</div>);
            node.set(TITLE_MAP.title, <div style={{width: "320px"}}>{d.title.length > 65 ? d.title.slice(0, 65) + "..." : d.title}</div>);
            node.set(TITLE_MAP.writer, <div style={{width: "120px"}}>{d.writer.name}</div>);
            node.set(TITLE_MAP.createdAt, <div style={{width: "144px"}}>{d.createdAt}</div>);
            node.set(TITLE_MAP.comment, <div style={{width: "66px"}}>{d.reply.count}</div>);
            node.set(TITLE_MAP.status, <Status status={d.status}>{d.status === 0 ? "대기중" : d.status === 1 ? "처리중" : "처리완료"}</Status>);
            return node;
        });
    }, [boardState]);

    const getBoardList = useCallback(() => {
        const data = {
            academyId: Number(academyID),
            page: currentPage,
            title: props.title,
            writer: props.writer,
            status,
        };
        getRequests(data)
            .then((res) => {
                console.log(res);
                setBoardState(res.boardRequests);
                setPagination(res.pagination);
                props.setRequestCount(props.requestCount);
                if (res.pagination.totalPage < res.pagination.page) {
                    setCurrentPage(1);
                }
            })
            // if (isNil(sortingType)) {
            //   setBoardState(data);
            // }
            .catch((error) => {
                throw new Error(`getBoardList() failed. error : ${error}`);
            });
    }, [academyID, currentPage, props, status]);

    const onClickRow = useCallback(
        (n: number) => {
            if (isNil(boardState)) {
                return;
            }
            const boardID = boardState[n]?.id;
            history.push(`/board/${boardID}`, {boardID});
        },
        [boardState, history]
    );

    useEffect(() => {
        if (!isNil(sortingType)) {
            if (sortingType.direction === "decrease") {
                const filteredData = boardState.sort((a, b) => b.id - a.id);
                setBoardState(filteredData);
                // console.log("decrease", filteredData);
            }
            if (sortingType.direction === "increase") {
                const filteredData = boardState.sort((a, b) => a.id - b.id);
                setBoardState(filteredData);
                // console.log("increase", filteredData);
            }
        }
    }, [boardState, sortingType, setBoardState]);

    const TableComponent = useMemo(() => {
        return <TableList keys={Object.values(TITLE_MAP)} items={listItems} onClickRow={onClickRow} useSorting={true} placeholder="등록된 게시글이 없습니다." getSortingType={setSortingType} />;
    }, [onClickRow, listItems]);

    const handleSelectValue = useCallback((v: BoardStatusType) => {
        console.log(v);
        setStatus(v.value);
    }, []);

    const statusOptions = useMemo(() => {
        return toSelectOptions(BoardStatusType.ALL_TYPES, (t) => t.exposure, false);
    }, []);

    useEffect(() => {
        getBoardList();
    }, [getBoardList, academyID, currentPage, props.title, props.writer, status]);

    const handleRefresh = useCallback(() => {
        getBoardList();
    }, [getBoardList]);

    return (
        <Container>
            <TitleFilterWrapper>
                <Title>
                    요청 목록 <RefreshButton onClick={handleRefresh}>새로고침</RefreshButton>
                </Title>

                <FilterWrapper listCnt={boardState.length}>
                    <StatusCategory>처리상태선택</StatusCategory>
                    <Line />
                    <Select options={statusOptions} boxShadow={false} border={false} width={100} height={33} fontSize={12} borderRadius={60} onChange={handleSelectValue} />
                </FilterWrapper>
            </TitleFilterWrapper>
            {TableComponent}
            {boardState.length > 0 && <Pagination num={pagination?.totalCount} perPage={10} currentPage={currentPage} pageExposeCount={5} setCurrentPage={setCurrentPage} columnGap={"15px"} />}
        </Container>
    );
}

export default BoardList;

const Container = styled.div`
    margin-top: 26px;
    background: #fff;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    padding: 24px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);

    ${media.tablet} {
        /* height: calc(100vh - 270px); */
        height: 100%;
        padding: 40px;
    }
`;

const RefreshButton = styled.button`
    background: none;
    border: none;
    padding: 5px 20px;
    font-size: 12px;
    font-weight: 600;
    font-family: NanumSquareRound;
    width: 100px;
    color: #999;
    &:hover {
        color: #000;
    }
`;

const TitleFilterWrapper = styled.div`
    ${flexRow};
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const Title = styled.div`
    color: #174490;
    font-family: NanumSquareRound;
    font-size: 18px;
    font-weight: 800;
    display: inline-flex;
    align-items: center;
`;

type FilterProps = {
    listCnt: number;
};

const FilterWrapper = styled.div<FilterProps>`
    ${flexRow};
    align-items: center;
    border-radius: 60px;
    border: 1px solid #cacaca;
    background: #fff;
    padding-left: 12px;
`;

const StatusCategory = styled.div`
    color: #666;
    font-family: NanumSquareRound;
    font-size: 11px;
    font-weight: 400;
    line-height: 140%; /* 15.4px */
    margin-right: 8px;
`;

const Line = styled.span`
    width: 1px;
    height: 17px;
    background: #d9d9d9;
`;
type StatusProps = {
    status: number;
};

export const Status = styled.button<StatusProps>`
    width: ${(props) => (props.status === 2 ? "61px" : "52px")};
    height: 23px;
    line-height: 1;
    border: none;
    border-radius: 120px;
    font-size: 10px;
    padding: 6px 12px;
    font-weight: 600;
    background: ${(props) => (props.status === 0 ? "#eaeaea" : props.status === 2 ? "#000" : "#ffcd00")};

    color: ${(props) => (props.status === 0 ? "#999" : props.status === 2 ? "#ffffff" : "#000")};
`;
