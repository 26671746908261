/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useRef, useContext, useLayoutEffect } from "react";
import { Info } from "components/profile/UserAccount";
import { Label, Input, Comment, Button } from "styles/ui";
import styled from "styled-components";
import { useFormatPhoneNumber } from "hooks/useFormatPhoneNumber";
import { RegisterStudentContext } from "context/registerStudent";
import _ from "lodash";
import ParentsRequest from "http/parents";
const parentsRequest = new ParentsRequest();

interface RegisterStudentStep1Props {}

const ParentInfoList = styled.ul`
    padding: 10px 0;
`;

const FooterButtons = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
`;

interface dataInterface {
    code?: string;
    name?: string;
    phone?: string;
}

export const RegisterStudentStep1: React.FC<RegisterStudentStep1Props> = () => {
    const { registerStudentState, setRegisterStudentState } = useContext(RegisterStudentContext);

    const [parent, setParent] = useState({
        name: registerStudentState.parentName,
        phone: registerStudentState.parentPhone
    });
    const [isValid, setIsValid] = useState({
        parentName: true,
        parentPhone: true
    });
    const [comment, setComment] = useState({
        parentName: "",
        parentPhone: ""
    });
    const parentNameInput = useRef<HTMLInputElement>(null);
    const parentPhoneInput = useRef<HTMLInputElement>(null);
    let formedPhone: any = useFormatPhoneNumber({ phone: parent.phone });
    const [getParentData, setParentData] = useState<dataInterface>({});

    // 보호자연락처를 활용한 학부모정보 조회
    const getParent = async () => {
        await parentsRequest.getParentByPhone(
            formedPhone,
            (response: any) => {
                if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                    console.log(`[${response.data.code}] ${response.data.message}`);
                    setParentData({});
                } else {
                    setParentData(response.data.data);
                }
            },
            (error: any) => {
                setParentData({});
                /*
                    테스트용
                    const tmp: dataInterface = {
                        code: 'c06e814a53e3ca86db9fe9c0e79694b9',
                        name: '테스트 학부모',
                        phone: '010-0000-0000',
                    };
                    setParentData(tmp);
                */
            }
        );
    };

    useLayoutEffect(() => {
        setRegisterStudentState({
            ...registerStudentState,
            hasParent: _.isEmpty(getParentData) ? false : true,
            parentName: _.isEmpty(getParentData) ? parent.name : getParentData?.name,
            parentPhone: _.isEmpty(getParentData) ? formedPhone : getParentData?.phone,
            parentCode: _.isEmpty(getParentData) ? undefined : getParentData?.code
        });

        // console.log({
        //     ...registerStudentState,
        //     hasParent: _.isEmpty(getParentData) ? false : true,
        //     parentName: _.isEmpty(getParentData)
        //         ? parent.name
        //         : getParentData?.name,
        //     parentPhone: _.isEmpty(getParentData)
        //         ? formedPhone
        //         : getParentData?.phone,
        //     parentCode: _.isEmpty(getParentData)
        //         ? undefined
        //         : getParentData?.code,
        // });

        // eslint-disable-next-line
    }, [getParentData]);

    const goNextStep = () => {
        setRegisterStudentState({
            ...registerStudentState,
            hasBackButton: true,
            step1: false,
            step2: true
        });
    };

    return (
        <form
            onSubmit={async e => {
                e.preventDefault();

                if (parent.name === "") {
                    setComment({
                        parentName: "을 입력해주세요.",
                        parentPhone: ""
                    });
                    setIsValid({
                        parentName: false,
                        parentPhone: true
                    });
                    parentNameInput.current?.focus();
                    return;
                } else if (parent.name.length < 2) {
                    setComment({
                        parentName: "은 2자 이상 입력해주세요.",
                        parentPhone: ""
                    });
                    setIsValid({
                        parentName: false,
                        parentPhone: true
                    });
                    parentNameInput.current?.focus();
                    return;
                } else if (parent.name[0].search(/[a-zA-Z|가-힣]/)) {
                    setComment({
                        parentName: "은 첫 글자로 한글 또는 영문만 입력 가능합니다.",
                        parentPhone: ""
                    });
                    setIsValid({
                        parentName: false,
                        parentPhone: true
                    });
                    parentNameInput.current?.focus();
                    return;
                } else if (parent.phone === "") {
                    setComment({
                        parentName: "",
                        parentPhone: "를 입력해주세요."
                    });
                    setIsValid({
                        parentName: true,
                        parentPhone: false
                    });
                    parentPhoneInput.current?.focus();
                    return;
                } else if (parent.phone.length < 13) {
                    setComment({
                        parentName: "",
                        parentPhone: "를 정확히 입력해주세요."
                    });
                    setIsValid({
                        parentName: true,
                        parentPhone: false
                    });
                    parentPhoneInput.current?.focus();
                    return;
                }

                try {
                    await getParent();
                    await goNextStep();
                } catch (e: any) {
                    const errorCode = e.graphQLErrors[0].extensions?.code;
                    console.log(errorCode);
                }
            }}
        >
            <ParentInfoList>
                <Info>
                    <Label htmlFor="parentName">
                        보호자 이름<Comment>{comment.parentName}</Comment>
                    </Label>
                    <Input
                        id="parentName"
                        ref={parentNameInput}
                        type="text"
                        value={parent.name}
                        onChange={e =>
                            setParent({
                                ...parent,
                                name: e.target.value.replace(/[`₩~!@#$%^&*,.<>+=|;:'"/?{}\s\[\]\\]/, "")
                            })
                        }
                        isValid={isValid.parentName}
                        maxLength={13}
                    />
                </Info>
                <Info>
                    <Label htmlFor="parentPhone">
                        보호자 연락처<Comment>{comment.parentPhone}</Comment>
                    </Label>
                    <Input
                        id="parentPhone"
                        ref={parentPhoneInput}
                        type="text"
                        value={formedPhone}
                        onChange={e =>
                            setParent({
                                ...parent,
                                phone: e.target.value
                            })
                        }
                        isValid={isValid.parentPhone}
                        maxLength={13}
                    />
                </Info>
            </ParentInfoList>
            <FooterButtons>
                <Button type="submit">다음</Button>
            </FooterButtons>
        </form>
    );
};
