import React, {useCallback, useState} from "react";
import styled from "styled-components";
import {flexColumn, flexRow} from "styles/CommonStyle";
import {Status} from "../boardList/BoardList";
import SVGImage from "components/common/SVGImage";
import {media} from "styles/media";
import {useHistory} from "react-router-dom";

//image
import AcademyIcon from "assets/images/icons/academy_info_icon.svg";
import {CBoardDetail} from "model/board";
import {isNil} from "components/ValidataionUtil";
import {delRequest} from "service/board/Board";
import {PopUp2} from "components/layout/PopUp2";
import DeleteRequestConfirmPopUp from "../popUp/DeleteRequestConfirmPopUp";

type DetailProps = {boardContent: CBoardDetail};
type deleteRequestDataType = {
    requestId: number;
    userId: number;
    userType: number;
};
function BoardDetailContents(props: DetailProps) {
    const [user] = useState(JSON.parse(localStorage.getItem("userInfo")));
    const history = useHistory();
    const [deleteRequestData, setDeleteRequestData] = useState<deleteRequestDataType>(null);
    const [deleteRequestPopUp, setDeleteRequestPopUp] = useState<boolean>(false);
    console.log(props.boardContent);
    console.log(user);

    const deleteRequest = useCallback(() => {
        console.log(deleteRequestData);
        delRequest(deleteRequestData)
            .then((res) => {
                console.log(res);
                if (res.status === -1) {
                    alert("요청 삭제에 실패했습니다.");
                    console.log(`${res.error.message}`);
                } else {
                    console.log(res.data);
                    history.push(`/board`);
                }
            })
            .catch((error) => {
                throw new Error(`Delete Request failed. error : ${error}`);
            });
    }, [deleteRequestData, history]);

    return (
        <Container>
            <CategoryStatus>
                <Category>{`${props.boardContent.category.parent.name} > ${props.boardContent.category.name}`}</Category>
                <Status status={props.boardContent.status}>{props.boardContent.status === 0 ? "대기중" : props.boardContent.status === 2 ? "처리완료" : "처리중"}</Status>
            </CategoryStatus>
            <Title>{props.boardContent.title}</Title>
            <WriterInfo>
                <AcademyImg source={AcademyIcon} />
                <Writer>{props.boardContent.writer.name}</Writer>
                <Date>{props.boardContent.createdAt}</Date>
            </WriterInfo>
            <Content>{props.boardContent.content}</Content>
            <ButtonContainer>
                {props.boardContent.status === 0 && props.boardContent.writer.id === user.id ? (
                    <ButtonWrapper>
                        <DeleteButton
                            onClick={() => {
                                setDeleteRequestData({
                                    requestId: props.boardContent.id,
                                    userId: user.id,
                                    userType: 2,
                                });
                                setDeleteRequestPopUp(true);
                            }}
                        >
                            삭제
                        </DeleteButton>
                        {deleteRequestPopUp && (
                            <PopUp2
                                open={deleteRequestPopUp}
                                handler={setDeleteRequestPopUp}
                                contents={<DeleteRequestConfirmPopUp onClose={() => setDeleteRequestPopUp(false)} deleteRequest={deleteRequest} />}
                                width="360px"
                                height="200px"
                            />
                        )}
                        {/* <ModifyButton>수정</ModifyButton> */}
                    </ButtonWrapper>
                ) : (
                    <DeleteNote>
                        상태가 (대기중)일 경우에만 삭제 가능합니다. <br />
                        추가사항은 댓글에 작성해주세요.
                    </DeleteNote>
                )}
                {!isNil(props.boardContent.updatedAt) && <UpdatedAt>수정일 {props.boardContent.updatedAt}</UpdatedAt>}
            </ButtonContainer>
        </Container>
    );
}

export default BoardDetailContents;

const Container = styled.div`
    ${flexColumn}
    height: 100%;
`;

const CategoryStatus = styled.div`
    ${flexRow};
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

const Category = styled.div`
    color: #174490;
    font-size: 12px;
    line-height: 14px;
`;

const Title = styled.div`
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    margin-bottom: 8px;
`;

const WriterInfo = styled.div`
    ${flexRow};
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #e7e7e7;

    ${media.tablet} {
        padding-bottom: 16px;
    }
`;

const AcademyImg = styled(SVGImage)`
    width: 16px;
    height: 16px;
`;

const Writer = styled.div`
    color: #174490;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding: 0 8px 0 4px;
    border-right: 1px solid #e7e7e7;
`;

const Date = styled.div`
    color: #666;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    margin-left: 8px;
`;

const Content = styled.div`
    color: #000;
    font-size: 14px;
    line-height: 160%; /* 19.6px */
    padding: 12px 0;
    border-bottom: 1px solid #e7e7e7;
    min-height: 100px;
    white-space: pre;
    ${media.tablet} {
        padding: 16px;
        overflow: scroll;

        ::-webkit-scrollbar {
            width: 10px;
            height: 100%;
            border-radius: 30px;
            background: #fff;
        }

        ::-webkit-scrollbar-thumb {
            background: #ffeb9f;
            border-radius: 30px;
            height: 30%;
        }
    }
`;

const ButtonWrapper = styled.div`
    ${flexRow};

    column-gap: 4px;
`;

const DeleteButton = styled.div`
    display: flex;
    width: 44px;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 11px;
    border-radius: 2px;
    background: #000;
    color: #fff;
    font-weight: 400;
    line-height: 12px;
    cursor: pointer;
`;

// const ModifyButton = styled(DeleteButton)`
//     background: #fff;
//     color: #174490;
//     border: 1px solid #174490;
// `;

const UpdatedAt = styled.div`
    color: #666;
    font-size: 10px;
    font-weight: 400;
    line-height: 140%; /* 14px */
`;

const ButtonContainer = styled.div`
    ${flexRow};
    justify-content: space-between;
    align-items: center;
    height: 22px;
    margin-top: 12px;
`;

const DeleteNote = styled.div`
    display: inlin-block;
    font-size: 10px;
    line-height: 1.4;
`;
// function setToast(arg0: {toggle: boolean; toastStatus: string; message: string}) {
//     throw new Error("Function not implemented.");
// }
