import Request from "./request";

class ProfileRequest extends Request {
    /**
     * 학원 프로필 조회 (useGetAcademyQuery)
     * params
     *      x
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getProfile = (callback, errorCallback) => {
        const request = {
            callback,
            errorCallback,
            includeAcademyId: true
        };
        this.get("/profile", request);
    }
}

export default ProfileRequest;
