import React, { Fragment } from "react";
import { BoxHeader } from "styles/layout";
import styled from "styled-components";
import { media } from "styles/media";
import StudentIcon from "assets/images/icons/student_icon.svg";

interface StudentInfoProps {
    data: any;
}

const BoxBody = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const Logo = styled.img``;

const InfoList = styled.ul`
    margin: 12px 0 0 24px;
    flex: 1;
    ${media.tablet} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

const Info = styled.li`
    margin-bottom: 20px;
    font-weight: bold;
    :last-child {
        margin: 0;
    }
    ${media.tablet} {
        flex-basis: 50%;
        :last-child {
            flex-basis: 100%;
        }
    }
    ${media.desktop} {
        flex-basis: 50%;
    }
`;

const Title = styled.div`
    font-size: 12px;

    color: ${({ theme }) => theme.colors.blue};
    margin-bottom: 8px;
`;

const Content = styled.div`
    font-size: 16px;
    line-height: 1.6;
`;

export const StudentInfo: React.FC<StudentInfoProps> = ({ data }) => {
    return (
        <Fragment>
            <BoxHeader>
                <h2>학생 기본정보</h2>
            </BoxHeader>
            <BoxBody>
                <Logo src={StudentIcon} />
                <InfoList>
                    <Info>
                        <Title>이름</Title>
                        <Content>{data?.name}</Content>
                    </Info>
                    <Info>
                        <Title>학년 정보</Title>
                        <Content>{data?.nickname || "-"}</Content>
                    </Info>
                    <Info>
                        <Title>전화번호</Title>
                        <Content>{data?.phone || "-"}</Content>
                    </Info>
                    <Info>
                        <Title>보호자 정보</Title>
                        <Content>
                            {data?.parent?.name}
                            <br />
                            {data?.parent?.phone}
                        </Content>
                    </Info>
                    <Info>
                        <Title>기본 승하차 정류장</Title>
                        <Content>{data?.station?.name || "-"}</Content>
                    </Info>
                </InfoList>
            </BoxBody>
        </Fragment>
    );
};
