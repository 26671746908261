import React, { useCallback, useContext, useEffect, useState } from "react";
import { styled } from "styles/theme";
import { Button } from "styles/ui";
import { ScheduleServiceContext } from "../../context/scheduleService";
import { formattingHHmm, getWeekdayStr } from "../DateUtil";
import { useTable, Column, useGlobalFilter, useSortBy } from "react-table";

interface ScheduleCheckProps {
    handler: any;
    cancel: any;
    btnCancel: boolean;
    formattedDate: string;
    buttonStatus: string;
    setModify: any;
    buttonHandler: any;
}

const Title = styled.h3`
    margin-bottom: 1em;
    width: 100%;
    text-align: center;
`;
const Text = styled.div`
    font-weight: normal;
    width: 100%;
    min-width: 320px;
    font-family: NanumSquareRound;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    padding: 10px;
    span {
        color: #174490;
    }
`;

export const Arrow = styled.span`
    margin: 0
    width: 8px;
    height: 8px;
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 177px;
    span {
        width: 7px;
        height: 2px;
        position: absolute;
        background: #bbb;
        display: inline-block;
        border-radius: 6px;
        transition: all 0.2s ease;
        &:first-of-type {
                top: 1px;
                left: 0;
                transform: rotate(-45deg);
        }
        &:last-of-type{
                top: 5px;
                left: 0;
                transform: rotate(45deg);
        }
    }   
`;
export const PopUpButtonGroup = styled.div`
    text-align: center;
    margin-top: 30px;
    button {
        display: inline-block;
        margin: 0 2px;
    }
`;

const TableBox = styled.div`
    overflow: visible;
    overflow: auto;
    table {
        width: 100%;
        border-spacing: 0;
        thead {
            tr {
                color: ${({ theme }) => theme.colors.blue};
                font-weight: 800;
                :nth-child(1) {
                    display: none;
                }

                th {
                    position: sticky;
                    background-color: white;
                    top: 0px;
                    box-shadow: 0px 1px 0px 0 rgba(0, 0, 0, 0.1);
                    padding: 10px;
                }
            }
        }
        tr {
            border-bottom: 1px solid #e1e1e1;
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th,
        td {
            max-width: 300px;
            text-align: center;
            margin: 0;
            padding: 20px 10px;
            border-bottom: 1px solid #e1e1e1;
            vertical-align: middle;
            :last-child {
                border-right: 0;
            }
        }

        .short {
            width: 100px;
        }
        .mid {
            min-width: 150px;
            width: 150px;
        }
        .busName {
            min-width: 150px;
            width: 200px;
        }
        .station {
            min-width: 150px;
            width: 250px;
        }
    }
`;

interface TableProps {
    columns: Array<Column>;
    data: any;
    getRowProps: any;
}

function Table({ columns, data, getRowProps = () => ({}) }: TableProps) {
    // Use the state and functions returned from useTable to build your UI

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow }: any = useTable(
        {
            columns,
            data
        },

        useGlobalFilter,
        useSortBy
    );

    // Render the UI for your table
    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup: any) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => {
                                return column.show === undefined || column.show ? (
                                    <th
                                        {...column.getHeaderProps([
                                            column.getSortByToggleProps(),
                                            { className: column.className }
                                        ])}
                                    >
                                        {column.render("Header")}
                                        <span>{column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}</span>
                                    </th>
                                ) : null;
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row: any) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps(getRowProps(row))}>
                                {row.cells.map((cell: any) => {
                                    return cell.column.show === undefined || cell.column.show ? (
                                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    ) : null;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}

export const ScheduleCheck: React.FC<ScheduleCheckProps> = ({
    handler,
    cancel,
    btnCancel,
    formattedDate,
    buttonStatus,
    setModify,
    buttonHandler
}) => {
    const { studentName, scheduleList } = useContext(ScheduleServiceContext);

    const [title, setTitle] = useState("");
    const [msg, setMsg] = useState("");
    const [btnSingle, setBtnSingle] = useState(false);
    const [editable, setEditable] = useState(true);
    const [changeData, setChangeData] = useState<any>();
    const [viewTable, setViewTable] = useState<boolean>(false);
    const [selectedWeekDay, setselectedWeekDay] = useState<string>("");
    const [moifyedCancle, setModifyedCancle] = useState<boolean>(false);
    const [clicked, setClicked] = useState<string>("");

    const checkBtnSave = (e: React.MouseEvent) => {
        e.preventDefault();
        handler(false);
        buttonHandler(true);
        if (btnCancel && editable) {
            scheduleList.forEach((schedule: any) => {
                schedule.changeBusName = "";
                schedule.changeCode = "";
                schedule.changeTime = "";
                schedule.seatFull = false;
                schedule.changeBoardSwitch = "";
            });
            cancel(false);
        } else if (!btnCancel && editable) {
            if (btnSingle) {
                cancel(true);
            } else {
                cancel(false);
            }
        } else if (!btnCancel && !editable) {
            cancel(false);
        } else {
            cancel(true);
        }
    };

    const checkChangeSchedule = useCallback(async () => {
        let isSeatFull = false;
        let isNotChange = 0;
        scheduleList.forEach((schedule: any) => {
            if (schedule.seatFull !== undefined && schedule.seatFull) {
                isSeatFull = true;
            }

            if (
                (schedule.changeTime === undefined || schedule.changeTime === "") &&
                (schedule.changeBoardSwitch === "" || schedule.changeBoardSwitch === undefined)
            ) {
                isNotChange++;
            }
        });

        setBtnSingle(false);
        setEditable(true);

        setTitle("취소 확인");
        setMsg("수정을 취소하시겠습니까?");
        if (buttonStatus !== "c") {
            if (isSeatFull) {
                setBtnSingle(true);
                setTitle("수정 불가");
                setMsg("빈 좌석이 없어 수정이 불가능합니다.");
                setEditable(false);
                setEditable(true);
            } else {
                setTitle("수정 확인");
                if (isNotChange === scheduleList.length) {
                    setMsg("수정 내역이 없습니다.");
                    setBtnSingle(true);
                    setEditable(false);
                    setViewTable(false);
                    setModify(false);
                } else {
                    const nameHtml = `<span>${studentName}</span>학생의 등하원 정보를 수정하시겠습니까?`;
                    setMsg(nameHtml);
                    setEditable(false);
                    setViewTable(true);
                    setModify(true);
                }
            }
        }
    }, [scheduleList, buttonStatus, studentName]);

    useEffect(() => {
        if (scheduleList.length > 0) {
            checkChangeSchedule().then();
            setselectedWeekDay(scheduleList[0].weekday.toString());

            let jsondata = {} as any;
            let temparray = [] as any;
            let tableData = [] as any;
            let resultData = [] as any; // usetable에 1개의 배열만 넘기기 위한 용도

            scheduleList.map((row: any, index: number) => {
                if (
                    (row.changeTime !== undefined && row.changeTime !== "") ||
                    (row.changeBoardSwitch !== undefined && row.changeBoardSwitch !== "")
                ) {
                    temparray.push(row);
                    jsondata.data = [index];
                    jsondata.row = temparray;
                    tableData.push(jsondata);
                }
            });
            resultData.push(tableData);
            setChangeData(resultData);
        }
    }, [checkChangeSchedule, scheduleList]);

    useEffect(() => {
        // 팝업이 닫히는 시점에 데이터 처리
        return () => {
            if (clicked !== "") {
                console.log("return : " + clicked);
                if ((buttonStatus === "c" && moifyedCancle) || (buttonStatus === "s" && moifyedCancle)) {
                    scheduleList.map((row: any) => {
                        row.changeTime = "";
                        row.changeBusName = "";
                        row.changeBoardSwitch = "";
                        row.changeCode = "";
                    });
                }
            }
        };
    }, [moifyedCancle]);

    const convertBoardSwitch = (data: string) => {
        if (data === "WILL_BOARD") {
            return "타요";
        } else {
            return "안타요";
        }
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                columns: [
                    {
                        Header: "날짜",
                        accessor: "weekday",
                        className: "short",
                        Cell: () => {
                            return (
                                <>
                                    {formattedDate}
                                    <div>{getWeekdayStr(selectedWeekDay)}</div>
                                </>
                            );
                        }
                    },
                    {
                        Header: "변경 전",
                        accessor: "status",
                        className: "mid",
                        Cell: (info: any) => {
                            return (
                                <>
                                    {info.data[0].length &&
                                        info.data[0].map((idx: any, index: number) => {
                                            return (
                                                <>
                                                    {idx.row[index].changeBoardSwitch !== undefined &&
                                                    (idx.row[index].changeTime === undefined ||
                                                        idx.row[index].changeTime === "") ? (
                                                        <div>
                                                            {idx.row[index].scheduleType + " "}
                                                            {convertBoardSwitch(idx.row[index].boardSwitch)}
                                                        </div>
                                                    ) : (idx.row[index].changeBoardSwitch === undefined ||
                                                          idx.row[index].changeBoardSwitch === "") &&
                                                      idx.row[index].changeTime !== undefined ? (
                                                        <div>
                                                            {idx.row[index].scheduleType + " "}
                                                            {formattingHHmm(idx.row[index].scheduleTime) + " "}
                                                            {idx.row[index].busName}
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div>
                                                                {idx.row[index].scheduleType + " "}
                                                                {convertBoardSwitch(idx.row[index].boardSwitch)}
                                                            </div>
                                                            <div>
                                                                {idx.row[index].scheduleType + " "}
                                                                {formattingHHmm(idx.row[index].scheduleTime) + " "}
                                                                {idx.row[index].busName}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            );
                                        })}
                                </>
                            );
                        }
                    },
                    {
                        Header: "변경 후",
                        accessor: "scheduleType",
                        className: "mid",
                        Cell: (info: any) => {
                            return (
                                <>
                                    {info.data[0].length &&
                                        info.data[0].map((idx: any, index: number) => {
                                            return (
                                                <>
                                                    {idx.row[index].changeBoardSwitch !== undefined &&
                                                    (idx.row[index].changeTime === undefined ||
                                                        idx.row[index].changeTime === "") ? (
                                                        <div>
                                                            {idx.row[index].scheduleType + " "}
                                                            {convertBoardSwitch(idx.row[index].changeBoardSwitch)}
                                                        </div>
                                                    ) : (idx.row[index].changeBoardSwitch === undefined ||
                                                          idx.row[index].changeBoardSwitch === "") &&
                                                      idx.row[index].changeTime !== undefined ? (
                                                        <div>
                                                            {idx.row[index].scheduleType + " "}
                                                            {formattingHHmm(idx.row[index].changeTime) + " "}
                                                            {idx.row[index].changeBusName}
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div>
                                                                {idx.row[index].scheduleType + " "}
                                                                {convertBoardSwitch(idx.row[index].changeBoardSwitch)}
                                                            </div>
                                                            <div>
                                                                {idx.row[index].scheduleType + " "}
                                                                {formattingHHmm(idx.row[index].changeTime) + " "}
                                                                {idx.row[index].changeBusName}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            );
                                        })}
                                </>
                            );
                        }
                    }
                ]
            }
        ],
        [changeData]
    );

    return (
        <div>
            <Title>{title}</Title>
            <Text dangerouslySetInnerHTML={{ __html: msg }}></Text>
            {buttonStatus === "s" && viewTable && (
                <TableBox>
                    <Table columns={columns} data={changeData || []} getRowProps={() => ({})} />
                </TableBox>
            )}
            <PopUpButtonGroup>
                {!btnSingle ? (
                    <Button
                        style={{ width: "98px", padding: "0", backgroundColor: "#e9e9e9" }}
                        onClick={e => {
                            e.preventDefault();
                            handler(false);
                            cancel(true);
                            setModifyedCancle(false);
                            setClicked("t");
                        }}
                    >
                        아니요
                    </Button>
                ) : (
                    ""
                )}

                <Button
                    style={{ width: "98px", padding: "0" }}
                    onClick={e => {
                        checkBtnSave(e);
                        setModifyedCancle(true);
                        setClicked("t");
                    }}
                >
                    {!btnSingle ? "예" : "확인"}
                </Button>
            </PopUpButtonGroup>
        </div>
    );
};
