import React, { useState, useContext, useEffect } from "react";
import { BoxHeader } from "./BusServiceStatus";
import styled from "styled-components";
import { media } from "styles/media";
import { PopUpNotice } from "components/layout/PopUpNotice";
import { NoticeDetail } from "components/notice/NoticeDetail";
import { DashboardContext } from "context/dashboard";
import NoticeRequest from "http/notice";
const noticeRequest = new NoticeRequest();

interface NoticeProps {}

const NoticeWrap = styled.div`
    ${BoxHeader} {
        margin-bottom: 0;
    }
`;
const BoxBody = styled.div``;

const NoticeList = styled.ul`
    max-height: 286px;
    overflow: auto;
    ${media.desktop} {
        height: 286px;
    }
`;

const NoticeLi = styled.li`
    padding: 20px 12px;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    :hover {
        border-radius: 10px;
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    }
    ${media.tablet} {
        padding: 20px 15px;
    }
`;

const Title = styled.div`
    flex: 1;
`;

const Date = styled.div`
    color: ${({ theme }) => theme.colors.grey};
    padding-left: 12px;
`;

export const Notice: React.FC<NoticeProps> = () => {
    const [noticeId, setNoticeId] = useState(0);
    const [noticeType, setNoticeType] = useState("");
    const { noticePopUp, setNoticePopUp } = useContext(DashboardContext);
    const [data, setData] = useState([]);

    // 30초 마다 공지사항 갱신
    useEffect(() => {
        const getData = async () => {
            noticeRequest.getPartnerNotices(
                (response: any) => {
                    if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                        alert(`[${response.data.code}] ${response.data.message}`);
                    } else {
                        setData(response.data.data);
                    }
                },
                (error: any) => {}
            );
        };
        getData();

        const timer = setInterval(getData, 30000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const getType = (type: String) => {
        if (type === "NOTICE") {
            return "[공지] ";
        } else if (type === "UPDATE") {
            return "[업데이트] ";
        }
        return "";
    };

    return (
        <NoticeWrap>
            <BoxHeader>
                <h2>공지사항</h2>
            </BoxHeader>
            <BoxBody>
                <NoticeList>
                    {data.map((notice: any) => {
                        return (
                            <NoticeLi
                                key={notice.id}
                                onClick={() => {
                                    setNoticeId(Number(notice.id));
                                    setNoticeType(notice.type);
                                    setNoticePopUp(true);
                                }}
                            >
                                <Title>
                                    {getType(notice.type)} {notice.title}
                                </Title>
                                <Date>{notice.publishedAt.substr(0, 10)}</Date>
                            </NoticeLi>
                        );
                    })}
                </NoticeList>
            </BoxBody>

            <PopUpNotice
                id={noticePopUp}
                handler={setNoticePopUp}
                title={"공지사항"}
                contents={<NoticeDetail id={noticeId} type={noticeType} />}
            />
        </NoticeWrap>
    );
};
