import React from "react";
import styled from "styled-components";
import { Box } from "styles/layout";
import { media } from "styles/media";

interface PopUpProps {
    // title: string;
    contents: any;
    id: boolean;
    handler: any;
    hasBackButton?: boolean | undefined;
}

interface ContainerProps {
    open: boolean;
}

const Container = styled.div<ContainerProps>`
    display: ${({ open }) => (open === true ? "block" : "none")};
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10010;
    padding: 0px;
`;

const BackgroundLayer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
`;

const Contents = styled(Box)`
    position: absolute;
    width: 575px;
    height: 604px;
    top: 50%;
    right: 50%;
    transform: translate3d(-50%, -50%, 0);
    padding: 0px;
    z-index: 100;
    border-radius: 12px;
    background-color: #fff;
    ${media.tablet} {
        width: 575px;
        height: 604px;
        top: 50%;
        left: 50%;
    }
`;

const PopUpBody = styled.div``;

export const StudentPopUp: React.FC<PopUpProps> = ({
    contents,
    id,
    handler,
}) => {
    return (
        <Container open={id}>
            <BackgroundLayer
                onClick={() => {
                    handler(false);
                }}
            />
            <Contents>
                <PopUpBody>{contents}</PopUpBody>
            </Contents>
        </Container>
    );
};
