import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "styles/theme";
import { Button, ButtonGroup } from "styles/ui";
import StudentRequest from "../../http/student";
import { useTable, Column } from "react-table";
import { getWeekdayStr } from "../DateUtil";
import Moment from "moment"; // 구현 후 삭제

const studentRequest = new StudentRequest();

interface ScheduleHistoryProps {
    studentId: number;
    scheduleId: number;
    handler: any;
}

const Title = styled.h3`
    margin-bottom: 30px;
    width: 100%;
    text-align: center;
`;
const History = styled.div`
    font-weight: normal;
    width: 800px;
    height: 300px;
    text-align: center;
    line-height: 1.6;
    font-size: 14px;
    margin: 30px -30px;
    overflow: auto;
    padding: 30px;
    background: rgba(23, 68, 144, 0.04);
    span {
        color: ${({ theme }) => theme.colors.orange};
        font-weight: 800;
    }
`;

const TableBox = styled.div`
    overflow: visible;
    overflow: auto;
    table {
        width: 100%;
        border-spacing: 0;
        thead {
            tr {
                color: ${({ theme }) => theme.colors.blue};
                font-weight: 800;
                :nth-child(1) {
                    display: none;
                }

                th {
                    position: sticky;
                    background-color: white;
                    top: 0px;
                    box-shadow: 0px 1px 0px 0 rgba(0, 0, 0, 0.1);
                    padding: 10px;
                }
            }
        }
        tr {
            border-bottom: 1px solid #e1e1e1;
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th,
        td {
            max-width: 300px;
            text-align: center;
            margin: 0;
            padding: 20px 10px;
            border-bottom: 1px solid #e1e1e1;
            :last-child {
                border-right: 0;
            }
        }

        .short {
            width: 100px;
        }
        .mid {
            min-width: 150px;
            width: 150px;
        }
        .busName {
            min-width: 150px;
            width: 200px;
        }
        .station {
            min-width: 150px;
            width: 250px;
        }
    }
`;

interface TableProps {
    columns: Array<Column>;
    data: any;
}
const Table = ({ columns, data }: TableProps) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

    return (
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(header => (
                    <tr {...header.getHeaderGroupProps()}>
                        {header.headers.map(col => (
                            <th {...col.getHeaderProps()}>{col.render("Header")}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export const ScheduleHistory: React.FC<ScheduleHistoryProps> = ({ studentId, scheduleId, handler }) => {
    const [history, setHistory] = useState<Array<string>>([]);
    const [converData, setConvertData] = useState<Array<string>>([]);
    const data = useMemo(() => converData, [converData]);

    useEffect(() => {
        let tempdata: Array<string> = [];
        let splitarray: Array<string> = [];

        history.map((data: any) => {
            if (data.afterChange === "") {
                data.scheduleDate = Moment(data.createAt).format("YYYY.MM.DD");
                data.scheduleTime = getWeekdayStr((Moment(data.createAt).day() + 1).toString());

                let beforedata = data.beforeChange.split("에서");
                splitarray = beforedata[0].split(" ");
                data.beforeChange = beforedata[0].replace(splitarray[0], "");
                data.afterChange = beforedata[1].replace("로 변경되었습니다.", "");
            } else {
                // 22년 3월 이후 log
                data.scheduleDate = Moment(data.scheduleDate).format("YYYY.MM.DD");
                data.scheduleTime = data.scheduleWeekday;
            }

            // 수정일시
            let tmpDate = Moment(data.createAt).format("YYYY.MM.DD HH:mm:ss");
            let formatSplit = tmpDate.split(" ");

            data.createAtDate = formatSplit[0];
            data.createAtTime = formatSplit[1];

            tempdata.push(data);
        });

        if (tempdata.length !== 0) {
            setConvertData(tempdata);
        } else setConvertData(history);
    }, [history]);

    // 히스토리 조회
    const saveSchedules = useCallback(async () => {
        studentRequest.getScheduleHistory(
            studentId,
            scheduleId,
            (response: any) => {
                if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                    alert(`[${response.data.code}] ${response.data.message}`);
                } else {
                    setHistory(response.data.data);
                }
            },
            () => {}
        );
    }, [studentId, scheduleId]);

    useEffect(() => {
        saveSchedules().then();
    }, [saveSchedules]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                columns: [
                    {
                        Header: "수정일시",
                        accessor: "createAt",
                        Cell: (info: any) => {
                            return (
                                <>
                                    <div>{info.data[info.row.index].createAtDate}</div>
                                    <div>{info.data[info.row.index].createAtTime}</div>
                                </>
                            );
                        }
                    },
                    {
                        Header: "날짜",
                        accessor: "scheduleDate",
                        Cell: (info: any) => {
                            return (
                                <>
                                    <div>{info.data[info.row.index].scheduleDate}</div>
                                    <div>{info.data[info.row.index].scheduleTime}</div>
                                </>
                            );
                        }
                    },
                    {
                        Header: "변경 전",
                        accessor: "beforeChange",
                        Cell: (info: any) => {
                            return <>{info.data[info.row.index].beforeChange}</>;
                        }
                    },
                    {
                        Header: "변경 후",
                        accessor: "afterChange",
                        Cell: (info: any) => {
                            return <>{info.data[info.row.index].afterChange}</>;
                        }
                    }
                ]
            }
        ],
        [history]
    );

    return (
        <div>
            <Title>수정 내역</Title>
            <History>
                <TableBox>
                    <Table columns={columns} data={data} />
                </TableBox>
            </History>
            <ButtonGroup>
                <Button
                    style={{ width: "90px", padding: "0" }}
                    onClick={e => {
                        e.preventDefault();
                        handler(false);
                    }}
                >
                    확인
                </Button>
            </ButtonGroup>
        </div>
    );
};
