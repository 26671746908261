import React from "react";
import styled from "styled-components";
import { CloseButton } from "assets/images";

type PopUpProps = {
    width?: string;
    height?: string;
    handler?: any;
    title?: string;
    contents?: string;
    confirm?: any;
    type?: string;
    right?: string;
    rightButton?: string;
};

const StatusPopUp = ({
    width,
    height,
    handler,
    title,
    contents,
    confirm,
    type,
    right,
    rightButton,
}: PopUpProps) => {
    return (
        <>
            <Container right={right} style={{ width: width, height: height }}>
                <CancelImg
                    onClick={() => handler(false)}
                    src={CloseButton}
                    alt="cancel"
                />
                <TitleWrapper>
                    <Title>{title}</Title>
                </TitleWrapper>
                <ContentsWrapper>
                    <Contents>{contents}</Contents>
                </ContentsWrapper>

                <ButtonWrapper>
                    {type === "single" ? (
                        ""
                    ) : (
                        <CancelButton
                            onClick={() => {
                                handler(false);
                            }}
                        >
                            <ButtonSpan style={{ fontWeight: 700 }}>
                                취소
                            </ButtonSpan>
                        </CancelButton>
                    )}

                    <ApplyButton
                        onClick={() => confirm()}
                        style={{ backgroundColor: "#FFD100" }}
                    >
                        <ButtonSpan
                            style={{ color: "#332A00", fontWeight: 700 }}
                        >
                            {rightButton ? rightButton : "저장"}
                        </ButtonSpan>
                    </ApplyButton>
                </ButtonWrapper>
            </Container>
        </>
    );
};

export default StatusPopUp;

type ContainerProps = {
    right?: string;
};

const Container = styled.div<ContainerProps>`
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    border-radius: 12px;
    background-color: #fff;
    padding: 30px 10px;
    border-radius: 22px;
    justify-content: center;
    text-align: center;
    top: 50%;
    right: ${(props) => (props.right ? props.right : "-10%")};
    z-index: 120;
    transform: translate3d(-50%, -50%, 0);
`;

const CancelImg = styled.img`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
`;

const TitleWrapper = styled.div`
    margin-bottom: 16px;
`;

const Title = styled.span`
    color: #1e2939;
    text-align: center;
    font-family: NanumSquareRound;
    font-size: 16px;
    font-weight: 800;
    line-height: 18px;
`;

const ContentsWrapper = styled.div`
    margin-bottom: 30px;
`;

const Contents = styled.span`
    color: #1e2939;
    text-align: center;
    font-family: NanumSquareRound;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fff;
`;

const CancelButton = styled.button`
    margin-right: 4px;
    width: 98px;
    height: 36px;
    border-radius: 30px;
    background: #efefef;
    border: 1px solid #efefef;
`;

const ApplyButton = styled.button`
    width: 98px;
    height: 36px;
    border-radius: 30px;
    background-color: #fff1b3;
    border: 1px solid #fff1b3;
`;

const ButtonSpan = styled.span`
    color: #000;
    font-size: 14px;
    line-height: 16px;
    font-weight: 800;
    font-family: NanumSquareRound;
`;
