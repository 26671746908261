import { styled } from "styles/theme";
import React from "react";

interface BusNameProps {
    data: any;
}

const SelectBus = styled.div`
    display: block;
    padding: 2px 12px;
    width: 210px;
    height: 26px;
    font-family: NanumSquareRound;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #666;
    line-height: 22px;
    border-radius: 60px;
    text-align: left;
    background: #eee;
    ::placeholder {
        color: #cecece;
        font-size: 14px;
        letter-spacing: 0;
    }
    border-radius: 60px;
`;

const Arrow = styled.span`
    width: 9px;
    height: 9px;
    display: inline-block;
    position: absolute;
    top: 12px;
    right: 12px;
    span {
        width: 6px;
        height: 2px;
        position: absolute;
        background-color: #c4c4c4;
        display: inline-block;
        border-radius: 6px;
        transition: all 0.2s ease;
        &:first-of-type {
            right: 0;
            transform: rotate(-45deg);
        }
        &:last-of-type {
            left: 0;
            transform: rotate(45deg);
        }
    }
`;

export const BusName: React.FC<BusNameProps> = ({ data }) => {
    return (
        <div style={{ position: "relative", display: "inline-block" }}>
            <SelectBus>
                {data.changeBusName !== undefined && data.changeBusName !== "" ? data.changeBusName : data.busName}
            </SelectBus>
            <Arrow>
                <span />
                <span />
            </Arrow>
        </div>
    );
};
