import Request from "./request";

class StudentRequest extends Request {
    /**
     * 총 학생수 (useGetStudentsCountQuery)
     * params
     *      academyId   (토큰활용)
     *
     * callback
     *      콜백함수
     *
     * errorCallback
     *      오류콜백함수
     */
    getStudentsCount = (callback, errorCallback) => {
        const request = {
            callback,
            errorCallback,
            includeAcademyId: true
        };
        this.get("/dashboard/students/cnt/total", request);
    }

    /**
     * 오늘 총 신규 학생 (useGetStudentsCountQuery)
     * params
     *      academyId   (토큰활용)
     *
     * callback
     *      콜백함수
     *
     * errorCallback
     *      오류콜백함수
     */
    getNewStudentsCount = (callback, errorCallback) => {
        const request = {
            callback,
            errorCallback,
            includeAcademyId: true
        };
        this.get("/dashboard/students/cnt/new", request);
    }

    /**
     * 신규학생 생성  (useRegisterStudentMutation)
     * params
     *      parentCode
     *      name
     *      nickName
     *      phone
     *      stationId
     *
     * callback
     *      콜백함수
     *
     * errorCallback
     *      오류콜백함수
     */
    addStudent = (data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback
        };
        this.post("/students", request);
    }

    /**
     * 해당 학생의 타요/안타요 스위치 수정 (useUpdateBoardSwitch)
     * params
     *      scheduleId  스케쥴 ID
     *      studentId   학생 ID
     *      data        boardSwitch: "true" or "false"
     *
     * callback
     *      콜백함수
     *
     * errorCallback
     *      오류콜백함수
     */
    updateBoardSwitch = (scheduleId, studentId, data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback
        };
        this.put(`/students/${studentId}/schedules/${scheduleId}/boarding-switch`, request);
    }

    /**
     * 해당 학생의 일일 스케줄 조회
     * params
     *      academyId   학원 ID
     *      studentId   학생 ID
     *      date        해당 일
     *
     * callback
     *      콜백함수
     *
     * errorCallback
     *      오류콜백함수
     */
    getSchedules = (weekdaySchedule, data, studentId, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback,
            includeAcademyId: true
        };
        let param = `date=${data.date}`;
        param += `&studentId=${data.studentId}`;
        if(weekdaySchedule){
            this.get(`/students/${studentId}/schedules/week?` + param, request);
        }else{
            this.get(`/students/${studentId}/schedules/day?` + param, request);
        }
    }

    /**
     * 해당 학생의 일일 스케줄 변경
     * params
     *      scheduleId  스케쥴 ID
     *      studentId   학생 ID
     *      data        boardSwitch: "true" or "false"
     *
     * callback
     *      콜백함수
     *
     * errorCallback
     *      오류콜백함수
     */
    updateSchedules = (studentId, data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback
        };
        this.put(`/students/${studentId}/schedules`, request);
    }

    /**
     * 해당 학생의 스케줄 변경 조회
     * params
     *      scheduleId  스케쥴 ID
     *      studentId   학생 ID
     *      data        boardSwitch: "true" or "false"
     *
     * callback
     *      콜백함수
     *
     * errorCallback
     *      오류콜백함수
     */
    getScheduleHistory = (studentId, scheduleId, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/students/${studentId}/schedules/${scheduleId}/logs`, request);
    }
}

export default StudentRequest;
