/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useContext, useEffect, useState, useRef } from "react";
import axios from "axios";
import { Map, TileLayer, Marker, Popup, Polyline } from "react-leaflet";
import { BusServiceContext } from "context/busService";
import L from "leaflet";
import DepartureStationIcon from "assets/images/icons/departure_station_icon.svg";
import StationIcon from "assets/images/icons/map/map_station_icon.svg";
import ArrivalStationIcon from "assets/images/icons/arrival_station_icon.svg";
import DepartureStationIconMini from "assets/images/icons/map/map_departure_station_icon_mini.svg";
import ArrivalStationIconMini from "assets/images/icons/map/map_arrival_station_icon_mini.svg";
import StationIconMini from "assets/images/icons/map/map_station_icon_mini.svg";
import CourseLineIcon from "assets/images/icons/map/map_course_line_icon_mini.svg";
import GPSLineIcon from "assets/images/icons/map/map_GPS_line_icon_mini.svg";
import BusIcon from "assets/images/icons/map/map_bus_icon.svg";
import styled from "styled-components";
import { StationName } from "./Station";
import { Icon } from "styles/ui";
import _ from "lodash";
import { OnBoardMap } from "./OnBoardMap";
import { OffBoardMap } from "./OffBoardMap";
import BusRequest from "http/bus";
const busRequest = new BusRequest();

interface BusServiceMapProps {
    busList?: string[];
    studentId: number | undefined;
    courseStations?: string[];
    center?: any;
    dispatchCode?: string;
    detailActive: boolean;
    setCourseStations?: React.Dispatch<React.SetStateAction<string[]>>;
    setCenter?: React.Dispatch<React.SetStateAction<any>>;
}

export const BusServiceMap: React.FC<BusServiceMapProps> = ({
    busList,
    studentId,
    courseStations, // 노선 상세
    center,
    dispatchCode,
    detailActive,
    setCourseStations,
    setCenter,
}) => {
    const [bounds, setBounds] = useState();
    const [lineCoordinates, setLineCoordinates] = useState<any[]>([]);

    interface ViewportInterface {
        center: [number, number] | null | undefined;
        zoom: number | null | undefined;
    }

    useEffect(() => {
        setViewport(center);
    }, [center]);

    // const [viewport, setViewport] = useState<ViewportInterface>({
    //     center: undefined,
    //     zoom: undefined,
    // });
    const [viewport, setViewport] = useState<ViewportInterface>(center);

    const getRoutesOptimizationAPIType = (
        stationLength: number
    ): 10 | 20 | 30 | 100 => {
        if (stationLength <= 10) return 10;
        else if (stationLength <= 20) return 20;
        else if (stationLength <= 30) return 30;
        else return 100;
    };

    // 배차 코스에 대한 정보
    const getRoutesOptimization = (courseStation: any) => {
        // session storage에 courseCode의 Key값의 데이터가 있다면 setLineCoordinates 후 early return
        let storageCourseCodeCoordinates: any;
        try {
            storageCourseCodeCoordinates = JSON.parse(
                sessionStorage.getItem(dispatchCode) ?? "[]"
            );
        } catch (e) {
            storageCourseCodeCoordinates = [];
        }

        if (storageCourseCodeCoordinates.length) {
            setLineCoordinates(storageCourseCodeCoordinates);
            return;
        }

        const stationLength = courseStation.length;
        const startStation = courseStation[0];
        const endStation = courseStation[stationLength - 1];

        let viaPoints;

        if (courseStation.length > 2) {
            // 출발과 도착 정류장을 제외하기 위한 slice
            viaPoints = courseStation
                .slice(1, stationLength - 1)
                .map((station: any, index: number) => ({
                    viaPointId: `${index + 1}`,
                    viaPointName: `stopover_${index + 1}`,
                    viaX: `${station.station.lng}`,
                    viaY: `${station.station.lat}`,
                    viaTime: 0,
                }));
        } else {
            // 경유지가 없으면 경유지에 도착지 위경도 값을 넣어 경유지 생성
            // viaX와 viaY에 startStation 값을 넣으면 T-MAP API 에서 오류 발생함
            viaPoints = [
                {
                    viaPointId: "1",
                    viaPointName: "stopover_1",
                    viaX: `${endStation.station.lng}`,
                    viaY: `${endStation.station.lat}`,
                    viaTime: 0,
                },
            ];
        }

        const routeInfo = {
            carType: "3",
            searchOption: "10",
            startName: "00",
            startX: `${startStation.station.lng}`,
            startY: `${startStation.station.lat}`,
            startTime: "202303280000",
            endName: "99",
            endX: `${endStation.station.lng}`,
            endY: `${endStation.station.lat}`,
            viaPoints: viaPoints,
            endPoiId: "",
            wishStartTime: "202303281200",
            wishEndTime: "202303281400",
        };

        busRequest.getTMapRouteOptimizationCourseLine(
            getRoutesOptimizationAPIType(stationLength),
            routeInfo,
            (response: any) => {
                if (response.status === 200) {
                    const coordinatesArray: any[] = [];
                    response.data.features?.forEach((feature: any) => {
                        if (feature.geometry.type === "LineString") {
                            feature.geometry.coordinates.forEach(
                                (coordinate: any) => {
                                    coordinatesArray.push([
                                        coordinate[1],
                                        coordinate[0],
                                    ]);
                                }
                            );
                        }
                    });
                    sessionStorage.setItem(
                        dispatchCode,
                        JSON.stringify(coordinatesArray)
                    );
                    setLineCoordinates(coordinatesArray);
                } else {
                    console.log(
                        "getCourseLine : " +
                            `[${response.data.code}] ${response.data.message}`
                    );
                }
            },
            (error: any) => setCourseStations([])
        );
    };

    useEffect(() => {
        if (courseStations?.length > 0) {
            getRoutesOptimization(courseStations);
        }
    }, [courseStations]);

    // 2. 코스의 정류장 리스트 API
    // const getCourseStationsData = async () => {
    //     await busRequest.getCourseStations(
    //         courseCode,
    //         (response: any) => {
    //             if (
    //                 response.status === -1 ||
    //                 (response.status === 200 && response.data.code !== "OK")
    //             ) {
    //                 console.log(
    //                     `[${response.data.code}] ${response.data.message}`
    //                 );
    //             } else {
    //                 // setCourseStations(response.data.data);
    //                 getRoutesOptimization(response.data.data);
    //             }
    //         },
    //         (error: any) => {
    //             // setCourseStations([]);
    //         }
    //     );
    // };

    // useEffect(() => {
    //     if (courseCode) {
    //         const getData = async () => {
    //             //await getCourseInfoData();
    //             // await getCourseStationsData();
    //         };
    //         getData();
    //     }
    //     // eslint-disable-next-line
    // }, [courseCode]);

    const getBounds: any | undefined = (stations: any | undefined) => {
        let stationBounds: Array<any> = [];
        stations.map((station: any) => {
            let stationLatLng = [station?.station?.lat, station?.station?.lng];
            stationBounds.push(stationLatLng);
            return true;
        });
        const bounds = L.latLngBounds(stationBounds);
        return bounds;
    };

    useEffect(() => {
        if (_.isEmpty(courseStations) === false) {
            setBounds(getBounds(courseStations));
        }
    }, [courseStations]);

    useEffect(() => {
        setViewport({
            center: [center?.lat, center?.lng],
            zoom: 15,
        });
    }, [center]);

    return dispatchCode !== "" && busList?.length > 0 && detailActive ? (
        <MapBox className="bus-service-map">
            <MapInfo className="map-info">
                <ul>
                    <li>
                        <Icon
                            img={DepartureStationIconMini}
                            width={"20px"}
                            height={"20px"}
                        />
                        시작 지점
                    </li>
                    <li>
                        <Icon
                            img={ArrivalStationIconMini}
                            width={"20px"}
                            height={"20px"}
                        />
                        도착 지점
                    </li>
                    <li>
                        <Icon
                            img={StationIconMini}
                            width={"20px"}
                            height={"20px"}
                        />
                        정류장
                    </li>
                    <li>
                        <Icon
                            img={CourseLineIcon}
                            width={"20px"}
                            height={"20px"}
                        />
                        예상 경로
                    </li>
                </ul>
            </MapInfo>
            <StyledMap
                viewport={viewport}
                bounds={bounds}
                className={"leaflet-map"}
            >
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {/* {_.isEmpty(courseStations) === false && */}
                {courseStations?.map((station: any, index: number) => {
                    const lat: number = parseFloat(station?.station?.lat);
                    const lng: number = parseFloat(station?.station?.lng);
                    const stationLength = courseStations.length;
                    const stationId = Number(station.id);

                    return (
                        <div key={index}>
                            <Marker
                                key={station.id}
                                position={[lat, lng]}
                                icon={getStationIcon(
                                    index,
                                    station,
                                    stationLength
                                )}
                            >
                                {/* {station?.station?.name} */}
                                {/* <StyledPopup>
                                    <StationName
                                        title={"정류장 지도 중앙 위치 >"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setCenter({
                                                lat: Number(station?.lat),
                                                lng: Number(station?.lng),
                                            });
                                        }}
                                    >
                                        {station?.station?.name}
                                    </StationName> */}
                                {/* <OnBoardMap
                                    stationId={stationId}
                                    schedules={schedules}
                                    studentId={studentId}
                                />
                                <OffBoardMap
                                    stationId={stationId}
                                    schedules={schedules}
                                    studentId={studentId}
                                /> */}
                                {/* </StyledPopup> */}
                            </Marker>
                        </div>
                    );
                })}

                {lineCoordinates && (
                    <Polyline
                        color="black"
                        positions={lineCoordinates}
                        weight={8}
                    />
                )}
                {lineCoordinates && (
                    <Polyline
                        color="#ffcd00"
                        positions={lineCoordinates}
                        weight={4}
                    />
                )}
                {/* {gpsLine && (
                    <Polyline color="black" positions={gpsLine} weight={6} />
                )}
                {gpsLine && (
                    <Polyline color="#ff7900" positions={gpsLine} weight={4} />
                )} */}

                {/* <Marker
                    position={[
                        busCoordinates.lat,
                        busCoordinates.lng,
                    ]}
                    icon={getBusIcon()}
                    zIndexOffset={1000}
                ></Marker> */}
                {/* 
                {busGpsLocation && status === 1 && (
                    <Marker
                        position={[
                            busGpsLocation.lat || 0,
                            busGpsLocation.lng || 0,
                        ]}
                        icon={getBusIcon()}
                        zIndexOffset={1000}
                    ></Marker>
                )} */}
            </StyledMap>
        </MapBox>
    ) : null;
};

const getStationIcon = (index: number, station: any, stationLength: number) => {
    const icon = L.divIcon({
        className: "station-icon",
        html: `<div className="index" style="
                width: 30px;
                background: url(${
                    index === 0
                        ? DepartureStationIcon
                        : index === stationLength - 1
                        ? ArrivalStationIcon
                        : StationIcon
                }) center center no-repeat;
                background-size:contain;
                width:48px;
                height:48px;
                display:inline-flex;
                align-items:center;
                justify-content:center;
                transform:translate3d(calc(-50% + 6px),calc(-100% + 12px),0);
                position:relative;
            "><div style="color:white;font-size:18px;font-weight:800;position: absolute; top: 50%; left: 50%; transform: translate(calc(-50% - 1px), calc(-50% - 3px));">${
                index === 0 || index === stationLength - 1 ? "" : index
            }</div><div className="name" style="display:none;width:100px;padding:10px;margin-left:-50px;background:pink">${
            station.station.name
        }</div></div>`,
    });
    return icon;
};

const getBusIcon = () => {
    const icon = L.divIcon({
        className: "bus-icon",
        html: `<div className="index" style="
                width: 30px;
                background: url(${BusIcon}) center center no-repeat;
                background-size:contain;
                width:40px;
                height:40px;
                display:inline-flex;
                align-items:center;
                justify-content:center;
                transform:translate3d(calc(-50% + 6px),calc(-100% + 12px),0);
                position:relative;
                z-index:1000
            ">`,
    });
    return icon;
};

// const useInterval = (callback:any, delay:any) => {
//     const savedCallback = useRef<any>(null);

//     // Remember the latest callback.
//     useEffect(() => {
//       savedCallback.current = callback;
//     }, [callback]);

//     // Set up the interval.
//     useEffect(() => {
//       function tick() {
//         savedCallback.current?.();
//       }
//       if (delay !== null) {
//         let id = setInterval(tick, delay);
//         return () => clearInterval(id);
//       }
//     }, [delay]);
//   }

const StyledMap = styled(Map)`
    border-radius: 30px;
    width: 100%;
    height: calc(100vh - 265px);
`;

const MapBox = styled.div`
    position: relative;
`;

const MapInfo = styled.div`
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    top: 20px;
    left: 46px;
    z-index: 1002;
    max-width: calc(100% - 56px);
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    ul {
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        flex-wrap: wrap;
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 4px 10px;
            ${Icon} {
                margin-right: 4px;
            }
        }
    }
`;

const StyledPopup = styled(Popup)`
    ${StationName} {
        padding-right: 15px;
        max-width: 200px;
    }
`;

interface CourseLineInterface {
    lat?: string;
    lng?: string;
}

interface CourseInfoInterface {
    studentId?: number | any;
    courseName?: string;
    totalDistance?: number;
    totalDuration?: number;
    courseLine?: CourseLineInterface;
}

interface CourseStationsInterface {
    id?: number;
    name?: string;
    address?: string;
    roadAddress?: string;
    lat?: number;
    lng?: number;
}

interface BusGpsLocationInterface {
    busId?: number;
    dispatchId?: number;
    status?: number;
    lat?: number;
    lng?: number;
    masterId?: number;
}

interface BusCoordinatesInterface {
    lat: number;
    lng: number;
}
