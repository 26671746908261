import React from "react";
import styled from "styled-components";
import { flexColumn, flexRow } from "styles/CommonStyle";

type CancelConfirmProps = { onClose: () => void; handleConfirm: () => void };

function CancelConfirmPopUp(props: CancelConfirmProps) {
  return (
    <Container>
      <Title>취소 확인</Title>
      <Content>
        게시글 작성을 취소 하시겠습니까?
        <br />
        취소시 해당 내용은 저장되지 않습니다.
      </Content>
      <ButtonWrapper>
        <CancelButton onClick={props.onClose}>아니요</CancelButton>
        <RegisterButton onClick={props.handleConfirm}>예</RegisterButton>
      </ButtonWrapper>
    </Container>
  );
}

export default CancelConfirmPopUp;

const Container = styled.div`
  width: 100%;
  height: 100%;
  ${flexColumn};
  justify-content: center;
`;

const Title = styled.div`
  color: #1e2939;
  text-align: center;
  font-family: NanumSquareRound;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 16px;
  line-height: 18px;
`;

const Content = styled.div`
  color: #1e2939;
  text-align: center;
  font-family: NanumSquareRound;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.42px;
  line-height: 20px;
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  ${flexRow};
  justify-content: center;
  column-gap: 4px;
  align-items: center;
`;

const CancelButton = styled.div`
  border-radius: 30px;
  background: #efefef;
  width: 98px;
  height: 36px;
  color: #000;
  line-height: 16px;
  font-family: NanumSquareRound;
  font-size: 14px;
  font-weight: 800;
  padding: 10px 22px;
  cursor: pointer;
  text-align: center;
`;

const RegisterButton = styled(CancelButton)`
  background: #ffcd00;
  color: #000;
`;
