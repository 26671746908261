import React, { useState, useRef } from "react";
import styled from "styled-components";
import SearchIconYellow from "assets/images/icons/search_icon_yellow.svg";
import { media } from "styles/media";

const SearchBox = styled.div`
    position: relative;
    border-radius: 8px;
    background: #fff;
    border: 0px solid #fff;
    :focus,
    :hover,
    :active {
        outline: none;
        -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
    }
`;

export const SearchInput = styled.input`
    padding-left: 12px;
    background: transparent url(${SearchIconYellow}) right 12px center no-repeat;
    background-size: 18px 18px;
    border: 1px solid #cecece;
    width: 100%;
    height: 34px;
    border-radius: 8px;
    color: #a7a9ac;
    font-family: NanumSquareRound;
    font-size: 12px;
    :focus,
    :hover {
        color: #000;
        outline: none;
    }
    ${media.tablet} {
        font-size: 12px;
    }
`;

interface SearchProps {
    setSearchKeyword?: React.Dispatch<React.SetStateAction<any>>;
}

export const Search: React.FC<SearchProps> = (props: SearchProps) => {
    const { setSearchKeyword } = props;
    const [keyword, setKeyword] = useState("");
    const [result, setResult] = useState(false);
    const searchInput = useRef<HTMLInputElement>(null);

    return (
        <SearchBox
            onFocus={(e) => setResult(true)}
            onMouseEnter={async (e) => {
                await setResult(true);
                await searchInput.current?.focus();
            }}
            onMouseLeave={async () => {
                await setResult(false);
                await searchInput.current?.blur();
            }}
        >
            <form>
                <SearchInput
                    ref={searchInput}
                    id="search"
                    type="text"
                    placeholder={"학생 이름 혹은 학부모 전화번호 뒷자리 4자리"}
                    value={keyword}
                    onChange={(e) => {
                        setKeyword(e.target.value);
                        setSearchKeyword(e.target.value);
                    }}
                    autoComplete="off"
                />
            </form>
        </SearchBox>
    );
};
