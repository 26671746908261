/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { Fragment, useState, useContext, useEffect, useRef } from "react";
import { BoxBody } from "components/dashboard/BusServiceStatus";
import { Label, Input, Button, ButtonGrey, Comment } from "styles/ui";
import { BoxFooter } from "styles/layout";
import { InfoList, Info } from "./UserAccount";
import { ProfileContext } from "context/profile";
import OperatorRequest from "http/operator";
const operatorRequest = new OperatorRequest();

interface UpdateUserAccountProps {
    email: string;
}

export const UpdateUserAccount: React.FC<UpdateUserAccountProps> = ({ email }) => {
    const { setUpdateUserAccount } = useContext(ProfileContext);

    const [password, setPassword] = useState({
        ex: "",
        new: "",
        newConfirm: ""
    });

    const [isValid, setIsValid] = useState({
        oldPassword: true,
        newPassword: true,
        newPasswordConfirm: true
    });

    const [comment, setComment] = useState({
        ex: "",
        new: "",
        newConfirm: ""
    });

    const oldPasswordInput = useRef<HTMLInputElement>(null);
    const newPasswordInput = useRef<HTMLInputElement>(null);
    const newPasswordConfirmInput = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const resetPassword = () => {
        setPassword({
            ex: "",
            new: "",
            newConfirm: ""
        });
        setIsValid({
            oldPassword: true,
            newPassword: true,
            newPasswordConfirm: true
        });
        setComment({
            ex: "",
            new: "",
            newConfirm: ""
        });
    };

    useEffect(() => {
        resetPassword();
    }, []);

    const onSubmitUpdate = async (e: React.FormEvent) => {
        e.preventDefault();

        if (password.ex === "") {
            setComment({
                ex: "를 입력해주세요.",
                new: "",
                newConfirm: ""
            });
            setIsValid({
                oldPassword: false,
                newPassword: true,
                newPasswordConfirm: true
            });
            oldPasswordInput.current?.focus();
            return;
        } else if (password.new === "") {
            setComment({
                ex: "",
                new: "를 입력해주세요.",
                newConfirm: ""
            });
            setIsValid({
                oldPassword: true,
                newPassword: false,
                newPasswordConfirm: true
            });
            newPasswordInput.current?.focus();
            return;
        } else if (password.new !== password.newConfirm) {
            setComment({
                ex: "",
                new: "",
                newConfirm: "에 실패했습니다. 다시 입력해주세요."
            });
            setIsValid({
                oldPassword: true,
                newPassword: true,
                newPasswordConfirm: false
            });
            newPasswordConfirmInput.current?.focus();
            return;
        }

        const param = {
            email,
            oldPassword: password.ex,
            newPassword: password.newConfirm
        };

        await operatorRequest.updateUserPassword(
            param,
            (response: any) => {
                if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                    alert(`[${response.data.code}] ${response.data.message}`);
                    setLoading(false);
                } else {
                    resetPassword();
                    alert("비밀번호를 변경하였습니다.");
                    setUpdateUserAccount(false);
                    window.location.reload();
                }
            },
            (error: any) => {}
        );
    };

    return (
        <Fragment>
            <form onSubmit={onSubmitUpdate}>
                <BoxBody>
                    <InfoList>
                        <Info>
                            <Label htmlFor="newEmail">이메일</Label>
                            <Input
                                id="newEmail"
                                type="email"
                                value={email}
                                autoComplete="user email"
                                isValid={true}
                                disabled={true}
                            />
                        </Info>
                        <Info>
                            <Label htmlFor="oldPassword">
                                기존 비밀번호<Comment>{comment.ex}</Comment>
                            </Label>
                            <Input
                                ref={oldPasswordInput}
                                id="oldPassword"
                                type="password"
                                value={password.ex}
                                autoComplete="current-password"
                                onChange={e =>
                                    setPassword({
                                        ...password,
                                        ex: e.target.value
                                    })
                                }
                                style={{ letterSpacing: 5 }}
                                isValid={isValid.oldPassword}
                            />
                        </Info>
                        <Info>
                            <Label htmlFor="newPassword">
                                새 비밀번호<Comment>{comment.new}</Comment>
                            </Label>
                            <Input
                                ref={newPasswordInput}
                                id="newPassword"
                                type="password"
                                value={password.new}
                                autoComplete="current-password"
                                onChange={e =>
                                    setPassword({
                                        ...password,
                                        new: e.target.value
                                    })
                                }
                                style={{ letterSpacing: 5 }}
                                isValid={isValid.newPassword}
                            />
                        </Info>
                        <Info>
                            <Label htmlFor="newPasswordConfirm">
                                새 비밀번호 확인
                                <Comment>{comment.newConfirm}</Comment>
                            </Label>
                            <Input
                                ref={newPasswordConfirmInput}
                                type="password"
                                value={password.newConfirm}
                                autoComplete="current-password"
                                onChange={e =>
                                    setPassword({
                                        ...password,
                                        newConfirm: e.target.value
                                    })
                                }
                                style={{ letterSpacing: 5 }}
                                isValid={isValid.newPasswordConfirm}
                            />
                        </Info>
                    </InfoList>
                </BoxBody>
                <BoxFooter>
                    <Button type="submit">{loading ? "변경중⋅⋅⋅" : "변경"}</Button>
                    <ButtonGrey
                        onClick={async e => {
                            e.preventDefault();
                            await resetPassword();
                            setUpdateUserAccount(false);
                        }}
                    >
                        취소
                    </ButtonGrey>
                </BoxFooter>
            </form>
        </Fragment>
    );
};
