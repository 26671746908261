import React, { useState, useContext, createRef, useEffect } from "react";
import OperatorRequest from "http/operator";
import AuthRequest from "http/auth";
import styled from "styled-components";
import { RouteComponentProps } from "react-router-dom";
import { AuthContext } from "context/auth";
import { ReactComponent as YellowbusLogo } from "../assets/images/common/ic_yellowbus_logo.svg";
import { Checkbox, CheckboxInput, CheckboxLabel, Label, Input } from "styles/ui";
import jwt_decode from "jwt-decode";
const authRequest = new AuthRequest();
const operatorRequest = new OperatorRequest();

interface ButtonProps {
    disabled: boolean;
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    padding: 20px;
    background: #f7f7f7;
`;

const Contents = styled.div`
    width: 100%;
    max-width: 348px;
    text-align: center;
`;

const Form = styled.form`
    width: 100%;
    margin-top: 36px;
    text-align: left;
`;

const LoginForm = styled.div`
    li {
        margin-bottom: 20px;
    }
`;

const LoginButton = styled.button<ButtonProps>`
    color: ${props => (props.disabled === true ? props.theme.colors.white : props.theme.colors.black)};
    background: ${props => (props.disabled === true ? "#cecece" : props.theme.colors.yellow)};
    border: none;
    border-radius: 120px;
    height: 40px;
    margin-top: 4px;
    :hover {
        cursor: ${props => (props.disabled === true ? "not-allowed" : "pointer")};
    }
`;

const LoginFunction = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
`;

const LoginLabel = styled(Label)`
    color: ${({ theme }) => theme.colors.black};
    font-weight: 400;
    margin-bottom: 6px;
`;

const FindIdPw = styled.a`
    color: ${props => props.theme.colors.orange};
    text-decoration: underline;
`;

const Footer = styled.div`
    font-size: 10px;
    line-height: 18px;
    color: #585858;
`;

const Copyright = styled.div`
    margin-bottom: 2px;
`;

const Contact = styled.div`
    a {
        color: #585858;
    }
`;

const Version = styled.div`
    margin-top: 11px;
    color: #999999;
    line-height: 1;
`;

const LoginInput = styled(Input)`
    margin-bottom: 10px;
`;

interface infoInterface {
    exp: number;
    expireAt: number;
    iat: number;
    id: number;
    role: string;
    sub: string;
    type: string;
    userType: string;
}

interface dataInterface {
    academy?: {
        id?: number;
        name?: string;
    };
    id?: number;
    name?: string;
    email?: string;
    phone?: string;
    role?: string;
    enrolledAt?: string;
}
export const Login: React.FC<RouteComponentProps> = ({ history }) => {
    const { loggedIn, setLoggedIn, setAcademyId } = useContext(AuthContext);
    const [loginLoading, setLoginLoading] = useState<boolean>(false);
    const emailInput = createRef<HTMLInputElement>();
    const passwordInput = createRef<HTMLInputElement>();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isSaveIdChecked, setIsSaveIdChecked] = useState(false);

    if (loggedIn) {
        history.push("/dashboard");
    }

    useEffect(() => {
        const savedEmail = localStorage.getItem("id");
        const saveId = localStorage.getItem("saveId");

        if (savedEmail) {
            setEmail(savedEmail);
        }
        if (saveId) {
            setIsSaveIdChecked(true);
        }
    }, []);

    useEffect(() => {
        email && password ? setButtonDisabled(false) : setButtonDisabled(true);
    }, [email, password]);

    const onSubmitLogin = async (e: React.FormEvent) => {
        e.preventDefault();

        if (isSaveIdChecked) {
            localStorage.setItem("id", `${email}`);
            localStorage.setItem("saveId", `${isSaveIdChecked}`);
        } else {
            localStorage.removeItem("id");
            localStorage.removeItem("saveId");
        }

        let data = { email, password, loginType: "ACADEMY" };
        await setLoginLoading(true);
        await authRequest.login(
            data,
            (response: any) => {
                if (response.status === -1 || (response.status === 200 && response.data.code === "R999")) {
                    alert(`존재하지 않는 아이디입니다.\n확인 후 다시 시도해 주세요.`);
                    setLoginLoading(false);
                    setLoggedIn(false);
                    setAcademyId("");
                } else if (response.status === -1 || (response.status === 200 && response.data.code === "S500")) {
                    alert(`비밀번호 오류입니다.\n확인 후 다시 시도해 주세요.`);
                    setLoginLoading(false);
                    setLoggedIn(false);
                    setAcademyId("");
                } else {
                    const accessToken = response.data.data.accessToken;
                    const info: infoInterface = jwt_decode(accessToken);
                    localStorage.setItem("accessToken", accessToken);
                    //localStorage.setItem("academyId", `${info?.id}`);
                    localStorage.setItem("info", JSON.stringify(info));
                    callUserInfoAPI();
                    // setLoggedIn(true);
                }
            },
            (error: any) => {
                throw new Error(`axiosLogin(), failed. (error:${error})`);
                /*
                    테스트용
                    const accessToken = response.data.data.accessToken;
                    const info: infoInterface = {
                        exp: 1608620786,
                        expireAt: 1608620786464,
                        iat: 1608534386,
                        id: 61,
                        role: 'ADMIN',
                        sub: 'reverselab@reverselab.co.kr',
                        type: 'Bearer',
                        userType: 'ACADEMY',
                    };
                    localStorage.setItem('accessToken', 'test');
                    localStorage.setItem('academyId', `${info?.id}`);
                    localStorage.setItem('info', JSON.stringify(info));
                    setLoggedIn(true);
                    callUserInfoAPI();
                */
            }
        );
    };

    const callUserInfoAPI = async () => {
        type dataTypes = {
            code: string;
            data: object;
        };
        type resTypes = {
            status: number;
            data: dataTypes;
        };

        await operatorRequest.getUserInfo(
            (response: resTypes) => {
                if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                    console.log("로그인한 사용자의 정보 가져오기 실패");
                } else {
                    const userInfo: dataInterface = response.data.data;
                    localStorage.setItem("userInfo", JSON.stringify(userInfo));
                    localStorage.setItem("academyId", `${userInfo.academy?.id}`);
                    localStorage.setItem("isMaster", Number(userInfo.academy?.id) === -1 ? "1" : "0");

                    window.location.href = "/";
                }
            },
            (error: object) => {
                /*
                    테스트용
                    const userInfo: object = {
                        academy: {
                            id: 61,
                            name: 'TEST',
                        },
                        id: 61,
                        name: '홍길동',
                        email: 'reverselab@reverselab.co.kr',
                        phone: '010-0000-0000',
                        role: 'ADMIN',
                        enrolledAt: '2021-12-31 00:00:00',
                    };
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    window.location.href = '/';
                */
            }
        );
    };

    return (
        <Container>
            <Contents>
                <YellowbusLogo />
                <Form onSubmit={onSubmitLogin}>
                    <LoginForm>
                        <ul>
                            <li>
                                <LoginLabel htmlFor="email">이메일</LoginLabel>
                                <LoginInput
                                    ref={emailInput}
                                    id="email"
                                    type="email"
                                    value={email}
                                    placeholder="이메일을 입력하세요"
                                    autoComplete="user email"
                                    onChange={e => setEmail(e.target.value)}
                                    autoFocus
                                />
                            </li>
                            <li>
                                <LoginLabel htmlFor="password">비밀번호</LoginLabel>
                                <LoginInput
                                    ref={passwordInput}
                                    type="password"
                                    value={password}
                                    placeholder="비밀번호를 입력하세요"
                                    autoComplete="current-password"
                                    onChange={e => setPassword(e.target.value)}
                                    style={{ letterSpacing: 5 }}
                                />
                            </li>
                        </ul>
                        <LoginButton type="submit" disabled={buttonDisabled}>
                            {loginLoading ? "로그인 중 ⋅⋅⋅" : "로그인"}
                        </LoginButton>
                    </LoginForm>
                </Form>
                <LoginFunction>
                    <Checkbox>
                        <CheckboxInput
                            type="checkbox"
                            id="saveId"
                            checked={isSaveIdChecked}
                            onChange={e => {
                                setIsSaveIdChecked(e.target.checked);
                            }}
                        />
                        <CheckboxLabel htmlFor="saveId">이메일 저장</CheckboxLabel>
                    </Checkbox>
                    <FindIdPw
                        onClick={() => {
                            alert("옐로우버스로 문의바랍니다.\ncontact@reverselab.co.kr");
                        }}
                    >
                        이메일/비밀번호 찾기
                    </FindIdPw>
                </LoginFunction>
                <Footer>
                    <Copyright>Copyright © 2020 Reverselab 모든 권리 보유.</Copyright>
                    <Contact>
                        문의: <a href="mailto:contact@reverselab.co.kr">contact@reverselab.co.kr</a>
                    </Contact>
                    <Version>Ver. 1.1.7 (2023-01-02)</Version>
                </Footer>
            </Contents>
        </Container>
    );
};
