import React from "react";
import { OnBoard } from "./OnBoard";
import { OffBoard } from "./OffBoard";
import styled, { css } from "styled-components";
import DepartureStationIcon from "assets/images/icons/departure_station_icon.svg";
import ArrivalStationIcon from "assets/images/icons/arrival_station_icon.svg";
import TerminatedArrivalStationIcon from "assets/images/icons/terminated_arrival_station_icon.svg";
import BusIconGreen from "assets/images/icons/bus_icon_green.svg";
import { media } from "styles/media";

interface StationProps {
    detail: any;
    order: number;
    studentId: number | undefined;
    index: number;
    setCenter?: React.Dispatch<React.SetStateAction<any>>;
}

export const Station: React.FC<StationProps> = ({
    detail,
    order,
    studentId,
    index,
    setCenter,
}) => {
    const stationId = Number(detail.station?.id);
    const detailIndex = index;

    return (
        <Stationli key={detail?.id} order={order}>
            <TimeBox arriveTime={detail?.station?.time}>
                <TimeGroup>
                    <EstimatedArrivalTime>
                        {detail?.station?.time}
                    </EstimatedArrivalTime>
                </TimeGroup>
            </TimeBox>

            <StationBox order={order}>
                <StationName
                    title={"정류장 지도 중앙 위치 >"}
                    onClick={(e) => {
                        e.preventDefault();
                        setCenter({
                            lat: Number(detail?.station?.lat),
                            lng: Number(detail?.station?.lng),
                        });
                    }}
                >
                    {detail.station?.name}
                </StationName>
                <div style={{ minHeight: "40px" }}>
                    <OnBoard
                        stationId={stationId}
                        detail={detail.station.students}
                        studentId={studentId}
                    />
                    <OffBoard
                        stationId={stationId}
                        detail={detail.station.students}
                        studentId={studentId}
                    />
                    <div style={{ width: "60px" }}></div>
                </div>
            </StationBox>
        </Stationli>
    );
};

export const StationName = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin: -6px 0 16px 0;
    line-height: 1.6;
    cursor: pointer;
    :hover {
        font-weight: 800;
    }
`;

interface StationBoxProps {
    position?: number | undefined;
    departureTime?: string | null | undefined;
    order: number;
}

const StationBox = styled.div<StationBoxProps>`
    flex-basis: 80%;
    border-left: 2px solid ${({ theme }) => theme.colors.black};
    border-left: 2px solid ${({ theme }) => theme.colors.black};
    padding: 0 0 35px 30px;
    position: relative;
    ::before {
        ${({ order }) =>
            css`
                content: "${order}";
            `}
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: bold;
        width: 20px;
        height: 20px;
        border-radius: 30px;
        position: absolute;
        left: -11px;
        top: -4px;
        line-height: 1.1;
        border: 2px solid ${({ theme }) => theme.colors.black};
        background-color: ${({ theme }) => theme.colors.yellow};
    }

    @-webkit-keyframes scale {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes scale {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }

    @-webkit-keyframes bounce {
        0% {
            transform: translateY(-20px);
            opacity: 0;
        }
        50% {
            transform: translateY(0px);
            opacity: 1;
        }
        100% {
            transform: translateY(20px);
            opacity: 0;
        }
    }

    @keyframes bounce {
        0% {
            transform: translateY(-20px);
            opacity: 0;
        }
        50% {
            transform: translateY(0px);
            opacity: 1;
        }
        100% {
            transform: translateY(20px);
            opacity: 0;
        }
    }

    ::after {
        content: "";
        position: absolute;
        display: ${({ position }) => (position === 1 ? "block" : "none")};
        width: 30px;
        height: 30px;
        background: url(${BusIconGreen}) center center no-repeat;
        left: -16px;
        z-index: 1;
        top: -8px;
    }
    ${media.desktop} {
        flex-basis: 70%;
    }

    #empty {
        margin-left: 50px;
    }
`;

const Stationli = styled.li<StationLiProps>`
    display: flex;
    :first-child {
        ${StationBox} {
            ::before {
                content: "";
                width: 36px;
                height: 40px;
                border-radius: 0;
                border: none;
                background: url(${DepartureStationIcon}) center center no-repeat;
                background-size: 100%;
                top: -14px;
                left: -19px;
            }
        }
    }
    :last-child {
        ${StationBox} {
            border-left: 2px solid transparent;
            ::before {
                content: "";
                width: 36px;
                height: 40px;
                border-radius: 0;
                border: none;
                ${({ status }) =>
                    status === 2
                        ? css`
                              background: url(${TerminatedArrivalStationIcon})
                                  center center no-repeat;
                          `
                        : css`
                              background: url(${ArrivalStationIcon}) center
                                  center no-repeat;
                          `};
                background-size: 100%;
                top: -14px;
                left: -19px;
            }
            ::after {
                top: -9px;
                margin-top: 0;
            }
        }
    }
`;
interface StationLiProps {
    status?: number | undefined;
    order: number;
}

interface TimeBoxProps {
    arriveTime?: string | null | undefined;
}

const TimeBox = styled.div<TimeBoxProps>`
    flex-basis: 25%;
    text-align: right;
    margin-right: 5px;
    ${({ arriveTime }) =>
        arriveTime !== "" &&
        css`
            ::after {
                content: "";
                position: absolute;
                width: 2px;
                height: 2px;
                background: #dedede;
                top: 0;
                right: 0;
            }
        `}
`;

const TimeGroup = styled.div`
    display: inline;
    width: 66px;
    text-align: left;
    min-height: 115px;
`;

const EstimatedArrivalTime = styled.div`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 30px;
`;
