import React, {ChangeEvent, CSSProperties, ForwardedRef, forwardRef, ReactElement, TextareaHTMLAttributes, useCallback, useImperativeHandle, useState} from "react";
import styled from "styled-components";
import {isNil, orElse} from "../../../components/ValidataionUtil";

type TextInputProps = {
    readOnly: boolean;
    required: boolean;
    default?: string;
    containerStyle?: CSSProperties;
    resetOff?: boolean;
    onChange?(value: string): void;
} & Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange">;
export type TextInputRef = {
    getValue(): string;
    setValue(t: string): void;
    reset(): void;
};

function TextAreaBase(props: TextInputProps, ref: ForwardedRef<TextInputRef>): ReactElement {
    const [value, setValue] = useState<string>(orElse(props.default, ""));
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const onChange = useCallback(
        (e: ChangeEvent<HTMLTextAreaElement>) => {
            const v = e.target.value;
            setValue(v);

            if (!isNil(props.onChange)) {
                props.onChange(v);
            }
        },
        [props, setValue]
    );

    const onFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const onBlur = useCallback(() => {
        setIsFocused(false);
    }, []);

    useImperativeHandle(
        ref,
        () => ({
            getValue(): string {
                return value;
            },
            setValue(t: string): void {
                setValue(t);
            },
            reset(): void {
                setValue("");
            },
        }),
        [value, setValue]
    );
    return (
        <Container style={props.containerStyle}>
            <Input
                {...props}
                // contentEditable={!props.readOnly}
                value={value}
                onChange={onChange}
                resetOff={props.resetOff}
                className={props.className}
                onFocus={onFocus}
                onBlur={onBlur}
                isFocused={isFocused}
            />
        </Container>
    );
}

const TextArea = forwardRef(TextAreaBase);
export default TextArea;

const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

const Input = styled.textarea<{
    value: string;
    resetOff: boolean;
    isFocused: boolean;
}>`
    resize: none;
    font-family: NanumSquareRound;
    min-width: 100%;
    max-width: 100%;
    max-height: 240px;
    min-height: 240px;
    border: 1px solid #e7e7e7;
    padding: 12px;
    border-radius: 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    background: ${(props) => (props.readOnly ? "#f5f5f5" : "#ffffff")};
    cursor: ${(props) => (props.readOnly ? "not-allowed" : "default")};
    color: ${(props) => (props.readOnly ? "#999999" : "#000000")};

    &::placeholder {
        color: #a7a9ac;
        font-size: 12px;
        font-weight: 400;
    }

    :focus-within {
        border: none;
        outline: 1px solid #ffcd00;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    }
`;
