import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BoardTitle, BoardBox, StudentList, Student, NoShow } from "./OnBoardMap";
import _ from "lodash";

interface OffBoardMapProps {
    stationId: number;
    schedules: any;
    studentId: number | undefined;
}

const OffBoardTitle = styled(BoardTitle)`
    span {
        background-color: ${({ theme }) => theme.colors.blue};
    }
`;

export const OffBoardMap: React.FC<OffBoardMapProps> = ({ stationId, schedules, studentId }) => {
    let offBoardCounts = 0;

    if (_.isEmpty(schedules) === false) {
        schedules.map((schedule: any) => {
            if (stationId === Number(schedule.takeOffStation?.id) && schedule.status !== 4) {
                offBoardCounts = offBoardCounts + 1;
            }
            return null;
        });
    }

    return offBoardCounts === 0 ? null : (
        <BoardBox>
            <OffBoardTitle>
                <span>하차</span>
            </OffBoardTitle>
            <StudentList>
                {schedules.map((schedule: any) => {
                    if (stationId === Number(schedule.takeOffStation?.id) && schedule.status !== 4) {
                        return (
                            <Student
                                key={schedule.student?.id}
                                thisStudentId={schedule.student?.id}
                                studentId={studentId}
                            >
                                {schedule.isB2CUser ? (
                                    <span>{schedule.student?.name}(B2C)</span>
                                ) : (
                                    <Link
                                        to={`/students/${schedule.student?.id}`}
                                        title={"학생 페이지로 이동"}
                                        target={"blank"}
                                    >
                                        {schedule.student?.name}
                                    </Link>
                                )}
                                {schedule.boardSwitch === 1 ? " (타요)" : " (안타요)"}
                                {schedule.boardSwitch !== 1 ? (
                                    ""
                                ) : schedule.status === 3 ? (
                                    <NoShow>미승차</NoShow>
                                ) : schedule.status === 4 ? (
                                    " 미탑승예약"
                                ) : (
                                    ` ${schedule.timeOffBoard}`
                                )}
                            </Student>
                        );
                    } else {
                        return null;
                    }
                })}
            </StudentList>
        </BoardBox>
    );
};
