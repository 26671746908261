import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "context/global";
import styled from "styled-components";

import {Container, PageTitle} from "styles/layout";
import {BackButton} from "styles/ui";
import {media} from "styles/media";

import BackIcon from "assets/images/icons/back_icon.svg";
import BoardSearch from "components/board/boardSearch/BoardSearch";
import BoardList from "components/board/boardList/BoardList";
import {Optional} from "type/Common";

type BoardProps = {};

type BoardRef = {};

export function Board(props: BoardProps, ref: BoardRef) {
    const {navToggle, setCurrentLocation} = useContext(GlobalContext);
    const [select, setSelect] = useState<string>("TITLE");
    const [title, setTitle] = useState<Optional<string>>(null);
    const [writer, setWriter] = useState<Optional<string>>(null);
    const [requestCount, setRequestCount] = useState<number>(null);

    useEffect(() => {
        const location = window.location.href.split("/");
        if (location[location.length - 1] === "board") {
            setCurrentLocation("board");
        }
    });

    return (
        <Container navToggle={navToggle} className="Container">
            <PageTitle className="title">
                <BackStepButton img={BackIcon} width={"28px"} height={"28px"} />
                요청 게시판
            </PageTitle>
            <BoardSearch select={select} setSelect={setSelect} setTitle={setTitle} setWriter={setWriter} requestCount={requestCount} setRequestCount={setRequestCount} />
            <BoardList title={title} writer={writer} requestCount={requestCount} setRequestCount={setRequestCount} />
        </Container>
    );
}

type BackStepButtonProps = {
    active?: boolean | undefined;
};

const BackStepButton = styled(BackButton)<BackStepButtonProps>`
    margin-right: 10px;
    display: ${({active}) => (active ? "block" : "none")};
    ${media.tablet} {
        display: none;
    }
`;
