import React, { useContext, Fragment, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { BusServiceContext } from "context/busService";
import { media } from "styles/media";
import {
    useAsyncDebounce,
    Column,
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
} from "react-table";
import { Box, BoxHeader } from "styles/layout";
import SearchIconYellow from "assets/images/icons/search_icon_yellow.svg";
import _ from "lodash";
import BusRequest from "http/bus";
const busRequest = new BusRequest();

interface BusServiceListProps {
    studentId?: number | undefined;
}

const BoxBody = styled.div`
    position: relative;
`;

const GlobalFilterBox = styled.div`
    padding-bottom: 30px;
    box-shadow: 0px 1px 0px 0 rgba(0, 0, 0, 0.1);
    input {
        display: block;
        padding: 12px;
        width: 100%;
        height: 40px;
        font-size: 16px;
        border: 1px solid #e7e7e7;
        border-radius: 5px;
        background: #f6f7f9;
        ::placeholder {
            color: #cecece;
            font-size: 14px;
            letter-spacing: 0;
        }
        :focus,
        :hover {
            outline: none;
            border: 1px solid ${({ theme }) => theme.colors.yellow};
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        }
        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus {
            border: 1px solid ${({ theme }) => theme.colors.yellow};
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        }
        border-radius: 60px;
        padding-left: 50px;
        background: #fff url(${SearchIconYellow}) left 20px center no-repeat;
        background-size: 18px 18px;
    }

    ${media.tablet} {
        position: sticky;
        top: 0;
        background-color: white;
        input {
            width: 100%;
        }
    }
`;

const TableBox = styled.div`
    /* overflow: auto;
    ${media.tablet} {
        overflow: visible;
    } */
    overflow: visible;
    padding: 0 15px;
    width: calc(100% + 30px);
    margin-left: -15px;
    ${media.tablet} {
        max-height: calc(100vh - 330px);
        overflow-x: visible;
        overflow-y: auto;
    }
    table {
        width: 100%;
        border-spacing: 0;
        border-top: 1px solid #e1e1e1;
        ${media.tablet} {
            min-width: auto;
        }
        thead {
            tr {
                color: ${({ theme }) => theme.colors.blue};
                font-weight: 800;
                :nth-child(1) {
                    display: none;
                }
                th {
                    position: sticky;
                    background-color: white;
                    top: 70px;
                    box-shadow: 0px 1px 0px 0 rgba(0, 0, 0, 0.1);
                }
            }
        }
        tr {
            border-bottom: 1px solid #e1e1e1;
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }
        tbody {
            tr {
                transition: 0.3s all ease-out;
                :hover {
                    border-radius: 10px;
                    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                }
            }
        }

        th,
        td {
            text-align: center;
            margin: 0;
            padding: 20px 10px;
            border-bottom: 1px solid #e1e1e1;
            line-height: 1.6;
            vertical-align: middle;
            :last-child {
                border-right: 0;
            }
            ${media.desktop} {
                padding: 20px;
            }
        }
    }
`;

// Define a default UI for filtering
const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}: any) => {
    // const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <Fragment>
            <input
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`검색어 입력 (버스, 배차명)`}
            />
        </Fragment>
    );
};

interface TableProps {
    columns: Array<Column>;
    data: any;
    getRowProps: any;
}

interface BusServiceStatusProps {
    status: number;
}

const Tr = styled.tr<BusServiceStatusProps>`
    ${({ status }) =>
        status > 0
            ? css`
                  cursor: pointer;
              `
            : css`
                  cursor: pointer; //cursor: not-allowed;
              `};
`;

function Table({ columns, data, getRowProps = () => ({}) }: TableProps) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
    }: any = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useFilters,
        useSortBy
    );

    // Render the UI for your table
    return (
        <Fragment>
            <GlobalFilterBox>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            </GlobalFilterBox>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup: any) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                >
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? " ▼"
                                                : " ▲"
                                            : ""}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row: any, i: any) => {
                        prepareRow(row);
                        return (
                            <Tr
                                status={row.original.status}
                                {...row.getRowProps(getRowProps(row))}
                            >
                                {row.cells.map((cell: any) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </tbody>
            </table>
        </Fragment>
    );
}

const BusServiceStatus = styled.div<BusServiceStatusProps>`
    border-radius: 5px;
    font-size: 10px;
    font-weight: 800;
    color: ${({ theme }) => theme.colors.white};
    display: inline-block;
    padding: 6px 8px;
    width: 55px;
    ${({ status }) =>
        status === -1
            ? css`
                  background-color: ${({ theme }: any) => theme.colors.grey};
              `
            : status === 0
            ? css`
                  background-color: ${({ theme }: any) => theme.colors.purple};
              `
            : status === 1
            ? css`
                  background-color: ${({ theme }: any) => theme.colors.green};
              `
            : css`
                  background-color: #e9e9e9;
                  color: #afafaf;
              `};
`;

interface paramInterface {
    date: string;
    studentId?: number;
}
export const BusServiceList: React.FC<BusServiceListProps> = ({
    studentId,
}) => {
    const {
        date,
        setBusServiceId,
        setDispatchCode,
        setCourseCode,
        setStepActive,
        setStatus,
        setSelectedDispatchData,
    } = useContext(BusServiceContext);

    const [data, setData] = useState([]);

    // 60초 마다 운행리스트 갱신
    useEffect(() => {
        const getData = async () => {
            let param: paramInterface = {
                date,
            };
            if (_.isNaN(studentId) === false) {
                param.studentId = studentId;
            }
            busRequest.getBusServiceList(
                param,
                (response: any) => {
                    if (
                        response.status === -1 ||
                        (response.status === 200 && response.data.code !== "OK")
                    ) {
                        alert(
                            `[${response.data.code}] ${response.data.message}`
                        );
                    } else {
                        setData(response.data.data);
                    }
                },
                (error: any) => {
                    setData([]);
                    /*
                        테스트용
                        const tmp: any = [
                        {
                            id: 1,
                            serviceDate: '2021-06-08 23:59:59',
                            dispatch: {
                                name: '배차1',
                                code: 'abcdefg',
                                academyId: 61,
                                courseCode: '123',
                            },
                            bus: {
                                name: '버스라능',
                            },
                            startTime: '2021-06-08 23:59:59',
                            endTime: '2021-06-08 23:59:59',
                            status: 0,
                        },
                        {
                            id: 2,
                            serviceDate: '2021-06-08 23:59:59',
                            dispatch: {
                                name: '배차2',
                                code: 'abcdefg123',
                                academyId: 61,
                                courseCode: '123456',
                            },
                            bus: {
                                name: '버스라능2',
                            },
                            startTime: '2021-06-08 23:59:59',
                            endTime: '2021-06-08 23:59:59',
                            status: 1,
                        },
                        {
                            id: 3,
                            serviceDate: '2021-06-08 23:59:59',
                            dispatch: {
                                name: '배차3',
                                code: 'abcdefg1234',
                                academyId: 61,
                                courseCode: '1234567',
                            },
                            bus: {
                                name: '버스라능3',
                            },
                            startTime: '2021-06-08 23:59:59',
                            endTime: '2021-06-08 23:59:59',
                            status: 2,
                        },
                    ];
                    setData(tmp);
                */
                }
            );
        };
        getData();

        const timer = setInterval(getData, 60000);

        return () => {
            clearInterval(timer);
        };
    }, [studentId, date]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                columns: [
                    {
                        Header: "버스",
                        accessor: "bus.name",
                    },
                    {
                        Header: "배차명",
                        accessor: "dispatch.name",
                    },
                    {
                        Header: "상태",
                        accessor: "status",
                        Cell: (cellInfo: { row: any }) =>
                            cellInfo.row.original.status === -1 ? (
                                <BusServiceStatus status={-1}>
                                    운행전
                                </BusServiceStatus>
                            ) : cellInfo.row.original.status === 0 ? (
                                <BusServiceStatus status={0}>
                                    운행대기
                                </BusServiceStatus>
                            ) : cellInfo.row.original.status === 1 ? (
                                <BusServiceStatus status={1}>
                                    운행중
                                </BusServiceStatus>
                            ) : (
                                <BusServiceStatus status={2}>
                                    운행완료
                                </BusServiceStatus>
                            ),
                    },
                ],
            },
        ],
        []
    );

    //console.log(data);

    return (
        <Fragment>
            <Box>
                <BoxHeader>
                    <h2>운행 목록</h2>
                </BoxHeader>
                <BoxBody>
                    <TableBox>
                        <Table
                            columns={columns}
                            data={data}
                            getRowProps={(row: any) => ({
                                onClick: () => {
                                    setStepActive({
                                        list: false,
                                        detail: true,
                                    });
                                    console.log(row.original);
                                    setBusServiceId(Number(row.original.id));
                                    setDispatchCode(
                                        row.original.dispatch?.code
                                    );
                                    setCourseCode(
                                        row.original.dispatch?.courseCode
                                    );
                                    setStatus(row.original.status);
                                    setSelectedDispatchData(row.original);
                                },
                            })}
                        />
                    </TableBox>
                </BoxBody>
            </Box>
        </Fragment>
    );
};
