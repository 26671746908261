import React from "react";
import { EditOnBoard } from "./EditOnBoard";
import { EditOffBoard } from "./EditOffBoard";
import styled, { css } from "styled-components";
import DepartureStationIcon from "assets/images/icons/departure_station_icon.svg";
import ArrivalStationIcon from "assets/images/icons/arrival_station_icon.svg";
import BusIconGreen from "assets/images/icons/bus_icon_green.svg";
import { media } from "styles/media";
import { Droppable } from "react-beautiful-dnd";

type OverBookingDataProps = {
    year?: string;
    seatOccupancyRates?: any[];
};
interface StationProps {
    detail: any;
    schedules: any;
    order: number;
    studentId: number | undefined;
    index: number;
    isFirst?: any;
    isLast?: boolean;
    seatInfo?: any;
    busType?: string;
    enrolledStudentList?: any;
    overBookingWarning?: boolean;
    edit?: any;
    setEdit?: React.Dispatch<React.SetStateAction<any>>;
    overBookingData?: OverBookingDataProps[];
    setOverBookingWarning?: React.Dispatch<React.SetStateAction<any>>;
    setDuplicateWarning?: React.Dispatch<React.SetStateAction<any>>;
    duplicateData?: string[];
    detailsIds?: number[];
}

export const ModalStation: React.FC<StationProps> = ({
    detail,
    schedules,
    order,
    studentId,
    index,
    isFirst,
    isLast,
    seatInfo,
    busType,
    enrolledStudentList,
    edit,
    setEdit,
    overBookingData,
    duplicateData,
    overBookingWarning,
    setOverBookingWarning,
    setDuplicateWarning,
    detailsIds,
}) => {
    const stationId = detail?.station?.id;
    const detailIndex = index;
    // 노선 마지막 정류장
    const lastStation = schedules[schedules?.length - 1]?.station?.id;
    const firstStation = schedules[0]?.station?.id;

    function emptyStation(stationId: any, schedules: any) {
        if (schedules === undefined) return "";

        const _course = [...schedules];

        let check = _course.some(function (info) {
            if (
                info.pickUpStationId === stationId ||
                info.takeOffStationId === stationId
            ) {
                return true;
            }
            return false;
        });

        if (check) return "";

        return "empty";
    }

    return (
        <Stationli key={detail.id} order={order} className="scroll">
            <TimeBox arriveTime={detail?.station?.time}>
                <TimeGroup>
                    <EstimatedArrivalTime>
                        {detail?.station?.time}
                    </EstimatedArrivalTime>
                </TimeGroup>
            </TimeBox>

            <StationBox
                position={Number(detail.position)}
                departureTime={detail?.station?.time}
                order={order}
            >
                <StationName>
                    <div style={{ marginRight: "8px" }}>
                        {detail.station?.name}
                    </div>
                    {/* {warning && matchingStationId > 0 && (
                        <WarningImg src={WarningIcon} alt="warning" />
                    )} */}
                    {/* <Count
                        warning={warning}
                        matchingStationId={matchingStationId}
                    >
                        {busType === "하원" && isFirst
                            ? detail?.station?.students?.length
                            : busType === "등원" && isLast
                            ? edit?.length + detail?.station?.students?.length
                            : matchingStationId +
                              detail?.station?.students?.length}
                        &nbsp; / {`${seatInfo?.seat?.validCount}`}
                    </Count> */}
                </StationName>

                <Droppable
                    key={"bound-droppable-" + detailIndex}
                    droppableId={stationId + ""}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            id={emptyStation(stationId, schedules)}
                            style={{ minHeight: "40px" }}
                        >
                            <EditOnBoard
                                stationId={stationId}
                                schedules={detail?.station?.students}
                                studentId={studentId}
                                isFirst={isFirst}
                                isLast={isLast}
                                busType={busType}
                                enrolledStudentList={enrolledStudentList}
                                takeOffStationId={lastStation}
                                edit={edit}
                                setEdit={setEdit}
                                overBookingData={overBookingData}
                                overBookingWarning={overBookingWarning}
                                setOverBookingWarning={setOverBookingWarning}
                                setDuplicateWarning={setDuplicateWarning}
                                duplicateData={duplicateData}
                                detailsIds={detailsIds}
                            />
                            <EditOffBoard
                                stationId={stationId}
                                schedules={detail?.station?.students}
                                studentId={studentId}
                                busType={busType}
                                isFirst={isFirst}
                                enrolledStudentList={enrolledStudentList}
                                pickUpStationId={firstStation}
                                edit={edit}
                                setEdit={setEdit}
                                overBookingData={overBookingData}
                                overBookingWarning={overBookingWarning}
                                setOverBookingWarning={setOverBookingWarning}
                                setDuplicateWarning={setDuplicateWarning}
                                duplicateData={duplicateData}
                                detailsIds={detailsIds}
                            />
                            <div style={{ width: "60px" }}>
                                {provided.placeholder}
                            </div>
                        </div>
                    )}
                </Droppable>
            </StationBox>
        </Stationli>
    );
};

export const StationName = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin: -6px 0 16px 0;
    line-height: 1.6;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

interface StationBoxProps {
    position?: number | undefined;
    departureTime?: string | null | undefined;
    status?: number;
    order?: number;
}

const StationBox = styled.div<StationBoxProps>`
    flex-basis: 80%;
    border-left: 2px solid ${({ theme }) => theme.colors.black};
    padding: 0 0 35px 30px;
    position: relative;
    ::before {
        ${({ order }) =>
            css`
                content: "${order}";
            `}
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: bold;
        width: 20px;
        height: 20px;
        border-radius: 30px;
        position: absolute;
        left: -11px;
        top: -4px;
        line-height: 1.1;
        ${({ status }) =>
            status === 2
                ? css`
                      border: 2px solid #dedede;
                      background-color: #dedede;
                  `
                : css`
                      border: 2px solid ${({ theme }) => theme.colors.black};
                      background-color: ${({ theme }) => theme.colors.yellow};
                  `};
    }

    @-webkit-keyframes scale {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes scale {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }

    @-webkit-keyframes bounce {
        0% {
            transform: translateY(-20px);
            opacity: 0;
        }
        50% {
            transform: translateY(0px);
            opacity: 1;
        }
        100% {
            transform: translateY(20px);
            opacity: 0;
        }
    }

    @keyframes bounce {
        0% {
            transform: translateY(-20px);
            opacity: 0;
        }
        50% {
            transform: translateY(0px);
            opacity: 1;
        }
        100% {
            transform: translateY(20px);
            opacity: 0;
        }
    }

    ::after {
        content: "";
        position: absolute;
        display: ${({ position }) => (position === 1 ? "block" : "none")};
        width: 30px;
        height: 30px;
        background: url(${BusIconGreen}) center center no-repeat;
        ${({ status }) =>
            status === 1 &&
            css`
                -webkit-animation: scale 2s infinite ease-in-out;
                -o-animation: scale 2s infinite ease-in-out;
                -ms-animation: scale 2s infinite ease-in-out;
                -moz-animation: scale 2s infinite ease-in-out;
                animation: scale 2s infinite ease-in-out;
            `};
        left: -16px;
        z-index: 1;
        ${({ departureTime, status }) =>
            departureTime !== "" && status === 1
                ? css`
                      top: 50%;
                      margin-top: -10px;
                      -webkit-animation: bounce 3s infinite ease-in-out;
                      -o-animation: bounce 3s infinite ease-in-out;
                      -ms-animation: bounce 3s infinite ease-in-out;
                      -moz-animation: bounce 3s infinite ease-in-out;
                      animation: bounce 3s infinite ease-in-out;
                  `
                : css`
                      top: -8px;
                  `};
    }
    ${media.desktop} {
        flex-basis: 70%;
    }

    /* #empty {
        margin-left: 50px;
    } */
`;

interface StationLiProps {
    status?: number | undefined;
    order: number;
}

const Stationli = styled.li<StationLiProps>`
    display: flex;

    ::-webkit-scrollbar {
        display: none; /* 크롬, 사파리, 오페라, 엣지 */
    }

    :first-child {
        ${StationBox} {
            ::before {
                content: "";
                width: 36px;
                height: 40px;
                border-radius: 0;
                border: none;
                background: url(${DepartureStationIcon}) center center no-repeat;
                background-size: 100%;
                top: -14px;
                left: -19px;
            }
        }
    }

    :last-child {
        ${StationBox} {
            border-left: 2px solid transparent;
            ::before {
                content: "";
                width: 36px;
                height: 40px;
                border-radius: 0;
                border: none;
                background: url(${ArrivalStationIcon}) center center no-repeat;
                background-size: 100%;
                top: -14px;
                left: -19px;
            }
            ::after {
                top: -9px;
                margin-top: 0;
            }
        }
    }
`;

interface TimeBoxProps {
    arriveTime?: string | null | undefined;
}

const TimeBox = styled.div<TimeBoxProps>`
    flex-basis: 25%;
    text-align: right;
    margin-right: 8px;
    max-width: 70px;
    ${({ arriveTime }) =>
        arriveTime !== "" &&
        css`
            ::after {
                content: "";
                position: absolute;
                width: 2px;
                height: 2px;
                background: #dedede;
                top: 0;
                right: 0;
            }
        `}
`;

const TimeGroup = styled.div`
    display: inline;
    width: 66px;
    text-align: left;
    min-height: 115px;
`;

const EstimatedArrivalTime = styled.div`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 30px;
`;
