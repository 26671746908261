import { createContext } from "react";

export interface TicketContextType {
    dispatchCode: string | undefined;
    setDispatchCode: React.Dispatch<React.SetStateAction<string | undefined>>;
    busList: any;
    setBusList: React.Dispatch<React.SetStateAction<any>>;
}

export const TicketContext = createContext<TicketContextType | any>(undefined);
