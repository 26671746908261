import styled, { css } from "styled-components";

const Span = styled.span`
    // default
    font-size: ${(props) => props.fontSize || "14px"};
    font-weight: ${(props) => props.fontWeight || "400"};
    font-weight: ${(props) => props.bold && "700"};
    line-height: ${(props) => props.lineHeight || "20px"};
    text-align: ${(props) => props.textAlign || ""};
    letter-spacing: ${(props) => props.letterSpacing || "-0.5px"};
    text-decoration-line: ${(props) => props.textDecorationLine || ""};

    // small
    ${(props) =>
        props.small &&
        css`
            font-size: 12px;
            line-height: 18px;
        `}

    // large
    ${(props) =>
        props.large &&
        css`
            font-size: 16px;
            line-height: 22px;
        `}

    font-size: ${(props) => props.fs10 && "10px"};
    font-size: ${(props) => props.fs11 && "11px"};
    font-size: ${(props) => props.fs12 && "12px"};
    font-size: ${(props) => props.fs13 && "13px"};
    font-size: ${(props) => props.fs14 && "14px"};
    font-size: ${(props) => props.fs15 && "15px"};
    font-size: ${(props) => props.fs16 && "16px"};
    font-size: ${(props) => props.fs17 && "17px"};
    font-size: ${(props) => props.fs18 && "18px"};
    font-size: ${(props) => props.fs20 && "20px"};

    line-height: ${(props) => props.lh12 && "12px"};
    line-height: ${(props) => props.lh14 && "14px"};
    line-height: ${(props) => props.lh16 && "16px"};
    line-height: ${(props) => props.lh18 && "18px"};
    line-height: ${(props) => props.lh20 && "20px"};
    line-height: ${(props) => props.lh22 && "22px"};
    line-height: ${(props) => props.lh24 && "24px"};
    line-height: ${(props) => props.lh26 && "26px"};
    line-height: ${(props) => props.lh28 && "28px"};

    color: ${(props) => props.color || "#000"};
    color: ${(props) => props.textOrangeColor && "var(--text-orange-color)"};
    color: ${(props) => props.textGrayColor && "var(--text-gray-color)"};
    color: ${(props) =>
        props.textLightGrayColor && "var(--text-light-gray-color)"};
    color: ${(props) =>
        props.textDisabledColor && "var(--text-disabled-color)"};

    display: inline-block;
`;
export default Span;
