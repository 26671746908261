import { useState, useCallback } from "react";

const useInput = (initialValue?: string | undefined) => {
    const [value, setValue] = useState(initialValue);

    const onChange = useCallback(e => setValue(e.currentTarget.value), []);

    return {
        value,
        setValue,
        onChange
    };
};

export default useInput;
