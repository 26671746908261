import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Container, PageTitle, Box, Accordion, AccordionTitle, AccordionTab } from "styles/layout";
import BackIcon from "assets/images/icons/back_icon.svg";
import AccordionUpIcon from "assets/images/icons/accordion_up_icon.svg";
import AccordionDownIcon from "assets/images/icons/accordion_down_icon.svg";
import { GlobalContext } from "context/global";
import { Icon, BackButton } from "styles/ui";
import { useHistory } from "react-router-dom";
import { StudentInfo } from "./StudentInfo";
import { StudentMemo } from "./StudentMemo";
import { media } from "styles/media";
import { StudentSchedule } from "./StudentSchedule";
import MemberRequest from "http/member";

const memberRequest = new MemberRequest();

interface StudentDetailProps {}
interface parentInterface {
    name?: string;
    phone?: string;
}

// interface zonInterface {
//     id?: number;
//     name?: string;
// }
interface stationInterface {
    id?: number;
    name?: string;
    // zone?: zonInterface;
}

interface dataInterface {
    id?: number;
    name?: string;
    nickname?: string;
    ticketNumber?: string;
    phone?: string;
    kakaoId?: string;
    enrollDate?: string;
    contact?: string;
    parent?: parentInterface;
    station?: stationInterface;
}

interface paramInterface {
    id?: string;
}

export const StudentDetail: React.FC<StudentDetailProps> = () => {
    const { navToggle, setCurrentLocation } = useContext(GlobalContext);
    const history = useHistory();
    const [accordion, setAccordion] = useState({
        profile: false,
        busService: true
    });

    const { id } = useParams<paramInterface>();
    const [data, setData] = useState<dataInterface>({});

    // 학생 리스트 가져오기
    useEffect(() => {
        const getData = async () => {
            await memberRequest.getStudentById(
                id,
                (response: any) => {
                    if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                        if (response.data.code === "R3001") {
                            history.push("/students");
                        }
                    } else {
                        // document.title = `학생 관리`;
                        setCurrentLocation("students");
                        setData(response.data.data);
                    }
                },
                (error: any) => {
                    setData({});
                }
            );
        };

        getData();
    }, [setCurrentLocation, id]);

    return (
        <Container navToggle={navToggle}>
            <Contents>
                <PageTitle>
                    <BackButton
                        img={BackIcon}
                        width={"28px"}
                        height={"28px"}
                        onClick={e => {
                            if (history.action === "POP") {
                                history.push("/students");
                            } else {
                                history.goBack();
                            }
                        }}
                    />
                    <span>학생 정보 관리</span>
                </PageTitle>
                <Box style={{ padding: "25px" }}>
                    <Accordion toggle={accordion.profile}>
                        <AccordionTab
                            onClick={e => {
                                e.preventDefault();
                                accordion.profile === true
                                    ? setAccordion({
                                          ...accordion,
                                          profile: false
                                      })
                                    : setAccordion({
                                          ...accordion,
                                          profile: true
                                      });
                            }}
                        >
                            <AccordionTitle>
                                <span>{data?.name}</span> 프로필
                            </AccordionTitle>
                            <Icon
                                img={accordion.profile === true ? AccordionUpIcon : AccordionDownIcon}
                                width={"24px"}
                                height={"24px"}
                            />
                        </AccordionTab>
                        <ProfileContentGroup>
                            <ProfileContent>
                                <StudentInfo data={data} />
                            </ProfileContent>
                            <ProfileContent>
                                <StudentMemo studentId={Number(id)} />
                            </ProfileContent>
                        </ProfileContentGroup>
                    </Accordion>
                </Box>

                <StudendtScheduleBox>
                    {data?.id && <StudentSchedule studentId={data?.id} studentName={data.name} />}
                </StudendtScheduleBox>
            </Contents>
        </Container>
    );
};

const Contents = styled.div``;

const ProfileContent = styled.div`
    padding: 30px 0;
    border-top: 1px solid #e1e1e1;
    :last-child {
        padding-bottom: 0;
    }
    ${media.desktop} {
        border-top: 0;
        flex-basis: 60%;
        padding: 10px 40px 0 0;
        :last-child {
            border-left: 1px solid #e1e1e1;
            padding: 10px 0 0 40px;
            flex-basis: 40%;
        }
    }
`;

const ProfileContentGroup = styled.div`
    ${media.desktop} {
        display: flex;
    }
`;

const StudendtScheduleBox = styled(Box)`
    margin-top: 20px;
`;
