/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { Fragment, useState, useContext, useRef, useEffect } from "react";
import { BoxBody } from "components/dashboard/BusServiceStatus";
import { Label, Input, Button, ButtonGrey, Comment } from "styles/ui";
import { BoxFooter } from "styles/layout";
import { InfoList, Info } from "./UserAccount";
import { ProfileContext } from "context/profile";
import { useFormatPhoneNumber } from "hooks/useFormatPhoneNumber";
import { getUserInfo, setUserInfo } from "accessToken";
import OperatorRequest from "http/operator";
const operatorRequest = new OperatorRequest();

interface UpdateUserInfoProps {
    email: string;
    name: string;
    phone: string;
}

export const UpdateUserInfo: React.FC<UpdateUserInfoProps> = ({ email, name, phone }) => {
    const { setUpdateUserInfo } = useContext(ProfileContext);

    const [info, setInfo] = useState({
        newName: name,
        newPhone: phone
    });

    useEffect(() => {
        setInfo({ newName: name, newPhone: phone });
    }, [name, email, phone]);

    const [isValid, setIsValid] = useState({
        newName: true,
        newPhone: true
    });

    const [comment, setComment] = useState({
        newName: "",
        newPhone: ""
    });

    const [loading, setLoading] = useState<boolean>(false);

    const newNameInput = useRef<HTMLInputElement>(null);
    const newPhoneInput = useRef<HTMLInputElement>(null);
    let formedPhone: any = useFormatPhoneNumber({ phone: info.newPhone });

    const onSubmitUpdate = async (e: React.FormEvent) => {
        e.preventDefault();

        if (info.newName === "") {
            setComment({
                newName: "을 입력해주세요.",
                newPhone: ""
            });
            setIsValid({
                newName: false,
                newPhone: true
            });
            newNameInput.current?.focus();
            return;
        } else if (info.newPhone === "") {
            setComment({
                newName: "",
                newPhone: "를 입력해주세요."
            });
            setIsValid({
                newName: true,
                newPhone: false
            });
            newPhoneInput.current?.focus();
            return;
        }

        let resultphone = "";
        if (formedPhone === info.newPhone) {
            resultphone = info.newPhone;
        } else {
            resultphone = formedPhone;
        }

        const param = {
            email,
            name: info.newName,
            phone: resultphone
        };
        await setLoading(true);
        await operatorRequest.updateUserInfo(
            param,
            (response: any) => {
                if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                    alert(`[${response.data.code}] ${response.data.message}`);
                    setLoading(false);
                } else {
                    alert("운영자 정보를 변경하였습니다.");
                    setUpdateUserInfo(false);
                    setLoading(false);

                    // 변경된 이름 & 전화번호로 덮어씌우기
                    const tmp = getUserInfo();
                    if (tmp) {
                        const newUserInfo = {
                            ...tmp,
                            name: info.newName,
                            phone: info.newPhone
                        };
                        localStorage.setItem("userInfo", JSON.stringify(newUserInfo));
                        setUserInfo(newUserInfo);
                        window.location.reload();
                    }
                }
            },
            (error: any) => {}
        );
    };

    return (
        <Fragment>
            <form onSubmit={onSubmitUpdate}>
                <BoxBody>
                    <InfoList>
                        <Info>
                            <Label htmlFor="newName">
                                이름<Comment>{comment.newName}</Comment>
                            </Label>
                            <Input
                                ref={newNameInput}
                                id="newName"
                                type="text"
                                value={info.newName}
                                autoComplete="user name"
                                onChange={e =>
                                    setInfo({
                                        ...info,
                                        newName: e.target.value
                                    })
                                }
                                isValid={isValid.newName}
                            />
                        </Info>
                        <Info>
                            <Label htmlFor="newPhone">
                                전화번호<Comment>{comment.newPhone}</Comment>
                            </Label>
                            <Input
                                ref={newPhoneInput}
                                id="newPhone"
                                type="text"
                                value={formedPhone}
                                autoComplete="current-password"
                                onChange={e =>
                                    setInfo({
                                        ...info,
                                        newPhone: e.target.value
                                    })
                                }
                                isValid={isValid.newPhone}
                                maxLength={13}
                            />
                        </Info>
                    </InfoList>
                </BoxBody>
                <BoxFooter>
                    <Button type="submit">{loading ? "저장중⋅⋅⋅" : "저장"}</Button>
                    <ButtonGrey
                        onClick={async e => {
                            e.preventDefault();
                            setUpdateUserInfo(false);
                        }}
                    >
                        취소
                    </ButtonGrey>
                </BoxFooter>
            </form>
        </Fragment>
    );
};
