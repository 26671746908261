import React, { useContext, useEffect, Fragment, useState } from "react";
import styled from "styled-components";
import { AuthContext } from "context/auth";
import { Box, BoxHeader } from "styles/layout";
import SearchIconYellow from "assets/images/icons/search_icon_yellow.svg";
import { useTable, Column, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import { useHistory } from "react-router-dom";
import { media } from "styles/media";
import MemberRequest from "http/member";
const memberRequest = new MemberRequest();

interface StudentListProps {}

const BoxBody = styled.div`
    position: relative;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
`;

const GlobalFilterBox = styled.div`
    position: absolute;
    top: -54px;
    right: -10px;
    z-index: 1;
    max-width: calc(100% - 87px);
    input {
        display: block;
        padding: 12px;
        width: 100%;
        height: 40px;
        font-size: 16px;
        border: 1px solid #e7e7e7;
        border-radius: 5px;
        background: #f6f7f9;
        ::placeholder {
            color: #cecece;
            font-size: 14px;
            letter-spacing: 0;
        }
        :focus,
        :hover {
            outline: none;
            border: 1px solid ${({ theme }) => theme.colors.yellow};
        }
        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus {
            border: 1px solid ${({ theme }) => theme.colors.yellow};
            transition: background-color 5000s ease-in-out 0s;
        }
        border-radius: 60px;
        padding-left: 50px;
        background: #fff url(${SearchIconYellow}) left 20px center no-repeat;
        background-size: 18px 18px;
    }
    ${media.tablet} {
        position: absolute;
        top: -54px;
        left: 102px;
        input {
            width: 350px;
        }
    }
`;

const TableBox = styled.div`
    overflow: visible;
    padding: 0 15px;
    width: calc(100% + 30px);
    margin-left: -15px;
    overflow: auto;
    max-height: calc(100vh - 231px);
    ${media.tablet} {
        max-height: calc(100vh - 330px);
        overflow-x: visible;
        overflow-y: auto;
    }
    ${media.desktop} {
        max-height: calc(100vh - 280px);
    }
    table {
        width: 100%;
        border-spacing: 0;
        min-width: 550px;
        ${media.tablet} {
            min-width: auto;
        }
        thead {
            tr {
                color: ${({ theme }) => theme.colors.blue};
                font-weight: 800;
                :nth-child(1) {
                    display: none;
                }

                th {
                    position: sticky;
                    background-color: white;
                    top: 0px;
                    box-shadow: 0px 1px 0px 0 rgba(0, 0, 0, 0.1);
                }
            }
        }
        tr {
            border-bottom: 1px solid #e1e1e1;
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }
        tbody {
            tr {
                :hover {
                    border-radius: 10px;
                    /* font-weight: bold; */
                    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                }
            }
        }

        th,
        td {
            text-align: center;
            margin: 0;
            padding: 20px 10px;
            border-bottom: 1px solid #e1e1e1;
            :last-child {
                border-right: 0;
            }
            ${media.desktop} {
                padding: 20px;
            }
        }
    }
`;

// Define a default UI for filtering
const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: any) => {
    // const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <Fragment>
            <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`학생, 보호자 이름 또는 전화번호 입력`}
            />
        </Fragment>
    );
};

interface TableProps {
    columns: Array<Column>;
    data: any;
    getRowProps: any;
}

function Table({ columns, data, getRowProps = () => ({}) }: TableProps) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter
    }: any = useTable(
        {
            columns,
            data
        },
        useGlobalFilter,
        useSortBy
    );

    // Render the UI for your table
    return (
        <Fragment>
            <GlobalFilterBox>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            </GlobalFilterBox>

            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup: any) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    <span>{column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}</span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row: any, i: any) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps(getRowProps(row))}>
                                {row.cells.map((cell: any) => {
                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Fragment>
    );
}

interface parentInterface {
    code?: string;
    name?: string;
    phone?: string;
}
interface stationInterface {
    name?: string;
}
interface dataInterface {
    id?: number;
    name?: string;
    phone?: string;
    nickname?: string;
    parent?: parentInterface;
    enrolledAt?: string;
    station?: stationInterface;
}

export const StudentList: React.FC<StudentListProps> = () => {
    const history = useHistory();
    const { academyId } = useContext(AuthContext);
    const [data, setData] = useState<dataInterface[]>([]);

    // 학생 리스트 가져오기
    useEffect(() => {
        const getData = async () => {
            await memberRequest.getStudentsAll(
                (response: any) => {
                    console.log(response);
                    if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                        alert(`[${response.data.code}] ${response.data.message}`);
                    } else {
                        setData(response.data.data);
                    }
                },
                (error: any) => {
                    setData([]);
                    /*
                        테스트용
                        const tmp: dataInterface[] = [
                            {
                                id: 1091,
                                name: '한글이름1',
                                phone: '',
                                parent: {
                                    code: '5829596d8e8f4467ba58d0f2f6ddfc60',
                                    name: 'XXX',
                                    phone: '010-XXXX-XXXX',
                                },
                                enrolledAt: '2021-03-23 17:14',
                                station: {
                                    name: '',
                                },
                            },
                        ];
                        setData(tmp);
                    */
                }
            );
        };

        getData();
    }, [academyId]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                columns: [
                    {
                        Header: "학생 이름",
                        accessor: "name"
                    },
                    {
                        Header: "학생 전화번호",
                        accessor: "phone"
                    },
                    {
                        Header: "학년 정보",
                        accessor: "nickname"
                    },
                    {
                        Header: "보호자 이름",
                        accessor: "parent.name"
                    },
                    {
                        Header: "보호자 전화번호",
                        accessor: "parent.phone"
                    },
                    {
                        Header: "정류장",
                        accessor: "station.name"
                    },
                    {
                        Header: "등록일",
                        accessor: "enrolledAt"
                    }
                ]
            }
        ],
        []
    );

    return (
        <Fragment>
            <Box>
                <BoxHeader>
                    <h2>학생리스트</h2>
                </BoxHeader>
                <BoxBody>
                    <TableBox>
                        <Table
                            columns={columns}
                            data={data || []}
                            getRowProps={(row: any) => ({
                                onClick: () => {
                                    history.push(`/students/${row.original.id}`);
                                },
                                style: {
                                    cursor: "pointer"
                                }
                            })}
                        />
                    </TableBox>
                </BoxBody>
            </Box>
        </Fragment>
    );
};
