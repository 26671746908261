import {GlobalContext} from "context/global";
import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {useHistory, useLocation} from "react-router-dom";
import {Container, PageTitle} from "styles/layout";
import {BackButton} from "styles/ui";
import {media} from "styles/media";
import BoardDetailContents from "./BoardDetailContents";
import {getRequest} from "service/board/Board";
import {Optional} from "type/Common";
import {CBoardDetail} from "model/board";
import {isNil} from "components/ValidataionUtil";
import BoardDetailReplies from "./BoardDetailReplies";

// image
import BackIcon from "assets/images/icons/back_icon.svg";
import {flexColumn, flexRow} from "styles/CommonStyle";

type BoardDetailProps = {};

function BoardDetail(props: BoardDetailProps) {
    const {navToggle} = useContext(GlobalContext);
    const academyID = localStorage.getItem("academyId");
    const history = useHistory();
    const location = useLocation();

    const state = location.state as {boardID?: number};
    const requestId = state.boardID;

    const [boardDetailState, setBoardDetailState] = useState<Optional<CBoardDetail>>(null);

    const getBoard = useCallback(() => {
        if (!requestId) {
            return;
        }
        getRequest(Number(requestId))
            .then((res) => {
                setBoardDetailState(res?.boardRequest);
            })
            .catch((error) => {
                throw new Error(`getBoardDetail() failed. error : ${error}`);
            });
    }, [requestId]);

    useEffect(() => {
        if (!requestId) {
            return;
        }
        getBoard();
    }, [academyID, requestId, getBoard]);

    return (
        <>
            <Container navToggle={navToggle} className="Container">
                <PageTitle className="title">
                    <BackStepButton img={BackIcon} width={"28px"} height={"28px"} onClick={() => history.push(`/board`)} />
                    요청 상세 보기
                </PageTitle>
                {!isNil(boardDetailState) && (
                    <ContentsWrapper>
                        <ContentsContainer>
                            <BoardDetailContents boardContent={boardDetailState} />
                        </ContentsContainer>
                        <CommentContainer>
                            <BoardDetailReplies requestId={requestId} />
                        </CommentContainer>
                    </ContentsWrapper>
                )}
            </Container>
            <TextAreaWrapper>
                <WebTextArea
                    // inputHeight={inputHeight}
                    placeholder="댓글을 작성하고 싶다면 선택 후 댓글을 작성해 주세요."
                />
                <InputButton>등록</InputButton>
            </TextAreaWrapper>
        </>
    );
}

export default BoardDetail;

type BackStepButtonProps = {
    active?: boolean | undefined;
};

const BackStepButton = styled(BackButton)<BackStepButtonProps>`
    margin-right: 10px;
    display: block;
`;

const ContentsWrapper = styled.div`
    ${flexColumn};

    ${media.tablet} {
        display: block;
        ${flexRow};
        width: 100%;
        height: calc(100vh - 150px);
        max-height: calc(100vh - 150px);
        display: grid;
        column-gap: 26px;
        grid-template-columns: 1fr 1fr;
        position: relative;
        padding-bottom: 0;
    }
`;

const ContentsContainer = styled.div`
    background: #fff;
    padding: 16px 20px;
    border-radius: 6px;
    margin-bottom: 16px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);

    ${media.tablet} {
        border-radius: 30px;
        padding: 40px;
        width: calc(50% - 13px);
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const CommentContainer = styled.div`
    background: #fff;
    padding: 16px 0;
    border-radius: 6px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);

    ${media.tablet} {
        padding: 40px 0 0 0;
        border-radius: 30px;
        width: calc(50% - 13px);
        position: absolute;
        top: 0;
        right: 0;
    }
`;

const TextAreaWrapper = styled.div`
    ${flexRow};
    position: fixed;
    background: #fff;
    align-items: center;
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 48px;
    min-width: 100%;
    max-width: 100%;
    border: none;
    padding: 12px;
    border-top: 1px solid #e7e7e7;
    display: none;

    ${media.tablet} {
        display: none;
    }
`;

const WebTextArea = styled.textarea`
    width: calc(100% - 47px);
    min-width: calc(100% - 47px);
    max-width: calc(100% - 47px);
    min-height: 20px;
    height: 20px;
    line-height: 14px; /* 19.6px */
    font-size: 12px;
    border: none;
    resize: none;

    :focus {
        outline: none;
    }

    &::placeholder {
        color: #cecece;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
    }

    ${media.tablet} {
        display: none;
    }
`;

const InputButton = styled.div`
    width: 47px;
    display: flex;
    justify-content: center;
    color: #174490;
    border-left: 1px solid #e7e7e7;
    padding: 0 8px 0 16px;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;

    ${media.tablet} {
        display: none;
    }
`;
