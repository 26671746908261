import React from "react";
import styled from "styled-components";
import {flexColumn, flexRow} from "styles/CommonStyle";

type RegisterPopUpProps = {
    onClose: () => void;
    createRequest: () => void;
};

function RegisterPopUp(props: RegisterPopUpProps) {
    return (
        <Container>
            <Title>등록 확인</Title>
            <Content>작성한 요청 게시글을 등록 하시겠습니까?</Content>
            <ButtonWrapper>
                <CancelButton onClick={props.onClose}>아니요</CancelButton>
                <RegisterButton onClick={props.createRequest}>예</RegisterButton>
            </ButtonWrapper>
        </Container>
    );
}

export default RegisterPopUp;

const Container = styled.div`
    width: 100%;
    height: 100%;
    ${flexColumn};
    justify-content: center;
`;

const Title = styled.div`
    color: #1e2939;
    text-align: center;
    font-family: NanumSquareRound;
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 16px;
    line-height: 18px;
`;

const Content = styled.div`
    color: #1e2939;
    text-align: center;
    font-family: NanumSquareRound;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.42px;
    line-height: 40px;
`;

const ButtonWrapper = styled.div`
    margin-top: 30px;
    ${flexRow};
    justify-content: center;
    column-gap: 4px;
    align-items: center;
`;

const CancelButton = styled.div`
    border-radius: 30px;
    background: #efefef;
    width: 98px;
    height: 36px;
    color: #000;
    line-height: 16px;
    font-family: NanumSquareRound;
    font-size: 14px;
    font-weight: 800;
    padding: 10px 22px;
    cursor: pointer;
    text-align: center;
`;

const RegisterButton = styled(CancelButton)`
    background: #ffcd00;
    color: #000;
`;
