import React, { useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { ReactComponent as YellowBusLogoImg } from "assets/images/common/yellowbus_logo.svg";
import MobileMenu from "assets/images/icons/mobile_menu.svg";
import PinNavMenu from "assets/images/icons/pin_on_icon.svg";
import UnPinNavMenu from "assets/images/icons/pin_off_icon.svg";
import YellowbusLogoIcon from "assets/images/icons/yellowbus_logo_icon.svg";
import DashboadIconBlack from "assets/images/icons/dashboard_icon_black.svg";
import DashboadIconYellow from "assets/images/icons/dashboard_icon_yellow.svg";
import AcademyIconBlack from "assets/images/icons/academy_icon_black.svg";
import AcademyIconYellow from "assets/images/icons/academy_icon_yellow.svg";
import StudentIconBlack from "assets/images/icons/student_icon_black.svg";
import StudentIconYellow from "assets/images/icons/student_icon_yellow.svg";
import BusServiceIconBlack from "assets/images/icons/bus_service_icon_black.svg";
import BusServiceIconYellow from "assets/images/icons/bus_service_icon_yellow.svg";
import BusTicketIconBlack from "assets/images/icons/bus_ticket_icon_black.svg";
import BusTicketIconYellow from "assets/images/icons/bus_ticket_icon_yellow.svg";
import BoardIconBlack from "assets/images/icons/board_icon_black.svg";
import BoardIconYellow from "assets/images/icons/board_icon_yellow.svg";
import { media } from "styles/media";
import { GlobalContext } from "context/global";
import { Icon } from "styles/ui";
import PlusIcon from "assets/images/icons/plus_icon.svg";

interface SideNavProps {}

export const SideNav: React.FC<SideNavProps> = () => {
  const { navToggle, setNavToggle, currentLocation, setRegisterStudentPopUp } =
    useContext(GlobalContext);

  const toggleNav = () => {
    setNavToggle(navToggle === true ? false : true);
  };

  const closeNav = () => {
    const jsMediaQuery780 = (w780: any) => {
      if (w780.matches) {
      } else {
        setNavToggle(false);
      }
    };
    const w780 = window.matchMedia("(min-width: 780px)");
    jsMediaQuery780(w780);
    w780.addListener(jsMediaQuery780);
  };

  return (
    <Fragment>
      <BackgroundLayer navToggle={navToggle} onClick={toggleNav} />
      <Nav navToggle={navToggle}>
        <NavButton onClick={toggleNav}>
          <SideNavOpenIcon navToggle={navToggle}></SideNavOpenIcon>
        </NavButton>
        <ul>
          <li>
            <SideNavIcon img={YellowbusLogoIcon} />
            <YellowBusLogo />
          </li>
          <li>
            <StyledLink
              to="/dashboard"
              icon={DashboadIconBlack}
              hovericon={DashboadIconYellow}
              page={"dashboard"}
              currentlocation={currentLocation}
              onClick={closeNav}
            >
              <span>대시보드</span>
            </StyledLink>
          </li>
          <li>
            <StyledLink
              to="/profile"
              icon={AcademyIconBlack}
              hovericon={AcademyIconYellow}
              page={"academy"}
              currentlocation={currentLocation}
              onClick={closeNav}
            >
              <span>학원 프로필</span>
            </StyledLink>
          </li>
          <li>
            <StyledLink
              to="/students"
              icon={StudentIconBlack}
              hovericon={StudentIconYellow}
              page={"students"}
              currentlocation={currentLocation}
              onClick={closeNav}
            >
              <span>학생 관리</span>
            </StyledLink>
          </li>
          <li>
            <StyledLink
              to="/busService"
              icon={BusServiceIconBlack}
              hovericon={BusServiceIconYellow}
              page={"busService"}
              currentlocation={currentLocation}
              onClick={closeNav}
            >
              <span>버스 운행 관제</span>
            </StyledLink>
          </li>
          <li>
            <StyledLink
              to="/ticketservice"
              icon={BusTicketIconBlack}
              hovericon={BusTicketIconYellow}
              page={"ticketservice"}
              currentlocation={currentLocation}
              onClick={closeNav}
            >
              <span>승차권 관리</span>
            </StyledLink>
          </li>
          <li>
            <StyledLink
              to="/board"
              icon={BoardIconBlack}
              hovericon={BoardIconYellow}
              page={"board"}
              currentlocation={currentLocation}
              onClick={closeNav}
            >
              <span>요청 게시판</span>
            </StyledLink>
          </li>
        </ul>
        <RegisterStudentButton
          onClick={() => {
            setRegisterStudentPopUp(true);
            closeNav();
          }}
        >
          <span>학생등록</span>
          <Icon img={PlusIcon} width={"18px"} height={"18px"}></Icon>{" "}
        </RegisterStudentButton>
      </Nav>
    </Fragment>
  );
};

interface StyledLinkProps {
  icon: string;
  hovericon?: string | undefined;
  page: string;
  currentlocation?: string | undefined;
}

const SideNavIcon = styled(Icon)`
  width: 28px;
  height: 28px;
`;

const StyledLink = styled(Link)<StyledLinkProps>`
  display: flex;
  width: 48px;
  height: 48px;
  padding-left: 48px;
  background: url(${(props) => props.icon}) left 12px center no-repeat;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  transition: 0.3s ease;
  overflow: hidden;
  span {
    transition: 0.3s ease;
    color: ${(props) => props.theme.colors.black};
    line-height: 1;
    position: absolute;
    width: 162px;
    ${media.tablet_} {
      opacity: 0;
      margin-left: 40px;
    }
  }
  &:hover {
    width: 190px;
    background-image: transparent url(${(props) => props.hovericon});
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
  }
  ${({ page, currentlocation }) =>
    page === currentlocation &&
    css<StyledLinkProps>`
      background-image: url(${(props) => props.hovericon});
      background-color: ${(props) => props.theme.colors.black};
      span {
        font-weight: 800;
        color: ${(props) => props.theme.colors.yellow};
      }
    `}
`;
const YellowBusLogo = styled(YellowBusLogoImg)`
  position: absolute;
  top: 0;
  left: 13px;
  transition-delay: 0.3s;
  transition: 0.3s ease-in-out;
  ${media.tablet_} {
    opacity: 0;
  }
`;

const navFull = css`
  width: 210px;
  margin-left: 0;
  ${YellowBusLogo} {
    opacity: 1;
  }
  ${StyledLink} {
    span {
      margin-left: 0;
      opacity: 1;
    }
  }
`;

const navMobile = css`
  margin-left: -210px;
`;

const navMini = css`
  margin-left: 0;
  width: 68px;
  z-index: 10001;
  :hover {
    ${navFull}
  }
`;

interface NavProps {
  navToggle?: boolean | undefined;
}

const Nav = styled.nav<NavProps>`
  position: fixed;
  display: flex;
  width: 210px;
  height: 100vh;
  border-radius: 0 34px 0 0;
  background-color: ${(props) => props.theme.colors.yellow};
  padding: 60px 10px 0;
  transition: 0.3s ease-out;
  z-index: 10001;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  ${navMobile}
  li:first-child {
    position: relative;
    padding-left: 10px;
    margin-bottom: 17px;
  }

  ${({ navToggle }) =>
    navToggle &&
    css`
      ${navFull}
      ${StyledLink} {
        width: 190px;
      }
    `}

  ${media.tablet_} {
    overflow: hidden;
    ${navMini}
    ${({ navToggle }) =>
      navToggle &&
      css`
        ${navFull}
        ${StyledLink} {
          width: 190px;
        }
      `}
  }

  &:hover {
    ${StyledLink} {
      width: 190px;
    }
  }
`;

const NavButton = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  padding: 15px 20px;
  cursor: pointer;
  ${media.tablet_} {
    padding: 14px;
    position: absolute;
    left: 160px;
  }
`;

interface SidaNaveOpenIconProps {
  navToggle: boolean;
}
const SideNavOpenIcon = styled.div<SidaNaveOpenIconProps>`
  background: transparent url(${MobileMenu}) center center no-repeat;
  display: block;
  background-size: cover;
  width: 22px;
  height: 22px;
  ${media.tablet_} {
    transition: opacity 0.3s ease;
    opacity: 0.6;
    /* background-image: url(${({ navToggle }) =>
      navToggle ? PinNavMenu : UnPinNavMenu}); */
    :hover {
      opacity: 1;
    }
  }
`;

const BackgroundLayer = styled.div<NavProps>`
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10001;
  ${media.tablet_} {
    display: none;
  }
  ${({ navToggle }) =>
    navToggle &&
    css`
      display: block;
    `}
`;

const RegisterStudentButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 50px;
  span {
    margin-right: 20px;
  }
  ${media.tablet_} {
    display: none;
  }
`;
