import Request from "./request";

class ScheduleRequest extends Request {
    updateScheduleList = (data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback
        };
        this.put(`/schedules/stations`, request);
    }

}

export default ScheduleRequest;
