import React, { useContext } from "react";
import styled from "styled-components";
import { media } from "styles/media";
import { RegisterStudentStep1 } from "./RegisterStudentStep1";
import { RegisterStudentStep2 } from "./RegisterStudentStep2";
import { RegisterStudentContext } from "context/registerStudent";

interface RegisterStudentProps {}

const RegisterStudentSteps = styled.div`
    width: 100%;
    ${media.tablet} {
        width: 360px;
    }
`;

interface StepBoxProps {
    active: boolean;
}
const StepBox = styled.div<StepBoxProps>`
    display: ${({ active }) => (active ? "block" : "none")};
`;

export const RegisterStudent: React.FC<RegisterStudentProps> = () => {
    const { registerStudentState } = useContext(RegisterStudentContext);

    return (
        <RegisterStudentSteps>
            <StepBox active={registerStudentState.step1}>
                <RegisterStudentStep1 />
            </StepBox>
            <StepBox active={registerStudentState.step2}>
                <RegisterStudentStep2 />
            </StepBox>
        </RegisterStudentSteps>
    );
};
