import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Container, PageTitle } from "styles/layout";
import { media } from "styles/media";
import BackIcon from "assets/images/icons/back_icon.svg";
import ko from "date-fns/locale/ko";
import { registerLocale } from "react-datepicker";
import { TicketContext } from "context/ticket";
import { AuthContext } from "context/auth";
import { GlobalContext } from "context/global";
import { AcademyBusList } from "../components/ticketService/AcademyBusList";
import { BusServiceDetail } from "components/ticketService/BusServiceDetail";
import { BusServiceMap } from "components/ticketService/BusServiceMap";
import { BackButton, Checkbox, CheckboxLabel, RadioButton } from "styles/ui";
import TicketRequest from "http/ticket";
const ticketRequest = new TicketRequest();
registerLocale("ko", ko);

interface TicketServiceProps {
  studentId?: number | any;
  show: boolean;
}

const week = [
  { key: "1", value: "월" },
  { key: "2", value: "화" },
  { key: "3", value: "수" },
  { key: "4", value: "목" },
  { key: "5", value: "금" },
  { key: "6", value: "토" },
  { key: "0", value: "일" },
];

const waysArr = [
  {
    key: 1,
    value: "전체",
    type: "",
  },
  {
    key: 2,
    value: "등원",
    type: "INBOUND",
  },
  {
    key: 3,
    value: "하원",
    type: "OUTBOUND",
  },
];

export const TicketService: React.FC<TicketServiceProps> = ({ studentId }) => {
  const { navToggle, setCurrentLocation } = useContext(GlobalContext);
  const { loggedIn } = useContext(AuthContext);
  const history = useHistory();
  const today = new Date();
  const [day, setDay] = useState<any>(
    today.getDay() === 0 ? week[6].key : week[today.getDay() - 1].key
  );
  const [dispatchType, setDispatchType] = useState("");
  const [busList, setBusList] = useState<string[]>([]);
  const [courses, setCourses] = useState<string[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [detailActive, setDetailActive] = useState<boolean>(false);
  const academyID = localStorage.getItem("academyId");
  const [coursesDetail, setCoursesDetail] = useState<string[]>();
  const [dispatchCode, setDispatchCode] = useState("");
  const [dispatchName, setDispatchName] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [busEndDate, setBusEndDate] = useState<string>(""); // 버스 승차기간 마지막 날짜
  const [center, setCenter] = useState({
    lat: 37.42002671,
    lng: 127.13061966,
  });

  if (!loggedIn) {
    history.push("/login");
  }

  // 요일 선택
  const changeSelectedWeekDay = (e: any) => {
    setDay(e.target.id);
  };

  // 운행 구분 선택
  const changeSelectedValue = (e: any) => {
    setDispatchType(e.target.id);
  };

  useEffect(() => {
    const location = window.location.href.split("/");
    if (location[location.length - 1] === "ticketservice") {
      setCurrentLocation("ticketservice");
    }
  });

  const dispatchSearch = async (
    weekday: string,
    dispatchType: string,
    academyID: string
  ) => {
    const param = {
      weekday,
      dispatchType,
    };

    await ticketRequest.getBusList(param, academyID, (res: any) => {
      if (res.status === -1 || (res.status === 200 && res.data.code !== "OK")) {
        setShow(false);
        console.log(`[${res.data.code}] ${res.data.message}`);
      } else {
        setBusList(res.data.data);
        setShow(true);
      }
    });
  };

  useEffect(() => {
    dispatchSearch(day, dispatchType, academyID);
    setDetailActive(false);
  }, [academyID, day, dispatchType]);

  const [stepActive, setStepActive] = useState({
    list: true,
    detail: false,
  });

  useEffect(() => {
    setCoursesDetail(courses);
  }, [courses]);

  return (
    <TicketContext.Provider value={[busList, setBusList]}>
      <Container navToggle={navToggle} className="Container">
        <Contents>
          <PageTitle className="title">
            <BackStepButton
              img={BackIcon}
              width={"28px"}
              height={"28px"}
              onClick={() => {
                setStepActive({ list: true, detail: false });
              }}
              active={stepActive.detail}
            />
            승차권 관리
          </PageTitle>

          <TicketSettingContainer>
            <OptionWrapper>
              <Wrapper>
                <Title>운행 요일</Title>
                <RadioButtonWrapper>
                  {week.map((item, idx) => {
                    return (
                      <Checkbox key={item.key}>
                        <RadioButton
                          type="radio"
                          id={item.key}
                          checked={day === item.key}
                          onChange={(e) => changeSelectedWeekDay(e)}
                        />
                        <CheckboxLabel htmlFor="saveId">
                          {item.value}
                        </CheckboxLabel>
                      </Checkbox>
                    );
                  })}
                </RadioButtonWrapper>
              </Wrapper>
              <Wrapper>
                <Title>분류</Title>
                <RadioButtonWrapper>
                  {waysArr.map((item, idx) => {
                    return (
                      <Checkbox key={item.key}>
                        <RadioButton
                          type="radio"
                          id={item.type}
                          checked={dispatchType === item.type}
                          onChange={(e) => changeSelectedValue(e)}
                        />
                        <CheckboxLabel htmlFor="saveId">
                          {item.value}
                        </CheckboxLabel>
                      </Checkbox>
                    );
                  })}
                </RadioButtonWrapper>
              </Wrapper>
            </OptionWrapper>
            {/* <SearchBtn>
                            <div
                                onClick={() =>
                                    dispatchSearch(day, dispatchType, academyID)
                                }
                            >
                                검색
                            </div>
                        </SearchBtn> */}
          </TicketSettingContainer>
          <List>
            <Li active={show}>
              <AcademyBusList
                busTicketList={busList}
                setDispatchCode={setDispatchCode}
                setDispatchName={setDispatchName}
                setSelectedIndex={setSelectedIndex}
                setDetailActive={setDetailActive}
                setBusEndDate={setBusEndDate}
              />
            </Li>
            <Li active={detailActive}>
              <BusServiceDetail
                busList={busList}
                studentId={Number(studentId)}
                dispatchCode={dispatchCode}
                dispatchName={dispatchName}
                dispatchType={dispatchType}
                busEndDate={busEndDate}
                setCourses={setCourses}
                setCenter={setCenter}
                day={day}
                seatInfo={busList[selectedIndex]}
                dispatchSearch={dispatchSearch}
                detailActive={detailActive}
              />
            </Li>
            <Li active={detailActive}>
              <BusServiceMap
                busList={busList}
                studentId={Number(studentId)}
                dispatchCode={dispatchCode}
                courseStations={coursesDetail}
                setCourseStations={setCoursesDetail}
                center={center}
                setCenter={setCenter}
                detailActive={detailActive}
              />
            </Li>
          </List>
        </Contents>
      </Container>
    </TicketContext.Provider>
  );
};

const Contents = styled.div`
  width: 100%;
`;

interface BackStepButtonProps {
  active?: boolean | undefined;
}

const BackStepButton = styled(BackButton)<BackStepButtonProps>`
  margin-right: 10px;
  display: ${({ active }) => (active ? "block" : "none")};
  ${media.tablet} {
    display: none;
  }
`;

type ListProps = {};

const List = styled.ul<ListProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${media.desktop} {
    flex-wrap: nowrap;
  }
`;

interface LiProps {
  active?: boolean | undefined;
}

const Li = styled.li<LiProps>`
  display: inline-block;
  width: 100%;
  position: relative;
  flex-basis: 100%;
  display: ${({ active }) => (active ? "block" : "none")};
  ${media.tablet} {
    display: block;
    flex-basis: calc(50% - 10px);
    :last-child {
      display: none;
    }
  }
  ${media.desktop},
  ${media.fulldesktop},
    ${media.extrafulldesktop} {
    flex-basis: calc(33.3333333334% - 10px);
    max-width: 453px;
    margin-right: 20px;
    :last-child {
      display: block;
      flex: 1;
      max-width: 3000px;
    }
  }
`;

const TicketSettingContainer = styled.div`
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
  padding: 24px;
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OptionWrapper = styled.div`
  display: flex;
  gap: 40px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  color: #174490;
  font-size: 12px;
  font-weight: 800;
  margin-bottom: 8px;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  gap: 12px;
`;
