/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { Fragment, useContext, useLayoutEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import { BackButton, Icon, Button, Label, Input, Comment, ButtonGrey } from "styles/ui";
import { RegisterStudentContext } from "context/registerStudent";
import BackIcon from "assets/images/icons/back_icon.svg";
import StudentIcon from "assets/images/icons/student_mini_icon.svg";
import PlusIcon from "assets/images/icons/plus_icon.svg";
import { useFormatPhoneNumber } from "hooks/useFormatPhoneNumber";
import { media } from "styles/media";
import { GlobalContext } from "context/global";
// import { AuthContext } from 'context/auth';
import StudentRequest from "http/student";
import ParentsRequest from "http/parents";
import MemberRequest from "http/member";
const studentRequest = new StudentRequest();
const parentsRequest = new ParentsRequest();
const memberRequest = new MemberRequest();

interface RegisterStudentStep2Props {}

const PopUpBackButton = styled(BackButton)`
    position: absolute;
    top: 25px;
    left: 30px;
    ${media.tablet} {
        top: 35px;
    }
`;

const ParentInfo = styled.ul`
    margin-top: 34px;
    font-weight: bold;
`;

const Info = styled.li`
    margin-bottom: 26px;
`;

const Title = styled.div`
    font-size: 12px;

    color: ${({ theme }) => theme.colors.blue};
    margin-bottom: 8px;
`;

const Content = styled.div`
    font-size: 16px;
    line-height: 1.6;
`;

const bottomStyle = css`
    padding: 30px;
    background-color: #f4f4f4;
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-bottom: -30px;
    border-radius: 0 0 10px 10px;
    max-height: calc(100vh - 300px);
    overflow: auto;
    ${media.tablet} {
        width: calc(100% + 80px);
        max-height: calc(100vh - 280px);
        margin-left: -40px;
        margin-bottom: -40px;
    }
`;

const StudentListBox = styled.div`
    ${bottomStyle}
`;

const StudentList = styled.ul``;

const Student = styled.li`
    display: flex;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.grey};
    padding: 17px 14px;
    margin-bottom: 10px;
    :hover {
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    }
    :last-child {
        margin-bottom: 0;
    }
    :hover {
        cursor: pointer;
    }
`;

const StudentInfo = styled.div`
    margin-left: 12px;
`;
const StudentName = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 4px;
`;
const StudentStationName = styled.div`
    font-size: 16px;
    color: #585858;
`;

const AddStudentButtonBox = styled.div`
    text-align: center;
`;

const AddStudentButton = styled(Button)`
    display: inline-flex;
    align-items: center;
    margin-top: 20px;
`;

const NewStudentInfoList = styled.ul`
    ${bottomStyle}
`;
const NewStudentInfo = styled.li`
    margin-bottom: 26px;
`;
const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row-reverse;
    ${Button} {
        margin-left: 10px;
    }
`;

const BackToStudentListButton = styled.div`
    width: 100px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;
    line-height: 1.1;
    ${BackButton} {
        margin-right: 10px;
    }
`;

interface dataInterface {
    id?: number;
    name?: string;
    phone?: string;
}
export const RegisterStudentStep2: React.FC<RegisterStudentStep2Props> = () => {
    // const { academyId } = useContext(AuthContext);
    const { setRegisterStudentPopUp } = useContext(GlobalContext);
    const { registerStudentState, setRegisterStudentState } = useContext(RegisterStudentContext);

    const [newStudent, setNewStudent] = useState({
        name: "",
        nickName: "",
        phone: ""
    });
    const [isValid, setIsValid] = useState({
        newStudentName: true
    });
    const [comment, setComment] = useState({
        newStudentName: ""
    });
    const [addNewStudent, setAddNewStudent] = useState(false);

    let formedPhone: any = useFormatPhoneNumber({ phone: newStudent.phone });
    const [data, setData] = useState<dataInterface[]>([]);

    useLayoutEffect(() => {
        const param = registerStudentState.parentCode !== undefined ? registerStudentState.parentCode : "newParent";

        if (param !== "newParent") {
            // 학원에 등록되지 않은 학생리스트 조회
            parentsRequest.getStudentsByParentCode(
                param,
                (response: any) => {
                    if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                        console.log(`[${response.data.code}] ${response.data.message}`);
                    } else {
                        setData(response.data.data);
                    }
                },
                (error: any) => {
                    setData([]);
                    /*
                        테스트용
                    const tmp: dataInterface[] = [
                        {
                            id: 1,
                            name: '홍돌이',
                            phone: '010-0000-1111',
                        },
                        {
                            id: 2,
                            name: '홍순이',
                            phone: '010-0000-2222',
                        },
                    ];
                    setData(tmp);
                    */
                }
            );
        }
    }, [registerStudentState.parentCode]);

    const newStudentNameInput = useRef<HTMLInputElement>(null);
    const shortPhone: any = (phone: string) => {
        return phone.split("-")[2];
    };

    /** 이름 유효성 검증 : 성공 false, 실패 true */
    const nameValidation = () => {
        if (newStudent.name === "") {
            setComment({
                newStudentName: "를 입력해주세요. (필수입력)"
            });
            setIsValid({
                newStudentName: false
            });
            newStudentNameInput.current?.focus();
            return true;
        } else if (newStudent.name.length < 2) {
            setComment({
                newStudentName: "은 2자 이상 입력해주세요."
            });
            setIsValid({
                newStudentName: false
            });
            newStudentNameInput.current?.focus();
            return true;
        } else if (newStudent.name[0].search(/[a-zA-Z|가-힣]/)) {
            setComment({
                newStudentName: "은 첫 글자로 한글 또는 영문만 입력 가능합니다."
            });
            setIsValid({
                newStudentName: false
            });
            newStudentNameInput.current?.focus();
            return true;
        }

        return false;
    };

    return (
        <Fragment>
            <PopUpBackButton
                img={BackIcon}
                width={"28px"}
                height={"28px"}
                onClick={() => {
                    setRegisterStudentState({
                        ...registerStudentState,
                        hasBackButton: false,
                        step1: true,
                        step2: false
                    });
                }}
            />
            <ParentInfo>
                <Info>
                    <Title>보호자 이름</Title>
                    <Content>{registerStudentState.parentName}</Content>
                </Info>
                <Info>
                    <Title>보호자 연락처</Title>
                    <Content>{registerStudentState.parentPhone}</Content>
                </Info>
            </ParentInfo>
            {registerStudentState.hasParent && !addNewStudent ? (
                <StudentListBox>
                    <StudentList>
                        {data.map((student: any) => {
                            const shortStudentPhone = shortPhone(student?.phone);
                            return (
                                <Student
                                    key={student.id}
                                    onClick={async e => {
                                        e.preventDefault();
                                        if (
                                            !window.confirm(
                                                `${student.name} / ${shortStudentPhone} 학생을 등록 하시겠습니까?`
                                            )
                                        ) {
                                            return;
                                        }

                                        const param = {
                                            studentId: Number(student?.id)
                                        };
                                        memberRequest.registStudent(
                                            param,
                                            (response: any) => {
                                                if (
                                                    response.status === -1 ||
                                                    (response.status === 200 && response.data.code !== "OK")
                                                ) {
                                                    alert(`[${response.data.code}] ${response.data.message}`);
                                                } else {
                                                    alert("학생등록을 완료하였습니다.");
                                                    setRegisterStudentPopUp(false);
                                                    window.location.reload();
                                                }
                                            },
                                            (error: any) => {}
                                        );
                                    }}
                                >
                                    <Icon img={StudentIcon} width={"28px"} height={"28px"} />
                                    <StudentInfo>
                                        <StudentName>
                                            {student.name} / {shortStudentPhone}
                                        </StudentName>
                                        <StudentStationName>
                                            {student.station ? student.station?.name : "정류장 정보 없음"}
                                        </StudentStationName>
                                    </StudentInfo>
                                </Student>
                            );
                        })}
                    </StudentList>

                    <AddStudentButtonBox>
                        <AddStudentButton
                            onClick={e => {
                                e.preventDefault();
                                setAddNewStudent(true);
                            }}
                        >
                            <span>학생 추가 등록</span>
                            <Icon img={PlusIcon} width={"12px"} height={"12px"}></Icon>
                        </AddStudentButton>
                    </AddStudentButtonBox>
                </StudentListBox>
            ) : (
                <NewStudentInfoList>
                    {registerStudentState.hasParent && (
                        <BackToStudentListButton
                            onClick={() => {
                                setAddNewStudent(false);
                            }}
                        >
                            <BackButton img={BackIcon} width={"28px"} height={"28px"} />
                            학생 리스트
                        </BackToStudentListButton>
                    )}
                    <NewStudentInfo>
                        <Label htmlFor="newStudentName">
                            학생 이름
                            <Comment>{comment.newStudentName}</Comment>
                        </Label>
                        <Input
                            id="newStudentName"
                            ref={newStudentNameInput}
                            type="text"
                            value={newStudent.name}
                            onChange={e =>
                                setNewStudent({
                                    ...newStudent,
                                    name: e.target.value.replace(/[`₩~!@#$%^&*,.<>+=|;:'"/?{}\s\[\]\\]/, "")
                                })
                            }
                            isValid={isValid.newStudentName}
                            required
                            maxLength={10}
                        />
                    </NewStudentInfo>
                    <NewStudentInfo>
                        <Label htmlFor="newStudentNickName">학년 정보</Label>
                        <Input
                            id="newStudentNickName"
                            type="text"
                            value={newStudent.nickName}
                            onChange={e =>
                                setNewStudent({
                                    ...newStudent,
                                    nickName: e.target.value
                                })
                            }
                            isValid={true}
                        />
                    </NewStudentInfo>
                    <NewStudentInfo>
                        <Label htmlFor="newStudentPhone">학생 연락처</Label>
                        <Input
                            id="newStudentPhone"
                            type="text"
                            value={formedPhone}
                            onChange={e =>
                                setNewStudent({
                                    ...newStudent,
                                    phone: e.target.value
                                })
                            }
                            isValid={true}
                            maxLength={13}
                        />
                    </NewStudentInfo>
                    <ButtonGroup>
                        {registerStudentState.hasParent ? (
                            <Button
                                onClick={async e => {
                                    e.preventDefault();

                                    if (nameValidation()) {
                                        return;
                                    }

                                    if (!window.confirm(`${newStudent.name} 학생을 등록 하시겠습니까?`)) {
                                        return;
                                    }

                                    // 신규 학생 생성
                                    const param1 = {
                                        parentCode: registerStudentState.parentCode,
                                        name: newStudent.name,
                                        nickName: newStudent.nickName,
                                        phone: formedPhone
                                    };

                                    new Promise((resolve, reject) => {
                                        studentRequest.addStudent(
                                            param1,
                                            (response: any) => {
                                                if (
                                                    response.status === -1 ||
                                                    (response.status === 200 && response.data.code !== "OK")
                                                ) {
                                                    alert(`[${response.data.code}] ${response.data.message}`);
                                                    return false;
                                                } else {
                                                    resolve(response.data.data);
                                                }
                                            },
                                            (error: any) => {}
                                        );
                                    }).then((result: any) => {
                                        if (result !== false) {
                                            // 위에서 생성한 학생을 학원에 등록
                                            const param2 = {
                                                studentId: Number(result?.id)
                                            };
                                            memberRequest.registStudent(
                                                param2,
                                                (response: any) => {
                                                    if (
                                                        response.status === -1 ||
                                                        (response.status === 200 && response.data.code !== "OK")
                                                    ) {
                                                        alert(`[${response.data.code}] ${response.data.message}`);
                                                    } else {
                                                        alert("학생등록을 완료하였습니다.");
                                                        setRegisterStudentPopUp(false);
                                                        // window.location.reload();
                                                    }
                                                },
                                                (error: any) => {}
                                            );
                                        }
                                    });
                                }}
                            >
                                등록
                            </Button>
                        ) : (
                            <Button
                                onClick={async e => {
                                    e.preventDefault();

                                    if (nameValidation()) {
                                        return;
                                    }

                                    if (!window.confirm(`${newStudent.name} 학생을 등록 하시겠습니까?`)) {
                                        return;
                                    }

                                    const param = {
                                        name: registerStudentState.parentName,
                                        phone: registerStudentState.parentPhone
                                    };

                                    new Promise((resolve, reject) => {
                                        // 신규 부모 생성
                                        parentsRequest.addParent(
                                            param,
                                            (response: any) => {
                                                if (
                                                    response.status === -1 ||
                                                    (response.status === 200 && response.data.code !== "OK")
                                                ) {
                                                    alert(`[${response.data.code}] ${response.data.message}`);
                                                } else {
                                                    resolve(response.data.data);
                                                }
                                            },
                                            (error: any) => {}
                                        );
                                    })
                                        .then((result: any) => {
                                            if (result !== false) {
                                                return new Promise((resolve, reject) => {
                                                    const param1 = {
                                                        parentCode: result.code,
                                                        name: newStudent.name,
                                                        nickName: newStudent.nickName,
                                                        phone: newStudent.phone
                                                    };
                                                    // 신규 학생 생성
                                                    studentRequest.addStudent(
                                                        param1,
                                                        (response: any) => {
                                                            if (
                                                                response.status === -1 ||
                                                                (response.status === 200 && response.data.code !== "OK")
                                                            ) {
                                                                alert(
                                                                    `[${response.data.code}] ${response.data.message}`
                                                                );
                                                            } else {
                                                                resolve(response.data.data);
                                                            }
                                                        },
                                                        (error: any) => {}
                                                    );
                                                });
                                            }
                                        })
                                        .then((result: any) => {
                                            // 학원생 등록
                                            const param2 = {
                                                studentId: Number(result?.id)
                                            };
                                            memberRequest.registStudent(
                                                param2,
                                                (response: any) => {
                                                    if (
                                                        response.status === -1 ||
                                                        (response.status === 200 && response.data.code !== "OK")
                                                    ) {
                                                        alert(`[${response.data.code}] ${response.data.message}`);
                                                    } else {
                                                        alert("학생등록을 완료하였습니다.");
                                                        setRegisterStudentPopUp(false);
                                                        window.location.reload();
                                                    }
                                                },
                                                (error: any) => {}
                                            );
                                        });
                                }}
                            >
                                등록
                            </Button>
                        )}

                        <ButtonGrey
                            onClick={async e => {
                                e.preventDefault();
                                await setRegisterStudentPopUp(false);
                            }}
                        >
                            취소
                        </ButtonGrey>
                    </ButtonGroup>
                </NewStudentInfoList>
            )}
        </Fragment>
    );
};
