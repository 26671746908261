import React from "react";
import styled from "styled-components";

const Loader = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    display: inline-block;
    width: 40px;
    height: 40px;
    transform: translate3d(-50%, -50%, 0);
    ::after {
        content: " ";
        display: block;
        width: 32px;
        height: 32px;
        margin: 0px;
        border-radius: 50%;
        z-index: 10000;
        border: 3px solid ${props => props.theme.colors.yellow};
        border-color: ${props => props.theme.colors.yellow} transparent ${props => props.theme.colors.yellow}
            transparent;
        animation: lds-dual-ring 1.5s linear infinite;
    }
    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

interface LoadingProps {}

export const Loading: React.FC<LoadingProps> = () => {
    return <Loader></Loader>;
};
