export class BoardStatusType {
    static readonly ALL = new BoardStatusType(null, "전체");
    static readonly READY = new BoardStatusType(0, "대기중");
    static readonly PROCEED = new BoardStatusType(1, "처리중");
    static readonly DONE = new BoardStatusType(2, "처리완료");
    static readonly ALL_TYPES = [BoardStatusType.ALL, BoardStatusType.READY, BoardStatusType.PROCEED, BoardStatusType.DONE];

    private constructor(readonly value: number, readonly exposure: string) {}

    static parse(value: number): BoardStatusType {
        switch (value) {
            case BoardStatusType.ALL.value:
                return BoardStatusType.ALL;
            case BoardStatusType.READY.value:
                return BoardStatusType.READY;
            case BoardStatusType.PROCEED.value:
                return BoardStatusType.PROCEED;
            case BoardStatusType.DONE.value:
                return BoardStatusType.DONE;

            default:
                throw new Error(`invalid driver search type. (value: ${value})`);
        }
    }
}
