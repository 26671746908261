
import Request from "./request";

class OperatorRequest extends Request {
    /**
     * 로그인한 사용자의 회원정보 가져오기 (useMeQuery)
     * params
     *      x - 헤더에서 넘기는 사용자 토큰으로 구분
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getUserInfo = (callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get("/user/me", request);
    }

    /**
     * 관리자 기본정보 수정  (useUpdateUserInfoMutation)
     * params
     *      email  관리자 이메일
     *      name   관리자 이름
     *      phone  관리자 전화번호
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    updateUserInfo = (data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback
        };
        this.put("/user", request);
    }

    /**
     * 관리자 비밀번호 수정  (useUpdateUserPasswordMutation)
     * params
     *      email           관리자 이메일
     *      oldPassword      관리자 기존 이메일
     *      newPassword     관리자 새로운 이메일
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    updateUserPassword = (data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback
        };
        this.put("/user/password", request);
    }
}

export default OperatorRequest;