import React, { useContext, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";

import { media } from "styles/media";
import { Container, Box, SearchBox, PageTitle } from "styles/layout";

import { GlobalContext } from "context/global";

// store
import { useMasterDashboardStore } from "store/masterDashboardStore";

// molecules
import MasterDashboardAcademyBox, { AcademyInfoTypes } from "components/molecules/masterDashboardAcademyBox";
import SearchInput from "components/molecules/searchInput";
// http
import MasterDashboardRequest from "http/master-dashboard";

const masterDashboardRequest = new MasterDashboardRequest();

const MasterDashboardPage = () => {
    const history = useHistory();
    const { setToast } = useContext(GlobalContext);

    const { academies, setAcademies, clickedAcademyInfo, setClickedAcademyInfo } = useMasterDashboardStore();

    const { navToggle, setCurrentLocation, setIsViewStudentRegistrationButton } = useContext(GlobalContext);

    const [isAcademyPressPageMoved, setIsAcademyPressPageMoved] = useState(false);
    const [academySearchTerm, setAcademySearchTerm] = useState("");
    const [academySearchResults, setAcademySearchResults] = useState<AcademyInfoTypes[]>([]);

    useEffect(() => {
        // 검색어가 있다면 filter를 거침
        // 검색어가 없다면 academies 할당
        setAcademySearchResults(
            academySearchTerm.length
                ? academies.filter((academy: AcademyInfoTypes) =>
                      academy.name.toUpperCase().includes(academySearchTerm.trim().toUpperCase())
                  )
                : academies
        );
    }, [academies, academySearchTerm]);

    useEffect(() => {
        setCurrentLocation("master-dashboard");
    }, []);

    useEffect(() => {
        setIsViewStudentRegistrationButton(false);
        return () => setIsViewStudentRegistrationButton(true);
    }, []);

    useEffect(() => {
        localStorage.setItem("academies", JSON.stringify(academies));
    }, [academies]);

    useEffect(() => {
        masterDashboardRequest.getAcademies(
            (response: any) => {
                const responseData = response.data;
                if (responseData.status !== 1) {
                    setToast({
                        toggle: true,
                        toastStatus: "fail",
                        message: `오류가 발생했습니다. ${response.data.message}`
                    });
                    return;
                }
                setAcademies(
                    responseData.data.sort(function (a: AcademyInfoTypes, b: AcademyInfoTypes) {
                        let x = a.name.toLowerCase();
                        let y = b.name.toLowerCase();
                        if (x < y) {
                            return -1;
                        }
                        if (x > y) {
                            return 1;
                        }
                        return 0;
                    })
                );
            },
            (error: any) => {
                setToast({
                    toggle: true,
                    toastStatus: "fail",
                    message: "학원 리스트를 받아오는 도중 오류가 발생했습니다."
                });
            }
        );
    }, []);

    // clickedAcademyInfo가 변경되면 localStorage에 똑같이 저장
    useEffect(() => {
        const stringifyAcademyInfo: string = JSON.stringify(clickedAcademyInfo);
        localStorage.setItem("academyInfo", stringifyAcademyInfo);
        localStorage.setItem("academyId", String(clickedAcademyInfo?.id));
    }, [clickedAcademyInfo]);

    useEffect(() => {
        if (isAcademyPressPageMoved) {
            history.push("/dashboard");
        }
    }, [isAcademyPressPageMoved]);

    const handleAcademyBoxClick = (academyInfo: AcademyInfoTypes) => {
        // 선택한 academyId와 선택되어있는 academyId가 같으면 0 할당
        setClickedAcademyInfo(clickedAcademyInfo.id === academyInfo.id ? { id: 0, name: "" } : academyInfo);

        // 학원 클릭 시 페이지 이동을 위한 state 변경
        if (clickedAcademyInfo.id !== academyInfo.id) {
            setIsAcademyPressPageMoved(true);
        }
    };

    const handleAcademySearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setAcademySearchTerm(e.target.value);
    const handleSearchInputClear = () => setAcademySearchTerm("");

    return (
        <DashBoardContainer navToggle={navToggle}>
            <MasterDashboardHeaderWrap>
                <MasterDashboardHeader>
                    안녕하세요! 현재 <strong>Master 계정</strong>으로 접속 하셨습니다.
                </MasterDashboardHeader>
            </MasterDashboardHeaderWrap>

            <SearchInput
                value={academySearchTerm}
                onChange={handleAcademySearchTermChange}
                onClickClear={handleSearchInputClear}
            />

            <MasterDashboardAcademyBoxContainer>
                {academySearchResults.length ? (
                    academySearchResults.map((academy: AcademyInfoTypes) => {
                        return (
                            <MasterDashboardAcademyBox
                                key={academy.id}
                                academyInfo={academy}
                                isClicked={academy.id === clickedAcademyInfo.id}
                                onClick={handleAcademyBoxClick}
                            />
                        );
                    })
                ) : (
                    <SearchNotFoundTextWrap>
                        <SearchNotFoundText>검색된 학원이 없습니다.</SearchNotFoundText>
                    </SearchNotFoundTextWrap>
                )}
            </MasterDashboardAcademyBoxContainer>
        </DashBoardContainer>
    );
};
export default MasterDashboardPage;

const DashBoardContainer = styled(Container)`
    ${media.tablet_} {
        padding-top: 113px;
    }
    padding-left: 24px;
`;
const MasterDashboardHeaderWrap = styled.div`
    display: none;

    ${media.tablet_} {
        display: block;

        position: absolute;
        top: 42px;
        z-index: 10000;
    }
`;

const MasterDashboardHeader = styled.h1`
    font-weight: 300;
    font-size: 30px;

    > strong {
        font-weight: 800;
    }
`;

const Contents = styled.div`
    ${media.desktop} {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    ${SearchBox} {
        position: absolute;
        top: 52px;
        width: calc(100% - 20px);
        z-index: 1;
    }
`;

const MasterDashboardAcademyBoxContainer = styled.div`
    ${media.tablet} {
        justify-content: flex-start;
    }
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: space-between;

    width: 100%;
    height: auto;
    margin-top: 20px;
`;
const SearchNotFoundTextWrap = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 54px;
`;
const SearchNotFoundText = styled.p`
    color: #999999;
    font-size: 13px;
    text-align: center;
    line-height: 20px;
`;
