import Request from "./request";

class TicketRequest extends Request {
    // 기간, 요일, 분류에 따른 버스리스트
    getPeriodList = (params, callback) => {
        const request = {
            callback,
            params,
        };
        this.get("/dispatches/period", request);
    };

    getBusList = (params, academyID, callback) => {
        const request = {
            params,
            callback,
        };
        this.get(
            `/dispatches/with-seat-occupancy?academyId=${academyID}`,
            request
        );
    };

    getDispatchesDetail = (params, callback) => {
        const request = {
            params,
            callback,
        };
        this.get(`/dispatches/detail?${params}`, request);
    };

    getUpdateDispatchesDetail = (params, callback) => {
        const request = {
            params,
            callback,
        };
        this.get(`/dispatches/detail`, request);
    };


    // 초과 인원 확인
    getOverBookingInfo = (params, callback) => {
        const request = {
            params,
            callback,
        };
        console.log(params);
        this.get(`/dispatches/over-booking`, request);
    };

    // 중복 여부
    postExistStudentTicket = (data, callback) => {
        const request = {
            data,
            callback,
        };
        this.post(`/schedules/exists`, request);
    };

    // 학생 승차권 등록
    postCreateStudentTicket = (data, callback) => {
        const request = {
            data,
            callback,
        };
        this.post(`/schedules`, request);
    };

    // 학생 승차권 삭제
    deleteStudentTicket = (data, callback) => {
        const request = {
            data,
            callback,
        };
        this.del(`/schedules`, request);
    };

    // 학생 리스트(내림차순)
    getStudentList = (params, callback) => {
        const request = {
            params,
            callback,
        };
        this.get(`/students`, request);
    };
}

export default TicketRequest;
