import React from "react";

interface BusSeatStatusProps {
    data: any;
}

export const BusSeatStatus: React.FC<BusSeatStatusProps> = ({ data }) => {
    return (
        <div style={{ position: "relative", display: "inline-block" }}>
            {data.usedSeat !== undefined && data.usedSeat >= data.validSeat + data.tempSeat ? (
                <span style={{ color: "#F00" }}>{data.usedSeat}</span>
            ) : (
                <span>{data.usedSeat}</span>
            )}
            <span> / </span>
            <span>{data.validSeat + data.tempSeat}</span>
        </div>
    );
};
