import React, { useContext, useEffect, useState } from "react";
import { BusServiceContext } from "context/busService";
import styled from "styled-components";
import { css } from "styles/theme-components";
import OpenMapButtonIcon from "assets/images/icons/open_map_button_icon.svg";
import { Icon } from "styles/ui";
import BusRequest from "http/bus";
import bus from "http/bus";
const busRequest = new BusRequest();

interface SummaryProps {}

interface StatusProps {
    status?: number | undefined;
}

const statusColors = css<StatusProps>`
    ${({ status }) =>
        status === -1
            ? css`
                  ${({ theme }: any) => theme.colors.grey}
              `
            : status === 0
            ? css`
                  ${({ theme }: any) => theme.colors.purple}
              `
            : status === 1
            ? css`
                  ${({ theme }: any) => theme.colors.green}
              `
            : "#e9e9e9"};
`;

const SummaryBox = styled.div<StatusProps>`
    position: relative;
    color: ${({ theme }) => theme.colors.white};
    ${({ status }) =>
        status === 2
            ? css`
                  color: #afafaf;
              `
            : null}
    font-weight: 800;
    border-radius: 20px 20px 0 0;
    padding: 30px;
    background-color: ${statusColors};
`;
const BusName = styled.h2`
    height: 22px;
`;
const DispatchName = styled.h1`
    height: 22px;
    margin-bottom: 20px;
    line-height: 1.6;
    font-size: 18px;
    width: calc(100% - 60px);
`;
const Manager = styled.div`
    margin-bottom: 12px;
`;
const Driver = styled.div``;

const Status = styled.div<StatusProps>`
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 10px;
    font-weight: 800;
    color: ${statusColors};
    ${({ status }) =>
        status === 2
            ? css`
                  color: #afafaf;
              `
            : null}
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.white};
    text-align: center;
    border-radius: 30px;
`;

const OpenMapButton = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    font-size: 11px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    line-height: 1.1;
    position: absolute;
    bottom: 20px;
    right: 20px;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    z-index: 2;
    color: #fff;
    :hover {
        -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);
    }
    ${Icon} {
        margin-right: 5px;
    }
`;

interface dispatchInterface {
    name?: string;
}

interface busInterface {
    name?: string;
    driver?: string;
    driverPhone?: string;
}

interface managerInterface {
    name?: string;
    phone?: string;
}

interface busServiceInterpace {
    dispatch?: dispatchInterface;
    bus?: busInterface;
    manager?: managerInterface;
    status?: number;
}
export const Summary: React.FC<SummaryProps> = () => {
    const { busServiceId, status, setStatus, setLayerMap, dispatchCode } = useContext(BusServiceContext);
    const [busService, setBusService] = useState<busServiceInterpace>({});

    useEffect(() => {
        setBusService({});
        console.log({ busServiceId, dispatchCode });

        if (busServiceId === 0) {
            busRequest.getBusServiceMasterBydispatchCode(
                dispatchCode,
                (response: any) => {
                    if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                        console.log(`[${response.data.code}] ${response.data.message}`);
                    } else {
                        setBusService(response.data.data);
                        setStatus(Number(response.data.data.status));
                    }
                },
                (error: any) => {
                    console.log("여기");
                    setBusService({});
                }
            );
        } else {
            busRequest.getBusServiceMaster(
                busServiceId,
                (response: any) => {
                    if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                        console.log(`[${response.data.code}] ${response.data.message}`);
                    } else {
                        setBusService(response.data.data);
                        setStatus(Number(response.data.data.status));
                    }
                },
                (error: any) => {
                    console.log("여기");
                    setBusService({});
                }
            );
        }
        // eslint-disable-next-line
    }, [busServiceId, dispatchCode]);

    const getDispatchSchedule = () => {
        let academyName = busService.dispatch?.name?.split("-");
        let strCnt = busService.dispatch?.name?.length;

        if (academyName !== undefined) {
            let result = academyName.splice(2, academyName.length);
            return result.join("-");
        } else {
            return "";
        }
    };

    // 연락처에 하이픈 추가 함수
    const addHyphen = (param: string) => {
        if (!param) {
            return "";
        }

        param = param.replace(/[^0-9]/g, "");

        let result = [];
        let restNumber = "";

        // 지역번호와 나머지 번호로 나누기
        if (param.startsWith("02")) {
            // 서울 02 지역번호
            result.push(param.substr(0, 2));
            restNumber = param.substring(2);
        } else if (param.startsWith("1")) {
            // 지역 번호가 없는 경우
            // 1xxx-yyyy
            restNumber = param;
        } else {
            // 나머지 3자리 지역번호
            // 0xx-yyyy-zzzz
            result.push(param.substr(0, 3));
            restNumber = param.substring(3);
        }

        if (restNumber.length === 7) {
            // 7자리만 남았을 때는 xxx-yyyy
            result.push(restNumber.substring(0, 3));
            result.push(restNumber.substring(3));
        } else {
            result.push(restNumber.substring(0, 4));
            result.push(restNumber.substring(4));
        }

        return result.filter(val => val).join("-");
    };

    return (
        <SummaryBox status={status} className="summary">
            <BusName>{busService.bus?.name}</BusName>
            <DispatchName>{getDispatchSchedule()}</DispatchName>
            <Manager>
                • 동승자:{busService.manager?.name || ""}(
                {busService.manager?.phone ? addHyphen(busService.manager?.phone) : "" || ""})
            </Manager>
            <Driver>
                • 운전사:{busService.bus?.driver || ""}(
                {busService.bus?.driverPhone ? addHyphen(busService.bus?.driverPhone) : "" || ""})
            </Driver>
            <Status status={status} className={`status ${status}`}>
                {status === -1 ? (
                    "운행전"
                ) : status === 0 ? (
                    <span>
                        운행
                        <br />
                        대기
                    </span>
                ) : status === 1 ? (
                    "운행중"
                ) : status === 3 ? (
                    "미운행"
                ) : (
                    <span>
                        운행
                        <br />
                        완료
                    </span>
                )}
            </Status>
            <OpenMapButton
                onClick={() => {
                    setLayerMap(true);
                }}
                className="open-map-button"
            >
                <Icon img={OpenMapButtonIcon} width={"14px"} height={"14px"} />
                지도 확대보기
            </OpenMapButton>
        </SummaryBox>
    );
};
