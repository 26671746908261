import React from "react";
import styled from "styled-components";
import {flexColumn, flexRow} from "styles/CommonStyle";

type DeleteRequestConfirmProps = {onClose: () => void; deleteRequest: () => void};

function DeleteRequestConfirm(props: DeleteRequestConfirmProps) {
    return (
        <Container>
            <Title>요청 삭제</Title>
            <Content>
                (대기중) 상태일 때만 삭제 가능합니다.
                <br />
                <br />
                요청을 삭제 하시겠습니까?
            </Content>
            <ButtonWrapper>
                <CancelButton onClick={props.onClose}>취소</CancelButton>
                <DeleteButton onClick={props.deleteRequest}>삭제</DeleteButton>
            </ButtonWrapper>
        </Container>
    );
}

export default DeleteRequestConfirm;

const Container = styled.div`
    width: 100%;
    height: 100%;
    ${flexColumn};
    justify-content: center;
`;

const Title = styled.div`
    color: #1e2939;
    text-align: center;
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 16px;
    line-height: 18px;
`;

const Content = styled.div`
    color: #1e2939;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.42px;
    line-height: 20px;
`;

const ButtonWrapper = styled.div`
    margin-top: 30px;
    ${flexRow};
    justify-content: center;
    column-gap: 4px;
    align-items: center;
`;

const CancelButton = styled.div`
    border-radius: 30px;
    background: #efefef;
    width: 98px;
    height: 36px;
    color: #000;
    line-height: 16px;
    font-size: 14px;
    font-weight: 800;
    padding: 10px 22px;
    cursor: pointer;
    text-align: center;
`;

const DeleteButton = styled(CancelButton)`
    background: #ffcd00;
    color: #000;
`;
