import { Optional } from "../type/Common";

export function isNull<T>(input: T | null): input is null {
  return input === null;
}

export function isUndefined<T>(input: T | undefined): input is undefined {
  return input === undefined;
}

export function isNil<T>(
  input: T | null | undefined
): input is null | undefined {
  return isNull<T | undefined>(input) || isUndefined<T>(input);
}

export function isEmptyArray<T>(arr: Optional<T[]>): boolean {
  return !(arr && arr.length && arr.length > 0);
}

export function isEmptyString(str: Optional<string>): boolean {
  return !(str && str.length && str !== "");
}

export function orElse<T>(value: Optional<T>, sub: T) {
  if (isNil(value)) {
    return sub;
  }

  return value;
}
