import React, { ForwardedRef, forwardRef, ReactElement } from "react";
import styled from "styled-components";

type SVGImageProps = {
  className?: string;
  source: string;
};
export type SVGImageRef = {};

function SVGImageBase(
  props: SVGImageProps,
  _: ForwardedRef<SVGImageRef>
): ReactElement {
  return <Icon className={props.className} src={props.source} />;
}

const SVGImage = forwardRef(SVGImageBase);
export default SVGImage;

const Icon = styled.img`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || ""};
  cursor: pointer;
  object-fit: cover;
  display: block;
`;
