import React from "react";
import styled from "styled-components";
import { css } from "styles/theme-components";

interface SummaryProps {
    dispatchCode?: string;
    dispatchName?: string;
    driver?: driverInterface;
    manager?: driverInterface;
}

interface driverInterface {
    name?: string;
    phone?: string;
}

export const Summary: React.FC<SummaryProps> = ({
    dispatchCode,
    dispatchName,
    driver,
    manager,
}) => {
    // 연락처에 하이픈 추가 함수
    const addHyphen = (param: string) => {
        if (!param) {
            return "";
        }

        param = param.replace(/[^0-9]/g, "");

        let result = [];
        let restNumber = "";

        // 지역번호와 나머지 번호로 나누기
        if (param.startsWith("02")) {
            // 서울 02 지역번호
            result.push(param.substr(0, 2));
            restNumber = param.substring(2);
        } else if (param.startsWith("1")) {
            // 지역 번호가 없는 경우
            // 1xxx-yyyy
            restNumber = param;
        } else {
            // 나머지 3자리 지역번호
            // 0xx-yyyy-zzzz
            result.push(param.substr(0, 3));
            restNumber = param.substring(3);
        }

        if (restNumber.length === 7) {
            // 7자리만 남았을 때는 xxx-yyyy
            result.push(restNumber.substring(0, 3));
            result.push(restNumber.substring(3));
        } else {
            result.push(restNumber.substring(0, 4));
            result.push(restNumber.substring(4));
        }

        return result.filter((val) => val).join("-");
    };

    return dispatchCode !== "" ? (
        <SummaryBox className="summary">
            {/* <BusName>{dispatchName}</BusName> */}
            {/* <DispatchName>{getDispatchSchedule()}</DispatchName> */}
            <DispatchName>{dispatchName}</DispatchName>
            <Manager>
                • 동승자:{manager?.name || ""}(
                {manager?.phone ? addHyphen(manager?.phone) : "" || ""})
            </Manager>
            <Driver>
                • 운전사:{driver?.name || ""}(
                {driver?.phone ? addHyphen(driver?.phone) : "" || ""})
            </Driver>
        </SummaryBox>
    ) : (
        <div></div>
    );
};

interface StatusProps {
    status?: number | undefined;
}

const SummaryBox = styled.div<StatusProps>`
    position: relative;
    color: #000;
    ${({ status }) =>
        status === 2
            ? css`
                  color: #afafaf;
              `
            : null}
    font-weight: 800;
    border-radius: 20px 20px 0 0;
    padding: 30px;
    background-color: #ffd100;
`;
const DispatchName = styled.h1`
    height: 22px;
    margin-bottom: 20px;
    line-height: 1.6;
    font-size: 18px;
    width: 100%;
`;
const Manager = styled.div`
    margin-bottom: 12px;
`;
const Driver = styled.div``;
