import Request from "./request";

class AuthRequest extends Request {
    /**
     * 로그인
     * params
     *      email       이메일
     *      password    비밀번호
     *      loginType   ACADEMY
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    login = (data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback
        };
        this.post("/login", request);
    }
}

export default AuthRequest;
