import React from "react";
import styled from "styled-components";
import { BoardTitle, BoardBox, StudentList, Student, NoShow, Name, DisabledEditStudent } from "./OnBoard";
import _ from "lodash";
import { BoardSwitch } from "./BoardSwitch";
import { FiMenu } from "react-icons/fi";
import { Draggable } from "react-beautiful-dnd";

interface OffBoardProps {
    stationId: number;
    schedules: any;
    studentId: number | undefined;
    scheduleEdit: boolean;
    showNickName: boolean;
}

const OffBoardTitle = styled(BoardTitle)`
    span {
        background-color: ${({ theme }) => theme.colors.blue};
    }
`;

const EditStudent = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 6px;
    height: 30px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(208, 208, 208, 0.6);
    border-radius: 4px;
    font-size: 12px;
    span {
        padding: 7px 5px 5px 5px;
        font-family: NanumSquareRound;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;
    }
`;
export const OffBoard: React.FC<OffBoardProps> = ({ stationId, schedules, studentId, scheduleEdit, showNickName }) => {
    let offBoardCounts = 0;

    if (_.isEmpty(schedules) === false) {
        schedules.map((schedule: any) => {
            if (stationId === Number(schedule.takeOffStation?.id) && schedule.status !== 4) {
                offBoardCounts = offBoardCounts + 1;
            }
            return null;
        });
    }

    //console.log(" schedule s " + schedules)
    return offBoardCounts === 0 ? null : (
        <BoardBox>
            <OffBoardTitle>
                <span>하차</span>
            </OffBoardTitle>
            <StudentList>
                {schedules.map((schedule: any, index: any) => {
                    if (stationId === Number(schedule.takeOffStation?.id) && schedule.status !== 4) {
                        return (
                            <Student
                                key={schedule.scheduleId}
                                thisStudentId={schedule.student?.id}
                                studentId={studentId}
                            >
                                {!scheduleEdit ? (
                                    <div>
                                        {schedule.isB2CUser ? (
                                            <p>
                                                {showNickName
                                                    ? schedule.student?.nickName === ""
                                                        ? `${schedule.student?.name}(B2C)`
                                                        : `${schedule.student?.name} - ${schedule.student?.nickName}(B2C)`
                                                    : `${schedule.student?.name}(B2C)`}
                                            </p>
                                        ) : (
                                            <Name
                                                to={`/students/${schedule.student?.id}`}
                                                title={"학생 페이지로 이동"}
                                                target={"blank"}
                                            >
                                                {showNickName
                                                    ? schedule.student?.nickName === ""
                                                        ? `${schedule.student?.name}`
                                                        : `${schedule.student?.name} - ${schedule.student?.nickName}`
                                                    : `${schedule.student?.name}`}
                                            </Name>
                                        )}
                                        <div className="board-switch">
                                            <BoardSwitch schedule={schedule} boardType={"offBoard"} />
                                            {schedule.boardSwitch !== 1 ? (
                                                ""
                                            ) : schedule.status === 3 ? (
                                                <NoShow>미승차</NoShow>
                                            ) : schedule.status === 4 ? (
                                                " 미탑승예약"
                                            ) : schedule.status === 5 ? (
                                                <NoShow>학원 복귀</NoShow>
                                            ) : (
                                                ` ${schedule.timeOffBoard}`
                                            )}
                                        </div>
                                    </div>
                                ) : schedule.isB2CUser ? (
                                    <DisabledEditStudent>
                                        <FiMenu size="12" color="#999" />
                                        <span>{schedule.student.name}(B2C)</span>
                                    </DisabledEditStudent>
                                ) : (
                                    <Draggable
                                        key={"outbound-draggable-" + schedule.student.id}
                                        draggableId={"outbound-" + schedule.student.id}
                                        data-studentId={schedule.student.id}
                                        index={index}
                                    >
                                        {provided => (
                                            <EditStudent
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                            >
                                                <FiMenu size="12" color="#999" />
                                                <span>{schedule.student.name}</span>
                                            </EditStudent>
                                        )}
                                    </Draggable>
                                )}
                            </Student>
                        );
                    } else {
                        return null;
                    }
                })}
            </StudentList>
        </BoardBox>
    );
};
