import Request from "./request";

class MemberRequest extends Request {

    /**
     * 학생 혹은 보호자의 이름 & 휴대번호를 활용하여 학생정보 조회 (useSearchStudentsLazyQuery)
     * params
     *      keyword     학생 & 보호자의 이름 or 휴대번호
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getStudents = (data, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback,
            includeAcademyId: true
        };
        this.get(`/member/students/search?keyword=${data}`, request);
    }

    /**
     * 학생 pk를 활용한 학생리스트 조회  (useGetStudentLazyQuery)
     * params
     *      id   학생 pk
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getStudentById = (data, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback,
            includeAcademyId: true
        };
        this.get(`/member/students/${data}`, request);
    }

    /**
     * 보호자코드를 활용한 학생리스트 조회_학원에등록된 학생들임
     * params
     *      parentCode   보호자 code
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getStudentsByParentCode = (data, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback,
            includeAcademyId: true
        };
        this.get(`/member/parents/${data}/students`, request);
    }
    
    /**
     * 학생리스트 전체 조회
     * params
     *      x
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getStudentsAll = (callback, errorCallback) => {
        const request = {
            callback,
            errorCallback,
            includeAcademyId: true
        };
        this.get(`/member/students`, request);
    }

    /**
     * 기존에 추가되어있는 학생 중 선택하여 학원에 등록  (useEnrollAcademyMutation)
     * params
     *      studentId   학생 pk
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    registStudent = (data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback,
            includeAcademyId: true
        };
        this.post("/member/students", request);
    }

    /**
     * 해당 학생의 메모 조회 (useGetMemosQuery)
     * params
     *      id   학생 pk
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getMemo = (data, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback,
            includeAcademyId: true
        };
        this.get(`/member/students/${data}/memo`, request);
    }

    /**
     * 해당 학생의 메모 생성 (useCreateMemoMutation)
     * params
     *      studentId   학생 pk
     *      content     메모내용
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    addMemo = (studentId, data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback,
            includeAcademyId: true
        };
        this.post(`/member/students/${studentId}/memo`, request);
    }

    /**
     * 해당 학생의 메모 수정 (useUpdateMemoMutation)
     * params
     *      memoId      메모 pk
     *      studentId   학생 pk
     *      content     메모내용
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    updateMemo = (studentId, data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback,
            includeAcademyId: true
        };
        this.put(`/member/students/${studentId}/memo`, request);
    }


}

export default MemberRequest;
