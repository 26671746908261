import React, { useContext, useState, useEffect, Fragment } from "react";
import styled from "styled-components";
// import { Link } from 'react-router-dom';
import { Icon } from "styles/ui";
// import ArrowRightIcon from 'assets/images/icons/arrow_right_icon.svg';
import BusIcon from "assets/images/icons/bus_icon.svg";
import OperatingIcon from "assets/images/icons/operating_icon.svg";
import TerminatedIcon from "assets/images/icons/terminated_icon.svg";
import ClockIcon from "assets/images/icons/clock_icon.svg";
import { Box } from "styles/layout";
import { AuthContext } from "context/auth";
import dayjs from "dayjs";
import { media } from "styles/media";
import BusRequest from "http/bus";
const busRequest = new BusRequest();

interface BusServiceStatusProps {}

export const BoxHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 19px;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 20px;
    ${media.tablet} {
        margin-bottom: 30px;
    }
`;

export const BoxBody = styled.div`
    ${Box} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
        :nth-child(2) {
            background-color: ${({ theme }) => theme.colors.green};
            color: ${({ theme }) => theme.colors.white};
            -webkit-box-shadow: 0px 5px 25px 0px rgba(7, 159, 0, 0.4);
            -moz-box-shadow: 0px 5px 25px 0px rgba(7, 159, 0, 0.4);
            box-shadow: 0px 5px 25px 0px rgba(7, 159, 0, 0.4);
        }
        :last-child {
            background-color: #f3f3f3;
            color: #afafaf;
            margin-bottom: 0;
        }
        ${media.tablet} {
            position: relative;
            flex-basis: calc(33.3333333334% - 12px);
            height: 170px;
            padding: 25px 25px 30px;
            align-items: flex-end;
            margin-bottom: 0;
        }
        :last-child {
            box-shadow: none;
        }
    }

    ${media.tablet} {
        display: flex;
        justify-content: space-between;
    }
`;

export const BoxTitle = styled.div`
    display: flex;
    align-items: center;
    h3 {
        margin-left: 6px;
    }
    ${media.tablet} {
        h3 {
            position: absolute;
            top: 25px;
            left: 25px;
            margin-left: 0;
        }
        ${Icon} {
            width: 24px;
            height: 24px;
        }
    }
`;

// const BusServiceLink = styled(Link)`
//     display: flex;
//     font-weight: bold;
//     span {
//         margin-right: 4px;
//     }
// `;

export const Count = styled.div`
    font-size: 16px;
    font-weight: 800;
    ${media.tablet} {
        font-size: 45px;
        margin-bottom: -9px;
    }
`;

const Time = styled.div`
    font-size: 13px;
    font-weight: 800;
    color: ${({ theme }) => theme.colors.orange};
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    span {
        display: inline-flex;
        margin-left: 3px;
        line-height: 14px;
        height: 13px;
    }
    ${media.tablet} {
        position: absolute;
        left: 183px;
    }
`;

interface dataInterface {
    total?: number;
    operating?: number;
    terminated?: number;
}
export const BusServiceStatus: React.FC<BusServiceStatusProps> = () => {
    const { academyId } = useContext(AuthContext);
    const [rawDate, setRawDate] = useState(new Date());
    const today = dayjs(rawDate).format("YYYY-MM-DD");
    const [data, setData] = useState<dataInterface>({
        total: 0, //총 운행 버스
        operating: 0, //운행 중 버스
        terminated: 0 // 운행 완료 버스
    });

    // 1초마다 날짜 갱신
    useEffect(() => {
        const timer = setInterval(() => {
            setRawDate(new Date());
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    // 30초 마다 버스상태값 갱신
    useEffect(() => {
        const getData = async () => {
            await busRequest.getBusStatusCount(
                (response: any) => {
                    if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                        alert(`[${response.data.code}] ${response.data.message}`);
                    }

                    setData(response.data.data);
                },
                (error: any) => {}
            );
        };
        getData();

        const timer = setInterval(getData, 30000);

        return () => {
            clearInterval(timer);
        };
    }, [today, academyId]);

    return (
        <Fragment>
            <Time>
                <Icon img={ClockIcon} width={"15px"} height={"15px"} />
                <span>
                    {today} {rawDate.toLocaleTimeString()}
                </span>
            </Time>
            <BoxHeader>
                <h2>오늘 버스 현황</h2>
                {/*<BusServiceLink to="/busService">*/}
                {/*    <span>버스 운행 관제</span>*/}
                {/*    <Icon img={ArrowRightIcon} width={'6px'} height={'10px'} />*/}
                {/*</BusServiceLink>*/}
            </BoxHeader>
            <BoxBody>
                <Box>
                    <BoxTitle>
                        <Icon img={BusIcon} />
                        <h3>전체 운행</h3>
                    </BoxTitle>
                    <Count>{data?.total}</Count>
                </Box>
                <Box>
                    <BoxTitle>
                        <Icon img={OperatingIcon} />
                        <h3>현재 운행중</h3>
                    </BoxTitle>
                    <Count>{data?.operating}</Count>
                </Box>
                <Box>
                    <BoxTitle>
                        <Icon img={TerminatedIcon} />
                        <h3>운행 완료</h3>
                    </BoxTitle>
                    <Count>{data?.terminated}</Count>
                </Box>
            </BoxBody>
        </Fragment>
    );
};
