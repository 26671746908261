import Request from "./request";

class ParentsRequest extends Request {

    /**
     * 신규 부모 등록  (useRegisterParentMutation)
     * params
     *      name
     *      phone
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    addParent = (data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback
        };
        this.post("/parents", request);
    }

    /**
     * 보호자연락처를 활용한 학부모정보 조회 (useGetParentLazyQuery)
     * params
     *      phone   보호자 연락처
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getParentByPhone = (data, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/parents?keyword=${data}`, request);
    }

    /**
     * 보호자코드를 활용한 학생리스트 조회_학원에등록 안된 학생들임  (useGetStudentsLazyQuery)
     * params
     *      parentCode   보호자 code
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getStudentsByParentCode = (data, callback, errorCallback) => {
        const request = {
            callback,
            errorCallback
        };
        this.get(`/parents/${data}/students`, request);
    }

}

export default ParentsRequest;
