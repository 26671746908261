export class BoardSearchType {
  static readonly ALL = new BoardSearchType("ALL", "전체");
  static readonly TITLE = new BoardSearchType("TITLE", "제목");
  static readonly WRITER = new BoardSearchType("WRITER", "작성자");
  static readonly ALL_TYPES = [BoardSearchType.TITLE, BoardSearchType.WRITER];

  private constructor(readonly value: string, readonly exposure: string) {}

  static parse(value: string): BoardSearchType {
    switch (value) {
      case BoardSearchType.ALL.value:
        return BoardSearchType.ALL;
      case BoardSearchType.TITLE.value:
        return BoardSearchType.TITLE;
      case BoardSearchType.WRITER.value:
        return BoardSearchType.WRITER;
      default:
        throw new Error(`invalid driver search type. (value: ${value})`);
    }
  }
}
