import React, { useContext, useEffect, Fragment, useState } from "react";
import styled from "styled-components";
import { AuthContext } from "context/auth";
import { useTable, Column, useGlobalFilter, useSortBy } from "react-table";
import StudentRequest from "../../http/student";
import { calcStrDateForAPI, formattingHHmm, getWeekdayStr } from "../DateUtil";
import { SavePopUp } from "./HistoryPopUp";
import { ScheduleHistory } from "./ScheduleHistory";
import { SelectedTime } from "./SelectedTime";
import { BusName } from "./BusName";
import { ScheduleServiceContext } from "context/scheduleService";
import { SelectedTime1 } from "./SwitchC";
import dayjs from "dayjs";

interface StudentScheduleListProps {
    weekdaySchedule: boolean;
    studentId: number;
    selectedDate: string;
    editSchedule: boolean;
    setEmpty: any;
    refresh: boolean;
}

const BoxBody = styled.div`
    position: relative;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
`;

const TableBox = styled.div`
    overflow: visible;
    // overflow: auto;
    table {
        width: 100%;
        border-spacing: 0;
        thead {
            tr {
                color: ${({ theme }) => theme.colors.blue};
                font-weight: 800;
                :nth-child(1) {
                    display: none;
                }

                th {
                    position: sticky;
                    background-color: white;
                    top: 0px;
                    box-shadow: 0px 1px 0px 0 rgba(0, 0, 0, 0.1);
                    padding: 10px;
                }
            }
        }
        tr {
            border-bottom: 1px solid #e1e1e1;
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th,
        td {
            max-width: 300px;
            text-align: center;
            margin: 0;
            padding: 20px 10px;
            border-bottom: 1px solid #e1e1e1;
            vertical-align: middle;
            :last-child {
                border-right: 0;
            }
        }

        .short {
            width: 100px;
        }
        .mid {
            min-width: 150px;
            width: 150px;
        }
        .busName {
            min-width: 150px;
            width: 200px;
        }
        .station {
            min-width: 150px;
            width: 250px;
        }
    }
`;

const EmptyDiv = styled.div`
    padding: 40px 0;
    background-color: #fff;
    width: 100%;
    font-family: NanumSquareRound;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    align-items: center;
    text-align: center;
    letter-spacing: -0.03em;
`;

const History = styled.div`
    font-family: NanumSquareRound;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: block;
    align-items: center;
    text-decoration-line: underline;
    cursor: pointer;
`;

interface TableProps {
    columns: Array<Column>;
    data: any;
    getRowProps: any;
}

function Table({ columns, data, getRowProps = () => ({}) }: TableProps) {
    // Use the state and functions returned from useTable to build your UI

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow }: any = useTable(
        {
            columns,
            data
        },

        useGlobalFilter,
        useSortBy
    );

    // Render the UI for your table
    return (
        <Fragment>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup: any) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => {
                                return column.show === undefined || column.show ? (
                                    <th
                                        {...column.getHeaderProps([
                                            column.getSortByToggleProps(),
                                            { className: column.className }
                                        ])}
                                    >
                                        {column.render("Header")}
                                        <span>{column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}</span>
                                    </th>
                                ) : null;
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row: any) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps(getRowProps(row))}>
                                {row.cells.map((cell: any) => {
                                    return cell.column.show === undefined || cell.column.show ? (
                                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    ) : null;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {rows.length === 0 && <EmptyDiv>검색 결과가 없습니다.</EmptyDiv>}
        </Fragment>
    );
}

const studentRequest = new StudentRequest();

interface paramInterface {
    academyId: number;
    date: string;
    studentId: number;
}

export const StudentScheduleList: React.FC<StudentScheduleListProps> = ({
    weekdaySchedule,
    studentId,
    selectedDate,
    editSchedule,
    setEmpty,
    refresh
}) => {
    const { scheduleList, setScheduleList } = useContext(ScheduleServiceContext);
    const { academyId } = useContext(AuthContext);
    const [scheduleId, setScheduleId] = useState(0);
    const [confirmPopUp, setConfirmPopUp] = useState<boolean>(false);

    useEffect(() => {
        setEmpty(true);
        if (selectedDate === "") {
            return;
        }
        let param: paramInterface = {
            date: calcStrDateForAPI(selectedDate),
            studentId: studentId,
            academyId: academyId
        };

        studentRequest.getSchedules(
            weekdaySchedule,
            param,
            studentId,
            (response: any) => {
                if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                    //alert(`[${response.data.code}] ${response.data.message}`);
                } else {
                    setScheduleList(response.data.data);
                    if (response.data.data.length > 0) {
                        setEmpty(false);
                    }
                }
            },
            () => {
                setScheduleList([]);
            }
        );
    }, [academyId, studentId, selectedDate, setEmpty, setScheduleList, refresh, weekdaySchedule]);

    const getScheduleType = (schedule: any) => {
        return schedule.scheduleType + " / " + formattingHHmm(schedule.scheduleTime);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                columns: [
                    {
                        Header: "날짜",
                        accessor: "weekday",
                        className: "short",
                        Cell: (info: any) => {
                            return (
                                <>
                                    {/* <div style={{marginBottom: "6px"}}>{dayjs(selectedDate).format("MM.DD")}</div> */}
                                    <div style={{ marginBottom: "6px" }}>
                                        {dayjs(info.data[info.row.index].scheduleDate).format("MM.DD")}
                                    </div>
                                    <div>{getWeekdayStr(`${info.data[info.row.index].weekday}`)}</div>
                                </>
                            );
                        }
                    },
                    {
                        Header: "타요 / 안타요",
                        accessor: "status",
                        className: "mid",
                        Cell: (info: any) => {
                            return editSchedule ? ( // 수정버튼 눌렀을 때 true
                                <SelectedTime1
                                    editable={true} //
                                    propsStatus={info.data[info.row.index].boardSwitch}
                                    selectDate={calcStrDateForAPI(selectedDate)}
                                    params={info.data[info.row.index]}
                                    row={info.row.index}
                                />
                            ) : (
                                <>
                                    {info.data[info.row.index].boardSwitch === "WILL_BOARD" ? (
                                        <div>타요</div>
                                    ) : (
                                        <div style={{ color: "gray" }}>안타요</div>
                                    )}
                                </>
                            );
                        }
                    },
                    {
                        Header: "구분 / 시간",
                        accessor: "scheduleType",
                        className: "mid",
                        Cell: (info: any) => {
                            return editSchedule ? (
                                <SelectedTime
                                    academyId={academyId}
                                    selectDate={calcStrDateForAPI(selectedDate)}
                                    params={info.data[info.row.index]}
                                    row={info.row.index}
                                    seatFull={false}
                                />
                            ) : (
                                getScheduleType(info.data[info.row.index])
                            ); //formattingHHmm(`${info.data[info.row.index].scheduleTime}`)
                        }
                    },
                    {
                        Header: "첫 정류장 출발 시간",
                        accessor: "startTime",
                        className: "mid",
                        Cell: (info: any) => {
                            return formattingHHmm(`${info.data[info.row.index].startTime}`);
                        }
                    },
                    {
                        Header: "호차",
                        accessor: "busName",
                        className: "busName",
                        Cell: (info: any) => {
                            return editSchedule ? (
                                <BusName data={info.data[info.row.index]} />
                            ) : (
                                `${info.data[info.row.index].busName}`
                            );
                        }
                    },
                    {
                        Header: "승차정류장",
                        accessor: "departureStationName",
                        className: "station"
                    },
                    {
                        Header: "하차정류장",
                        accessor: "arrivalStationName",
                        className: "station"
                    },
                    {
                        Header: "수정내역",
                        accessor: "history",
                        className: "short",
                        show: !editSchedule,
                        Cell: (info: any) => (
                            <>
                                {info.data[info.row.index].hasLogs && ( // 수정위치 - 파라메터 변경
                                    <History
                                        onClick={() => {
                                            console.log("click row");
                                            setScheduleId(info.data[info.row.index].scheduleId);
                                            setConfirmPopUp(true);
                                        }}
                                    >
                                        보기
                                    </History>
                                )}
                            </>
                        )
                    }
                ]
            }
        ],
        [editSchedule, academyId, selectedDate]
    );
    return (
        <Fragment>
            <BoxBody>
                <TableBox>
                    <Table columns={columns} data={scheduleList || []} getRowProps={() => ({})} />
                </TableBox>
            </BoxBody>

            <SavePopUp
                open={confirmPopUp}
                handler={setConfirmPopUp}
                contents={<ScheduleHistory studentId={studentId} scheduleId={scheduleId} handler={setConfirmPopUp} />}
            />
        </Fragment>
    );
};
