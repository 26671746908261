import React from "react";
import styled from "styled-components";
import { CloseButton } from "assets/images";

type PopUpProps = {
    width?: string;
    height?: string;
    handler?: any;
    confirm?: any;
    right?: string;
    data?: string[];
};

const DuplicatePopUp = ({
    width,
    height,
    handler,
    confirm,
    right,
    data,
}: PopUpProps) => {
    return (
        <>
            <Container right={right} style={{ width: width, height: height }}>
                <CancelImg
                    onClick={() => handler(false)}
                    src={CloseButton}
                    alt="cancel"
                />
                <TitleWrapper>
                    <Title>승차권 등록 불가</Title>
                </TitleWrapper>
                <ContentsWrapper>
                    <Contents>
                        이미 동시간대 다른 호차에 승차권이 등록되어 있어 <br />
                        중복 등록은 불가능합니다.
                    </Contents>

                    <ListTitle>
                        <TitleSpan>중복 학생 리스트</TitleSpan>
                    </ListTitle>
                    <StudentList>
                        {data?.map((item: any, idx: number) => {
                            return (
                                <StudentSpan key={idx}>{`${
                                    item.nickName === "" ? "" : item.nickName
                                } ${item.name}(${item.parentPhone.slice(
                                    -4
                                )})`}</StudentSpan>
                            );
                        })}
                    </StudentList>
                </ContentsWrapper>

                <ButtonWrapper>
                    <ApplyButton
                        onClick={() => confirm()}
                        style={{ backgroundColor: "#FFD100" }}
                    >
                        <ButtonSpan>확인</ButtonSpan>
                    </ApplyButton>
                </ButtonWrapper>
            </Container>
        </>
    );
};

export default DuplicatePopUp;

type ContainerProps = {
    right?: string;
};

const Container = styled.div<ContainerProps>`
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    border-radius: 30px;
    background-color: #fff;
    padding: 30px 0px 16px;
    justify-content: center;
    text-align: center;
    top: 50%;
    right: ${(props) => (props.right ? props.right : "-10%")};
    z-index: 120;
    transform: translate3d(-50%, -50%, 0);
`;

const CancelImg = styled.img`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
`;

const TitleWrapper = styled.div`
    margin-bottom: 30px;
`;

const Title = styled.span`
    color: #1e2939;
    text-align: center;
    font-family: NanumSquareRound;
    font-size: 16px;
    font-weight: 800;
    line-height: 18px;
`;

const ContentsWrapper = styled.div`
    margin-bottom: 16px;
    padding: 20px 30px;
    background-color: #f6f8fb;
    justify-content: center;
`;

const Contents = styled.span`
    color: #000;
    text-align: center;
    font-family: NanumSquareRound;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.42px;
    line-height: 18.5px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fff;
`;

const ApplyButton = styled.button`
    width: 98px;
    height: 36px;
    border-radius: 30px;
    background-color: #ffd100;
    border: 1px solid #ffd100;
`;

const ButtonSpan = styled.span`
    color: #000;
    font-size: 14px;
    line-height: 16px;
    font-weight: 800;
    font-family: NanumSquareRound;
    color: #332a00;
    font-weight: 700;
`;

const ListTitle = styled.div`
    border-top: 1px solid rgba(208, 208, 208, 0.6);
    border-bottom: 1px solid rgba(208, 208, 208, 0.6);
    background: #fff;
    justify-content: center;
    padding: 6px auto;
    margin-top: 18px;
    height: 38px;
`;

const TitleSpan = styled.span`
    color: #666;
    text-align: center;
    font-family: NanumSquareRound;
    font-size: 12px;
    font-weight: 400;
    line-height: 38px; /* 216.667% */
    letter-spacing: -0.36px;
`;

const StudentList = styled.div`
    padding: 12px 10px;
    justify-content: center;
    display: flex;
    background: #fff;
    flex-direction: column;
    border-bottom: 1px solid rgba(208, 208, 208, 0.6);
`;

const StudentSpan = styled.span`
    color: #000;
    text-align: center;
    font-family: NanumSquareRound;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px; /* 150% */
    letter-spacing: -0.36px;
`;
