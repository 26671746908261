import React, { useEffect, Fragment, useState } from "react";
import styled from "styled-components";
import { Station } from "./Station";
import { Summary } from "./Summary";
import { media } from "styles/media";
import { Box } from "styles/layout";
import { FullPopUp } from "components/layout/FullPopUp";
import Span from "components/atoms/span";
import RegisterTicket from "./RegisterTicket";
import { PlusIcon, TrashCan } from "assets/images";
import TicketRequest from "http/ticket";
import { HalfPopUp } from "components/layout/HalfPopUp";
import DeleteTicket from "./DeleteTicket";
const ticketRequest = new TicketRequest();

interface BusServiceDetailProps {
    busList?: string[];
    studentId: number | undefined;
    dispatchCode?: string | undefined;
    dispatchName?: string | undefined;
    setCenter?: React.Dispatch<React.SetStateAction<any>>;
    setCourses?: React.Dispatch<React.SetStateAction<any>>;
    seatInfo?: any;
    day?: number;
    dispatchSearch?: any;
    dispatchType?: string;
    detailActive: boolean;
    busEndDate?: string;
}

export const BusServiceDetail: React.FC<BusServiceDetailProps> = ({
    busList,
    studentId,
    dispatchCode,
    dispatchName,
    setCenter,
    setCourses,
    seatInfo,
    day,
    dispatchSearch,
    dispatchType,
    detailActive,
    busEndDate,
}) => {
    const [busService, setBusService] = useState<busServiceInterpace>({});
    const [driver, setDriver] = useState<driverInterface>({});
    const [manager, setManager] = useState<driverInterface>({});
    const [details, setDetails] = useState([]);
    const [registerTicketPopup, setRegisterTicketPopup] = useState(false);
    const [deleteTicketPopup, setDeleteTicketPopup] = useState(false);
    const busType = dispatchName.slice(-2);

    interface managerInterface {
        name?: string;
        phone?: string;
    }

    interface driverInterface {
        name?: string;
        phone?: string;
    }

    interface busServiceInterpace {
        course?: string[];
        dispatchCode?: string;
        drivers?: driverInterface[];
        manager?: managerInterface;
    }

    const getDispatchesDetail = async (dispatchCode: string | undefined) => {
        await ticketRequest.getDispatchesDetail(
            `dispatchCode=${dispatchCode}`,
            (res: any) => {
                setBusService(res.data.data);
                setDriver(res.data.data.drivers[0]);
                setManager(res.data.data.manager);
                setDetails(res.data.data.courses);
                setCourses(res.data.data.courses);
                setCenter({
                    lat: Number(res.data.data.courses[0].station?.lat),
                    lng: Number(res.data.data.courses[0].station?.lng),
                });
            }
        );
    };

    useEffect(() => {
        if (dispatchCode) {
            getDispatchesDetail(dispatchCode);
        }
    }, [dispatchCode]);

    return dispatchCode !== "" && busList?.length > 0 && detailActive ? (
        <Fragment>
            <Detail>
                <Summary
                    dispatchCode={dispatchCode}
                    dispatchName={dispatchName}
                    driver={driver}
                    manager={manager}
                />
                <EnrollDeleteWrapper>
                    <Enroll onClick={(e) => setRegisterTicketPopup(true)}>
                        <PlusImage />
                        <Span
                            style={{
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: 700,
                                lineHeight: "20px",
                            }}
                        >
                            승차권 등록
                        </Span>
                    </Enroll>
                    <Delete onClick={() => setDeleteTicketPopup(true)}>
                        <TrashCanImage />
                        <Span
                            style={{
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: 700,
                                lineHeight: "20px",
                                color: "#ffcd00",
                            }}
                        >
                            삭제
                        </Span>
                    </Delete>
                </EnrollDeleteWrapper>

                <Course>
                    {details.map((detail: any, index) => {
                        return (
                            <Station
                                key={`detail-${index}`}
                                order={index}
                                detail={detail}
                                setCenter={setCenter}
                                studentId={studentId}
                                index={index}
                            ></Station>
                        );
                    })}
                </Course>
            </Detail>
            {registerTicketPopup && (
                <FullPopUp
                    id={registerTicketPopup}
                    handler={setRegisterTicketPopup}
                    contents={
                        <RegisterTicket
                            onClose={setRegisterTicketPopup}
                            details={details}
                            schedules={details}
                            seatInfo={seatInfo}
                            busType={busType}
                            busEndDate={busEndDate}
                            dispatchCode={dispatchCode}
                            dispatchSearch={dispatchSearch}
                            dispatchType={dispatchType}
                            day={day}
                            getDispatchesDetail={getDispatchesDetail}
                            setDetails={setDetails}
                        />
                    }
                />
            )}
            {deleteTicketPopup && (
                <HalfPopUp
                    id={deleteTicketPopup}
                    handler={setDeleteTicketPopup}
                    contents={
                        <DeleteTicket
                            onClose={setDeleteTicketPopup}
                            schedules={details}
                            busType={busType}
                            dispatchCode={dispatchCode}
                            dispatchSearch={dispatchSearch}
                            dispatchType={dispatchType}
                            day={day}
                            getDispatchesDetail={getDispatchesDetail}
                        />
                    }
                />
            )}
        </Fragment>
    ) : (
        <div></div>
    );
};

const Course = styled.ul`
    width: 100%;
    max-height: calc(100vh - 455px);
    overflow: auto;
    padding: 60px 2rem 30px;
    border-radius: 0 0 30px 30px;
    background-color: ${({ theme }) => theme.colors.white};
    ${media.tablet_} {
        max-height: calc(100vh - 455px);
    }
    position: relative;
`;

const Detail = styled(Box)`
    padding: 0 !important;
`;

const EnrollDeleteWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 20px;
    border-bottom: 1px solid #e3e3e3;
    background: #f7f7f7;
`;

const Enroll = styled.div`
    padding: 3px 8px 3px 6px;
    color: #000;
    border: 1px solid #ffd100;
    background-color: #ffd100;
    border-radius: 4px;
    width: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 4px;
    cursor: pointer;
`;

const Delete = styled.div`
    padding: 3px 8px 3px 6px;
    display: flex;
    justify-content: center;
    border: 1px solid #444444;
    background-color: #444444;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
`;

const PlusImage = styled.div`
    background: url(${PlusIcon}) center center no-repeat;
    width: 18px;
    height: 18px;
    margin-right: 2px;
`;

const TrashCanImage = styled.div`
    background: url(${TrashCan}) center center no-repeat;
    width: 18px;
    height: 18px;
    margin-right: 2px;
`;
