import React from "react";
import styled from "styled-components";
import { Box } from "styles/layout";
import CloseButtonIcon from "assets/images/icons/close_button_icon_grey.svg";
import { Icon } from "styles/ui";
import { media } from "styles/media";

interface PopUp2Props {
  open: boolean;
  handler: any;
  contents: any;
  width?: string;
  height?: string;
}

interface ContainerProps {
  open: boolean;
}

const Container = styled.div<ContainerProps>`
  display: ${({ open }) => (open === true ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10002;
  padding: 40px 10px;
`;

const BackgroundLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
`;

type ContentsProps = {
  width: string;
  height: string;
};

const Contents = styled(Box)<ContentsProps>`
  position: absolute;
  max-width: calc(100vw - 20px);
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  padding: 30px;
  max-height: calc(100vh - 79px);
  z-index: 100;
  border-radius: 22px;
  ${media.tablet} {
    width: ${(props) => (props.width ? props.width : "auto")};
    height: ${(props) => (props.height ? props.height : "auto")};
    min-width: 300px;
    max-width: 600px;
    padding: 30px;
  }
`;

const PopUpCloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 18px;
  cursor: pointer;
`;

const PopUpBody = styled.div``;

export const PopUp2: React.FC<PopUp2Props> = ({
  contents,
  open,
  handler,
  width,
  height,
}) => {
  return (
    <Container open={open} className="popup2-container">
      <BackgroundLayer
        className="popup2-background"
        // onClick={() => {
        //     handler(false);
        // }}
      />
      <Contents width={width} height={height}>
        <PopUpCloseButton
          onClick={() => {
            handler(false);
          }}
        >
          <Icon img={CloseButtonIcon} width={"12px"} height={"12px"}></Icon>
        </PopUpCloseButton>
        <PopUpBody>{contents}</PopUpBody>
      </Contents>
    </Container>
  );
};
