import React, {useCallback, useMemo, useState} from "react";
import Select, {toSelectOptions} from "components/atoms/checkSelect/Select";
import TextInput from "components/atoms/input/TextInput";
import {PopUp} from "components/layout/PopUp";
import {BoardSearchType} from "enum/BoardSearchType";
import styled from "styled-components";
import {color, flexRow} from "styles/CommonStyle";
import {media} from "styles/media";
import RegisterBoard from "../registerBoard/RegisterBoard";
import CancelConfirmPopUp from "../popUp/CancelConfirmPopUp";
import {PopUp2} from "components/layout/PopUp2";
import RegisterPopUp from "../popUp/RegisterPopUp";
import {postRequest} from "service/board/Board";

type BoardSearchProps = {
    select: string;
    setSelect: React.Dispatch<React.SetStateAction<string>>;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    setWriter: React.Dispatch<React.SetStateAction<string>>;
    requestCount: number;
    setRequestCount: React.Dispatch<React.SetStateAction<number>>;
};

function BoardSearch(props: BoardSearchProps) {
    const [registerBoardStatus, setRegisterBoardStatus] = useState<boolean>(false);
    const [cancelPopUp, setCancelPopUp] = useState<boolean>(false);
    const [registerPopUp, setRegisterPopUp] = useState<boolean>(false);
    const [newRequest, setNewRequest] = useState<any>({});

    const onChangeText = useCallback(
        (v: string) => {
            if (props.select === "TITLE") {
                props.setTitle(v);
                props.setWriter(null);
            }
            if (props.select === "WRITER") {
                props.setWriter(v);
                props.setTitle(null);
            }
        },
        [props]
    );

    const boardOptions = useMemo(() => {
        return toSelectOptions(BoardSearchType.ALL_TYPES, (t) => t.exposure, false);
    }, []);

    const onChangeSelectValue = (v: BoardSearchType) => {
        props.setSelect(v.value);
    };

    const openCreateRequest = useCallback(() => {
        if (!registerBoardStatus) {
            setRegisterBoardStatus(true);
        }
    }, [registerBoardStatus]);

    const hideRegisterPopUp = useCallback(() => {
        setRegisterBoardStatus(false);
        setCancelPopUp(false);
    }, []);

    const createRequest = useCallback(() => {
        postRequest(newRequest)
            .then((res) => {
                console.log(res);
                if (res.status === -1) {
                    alert("요청 등록에 실패했습니다.");
                    console.log(`${res.error.message}`);
                } else {
                    setRegisterBoardStatus(false);
                    setRegisterPopUp(false);
                    props.setRequestCount(props.requestCount + 1); //for reloading request list
                }
            })
            .catch((error) => {
                throw new Error(`Create Request failed. error : ${error}`);
            });
    }, [newRequest, props]);

    const updateNewRequestData = (newRequestData: any): void => {
        setNewRequest(newRequestData);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            const inputElement = event.currentTarget as HTMLInputElement;
            onChangeText(inputElement.value);
        }
    };

    return (
        <Container>
            <SelectSearchContainer>
                <Select
                    options={boardOptions}
                    onChange={onChangeSelectValue}
                    width={110}
                    height={46}
                    defaultIdx={0}
                    fontSize={12}
                    boxShadow={false}
                    border={false}
                    borderRadius={60}
                    status={props.select === "TITLE" ? "제목" : "작성자"}
                />
                <Line />
                <StyledTextInput
                    onKeyDown={handleKeyDown}
                    onChange={(event) => {}}
                    readOnly={false}
                    required={false}
                    placeholder={props.select === BoardSearchType.TITLE.value ? "제목을 입력해 주세요." : "이름을 입력해 주세요."}
                />
            </SelectSearchContainer>
            <WriteButton onClick={openCreateRequest}>글쓰기</WriteButton>
            {registerBoardStatus && (
                <PopUp
                    id={registerBoardStatus}
                    title={"게시물등록"}
                    handler={setRegisterBoardStatus}
                    contents={<RegisterBoard onClose={() => setCancelPopUp(true)} handleRegister={() => setRegisterPopUp(true)} updateNewRequestData={updateNewRequestData} />}
                />
            )}
            {cancelPopUp && (
                <PopUp2
                    open={cancelPopUp}
                    handler={setCancelPopUp}
                    contents={<CancelConfirmPopUp onClose={() => setCancelPopUp(false)} handleConfirm={hideRegisterPopUp} />}
                    width="360px"
                    height="200px"
                />
            )}
            {registerPopUp && (
                <PopUp2
                    open={registerPopUp}
                    handler={setRegisterPopUp}
                    contents={<RegisterPopUp onClose={() => setRegisterPopUp(false)} createRequest={createRequest} />}
                    width="360px"
                    height="200px"
                />
            )}
        </Container>
    );
}

export default BoardSearch;

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
    width: 100%;

    ${media.tablet} {
        justify-content: space-between;
        padding: 24px;
    }
`;

const StyledTextInput = styled(TextInput)`
    width: calc(100vw - 150px);
    margin-right: 10px;
    cursor: pointer;

    ${media.tablet} {
        margin-right: 0;
        width: 380px;
    }
`;

const WriteButton = styled.button`
    border: none;
    min-width: 85px;
    max-width: 85px;
    height: 40px;
    background: #ffcd00;
    border-radius: 30px;
    padding: 12px 23px;
    font-size: 14px;
    font-weight: 800;
    font-family: NanumSquareRound;
    position: absolute;
    top: -50px;
    right: 0;

    ${media.tablet} {
        right: 24px;
        top: 30px;
    }
`;

const SelectSearchContainer = styled.div`
    ${flexRow};
    align-items: center;
    max-height: 57px;
    border-radius: 60px;
    border: 0.1rem solid #ebebeb;
    background-color: ${color.white};

    :focus-within {
        border-color: #ffe880;
        background: #fffbe5;
        border-radius: 60px;

        input,
        div {
            background: #fffbe5;
            border-radius: 60px;
            outline: none;
        }
    }
`;

const Line = styled.span`
    width: 0.1rem;
    height: 2rem;
    background: #d9d9d9;
    margin-right: 0.4rem;
`;
