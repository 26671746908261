import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { RouteComponentProps } from "react-router-dom";
import { AuthContext } from "context/auth";
import { GlobalContext } from "context/global";
import { Container, PageTitle, Box } from "styles/layout";
import { AcademyInfo } from "components/profile/AcademyInfo";
import { UserAccount } from "components/profile/UserAccount";
import { ProfileContext } from "context/profile";
import { UserInfo } from "components/profile/UserInfo";
import { media } from "styles/media";

const Contents = styled.div``;

const BoxCommon = css`
    margin-bottom: 20px;
`;

const AcademyInfoBox = styled(Box)`
    ${BoxCommon}
`;

const BoxGroup = styled.div`
    ${media.tablet} {
        display: flex;
        justify-content: space-between;
    }
`;

const UserAccountBox = styled.div`
    ${BoxCommon}
    ${media.tablet} {
        flex-basis: calc(50% - 10px);
    }
`;

const UserInfoBox = styled.div`
    ${media.tablet} {
        flex-basis: calc(50% - 10px);
    }
`;

export const Profile: React.FC<RouteComponentProps> = ({ history }) => {
    const [updateUserAccount, setUpdateUserAccount] = useState(false);
    const [updateUserInfo, setUpdateUserInfo] = useState(false);

    const { navToggle, setCurrentLocation } = useContext(GlobalContext);
    useEffect(() => {
        // document.title = `학원  - 옐로우버스`;
        setCurrentLocation("academy");
    }, [setCurrentLocation]);

    const { loggedIn } = useContext(AuthContext);
    if (!loggedIn) {
        history.push("/login");
    }

    return (
        <ProfileContext.Provider
            value={{
                updateUserAccount,
                setUpdateUserAccount,
                updateUserInfo,
                setUpdateUserInfo
            }}
        >
            <Container navToggle={navToggle}>
                <Contents>
                    <PageTitle>학원 프로필</PageTitle>
                    <AcademyInfoBox>
                        <AcademyInfo />
                    </AcademyInfoBox>
                    <BoxGroup>
                        <UserAccountBox>
                            <UserAccount />
                        </UserAccountBox>
                        <UserInfoBox>
                            <UserInfo />
                        </UserInfoBox>
                    </BoxGroup>
                </Contents>
            </Container>
        </ProfileContext.Provider>
    );
};
