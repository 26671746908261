import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { AuthContext } from "context/auth";
import { BusServiceList } from "components/busService/BusServiceList";
import { BusServiceDetail } from "components/busService/BusServiceDetail";
import { BusServiceContext } from "context/busService";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import dayjs from "dayjs";
import { BusServiceMap } from "components/busService/BusServiceMap";
import { GlobalContext } from "context/global";
import { Container, PageTitle } from "styles/layout";
import { media } from "styles/media";
import { BackButton } from "styles/ui";
import CalendarIcon from "assets/images/icons/calendar_icon.svg";
import BackIcon from "assets/images/icons/back_icon.svg";
import { AcademyBusList } from "../components/busService/AcademyBusList";
import ko from "date-fns/locale/ko";
import "../styles/datepicker.css";

registerLocale("ko", ko);

const Contents = styled.div`
    width: 100%;
`;

const DatePickerDiv = styled.div`
    display: flex;
    position: absolute;
    top: 24px;
    right: 24px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    padding-left: 34px;
    padding: 8px 0 8px 34px;
    background: url(${CalendarIcon}) left 10px center no-repeat;
    input {
        border: none;
        font-size: 13px;
        font-weight: 600;
        width: 120px;
        height: 30px;
        padding: 0 10px;
        height: 14px;
    }
    ${media.desktop} {
        right: 24px;
    }
`;
const Line = styled.div`
    height: 14px;
    width: 1px;
    background-color: rgb(225, 225, 225);
`;

const List = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    ${media.desktop} {
        flex-wrap: nowrap;
    }
`;

interface LiProps {
    active?: boolean | undefined;
}

const Li = styled.li<LiProps>`
    display: inline-block;
    width: 100%;
    position: relative;
    flex-basis: 100%;
    display: ${({ active }) => (active ? "block" : "none")};
    :last-child {
        display: none;
    }
    ${media.tablet} {
        display: block;
        flex-basis: calc(50% - 10px);
        :last-child {
            display: none;
        }
    }
    ${media.desktop},
    ${media.fulldesktop},
    ${media.extrafulldesktop} {
        flex-basis: calc(33.3333333334% - 10px);
        max-width: 453px;
        margin-right: 20px;
        :last-child {
            display: block;
            flex: 1;
            max-width: 3000px;
        }
    }
`;

interface BackStepButtonProps {
    active?: boolean | undefined;
}

const BackStepButton = styled(BackButton)<BackStepButtonProps>`
    margin-right: 10px;
    display: ${({ active }) => (active ? "block" : "none")};
    ${media.tablet} {
        display: none;
    }
`;

interface BusServiceProps {
    studentId?: number | any;
}

export const BusService: React.FC<BusServiceProps> = ({ studentId }) => {
    const { navToggle, setCurrentLocation } = useContext(GlobalContext);
    const history = useHistory();

    useEffect(() => {
        // document.title = `버스 운행 관제 - 옐로우버스`;
        const location = window.location.href.split("/");
        if (location[location.length - 1] === "busService") {
            setCurrentLocation("busService");
        }
    });

    const { loggedIn, academyId } = useContext(AuthContext);
    if (!loggedIn) {
        history.push("/login");
    }

    const [rawDate, setRawDate] = useState(new Date());
    const date = dayjs(rawDate).format("YYYY-MM-DD");

    const [status, setStatus] = useState();
    const [busServiceId, setBusServiceId] = useState(null);
    const [dispatchCode, setDispatchCode] = useState("");
    const [courseCode, setCourseCode] = useState();
    const [center, setCenter] = useState({
        lat: 37.42002671,
        lng: 127.13061966,
    });
    const [schedules, setSchedules] = useState();
    const [layerMap, setLayerMap] = useState(false);
    const [stepActive, setStepActive] = useState({
        list: true,
        detail: false,
    });
    const [reloadTrigger, setReloadTrigger] = useState(0);
    const [selectedDispatchData, setSelectedDispatchData] = useState();
    const [editable, setEditable] = useState<boolean>();
    const [scheduleEdit, setScheduleEdit] = useState<boolean>(false);

    return (
        <BusServiceContext.Provider
            value={{
                status,
                setStatus,
                academyId,
                date,
                busServiceId,
                setBusServiceId,
                dispatchCode,
                setDispatchCode,
                courseCode,
                setCourseCode,
                center,
                setCenter,
                schedules,
                setSchedules,
                layerMap,
                setLayerMap,
                setStepActive,
                reloadTrigger,
                setReloadTrigger,
                selectedDispatchData,
                setSelectedDispatchData,
                editable,
                setEditable,
                scheduleEdit,
                setScheduleEdit,
            }}
        >
            <Container navToggle={navToggle} className="Container">
                <Contents>
                    <PageTitle className="title">
                        <BackStepButton
                            img={BackIcon}
                            width={"28px"}
                            height={"28px"}
                            onClick={() => {
                                setStepActive({
                                    list: true,
                                    detail: false,
                                });
                            }}
                            active={stepActive.detail}
                        />
                        버스 운행 관제
                    </PageTitle>
                    <List>
                        <Li active={stepActive.list}>
                            <DatePickerDiv>
                                <Line />
                                <DatePicker
                                    selected={rawDate}
                                    onChange={(rawDate: Date) => {
                                        setRawDate(rawDate);
                                        // setBusServiceId(null);
                                    }}
                                    locale={"ko"}
                                    dateFormat="yyyy-MM-dd (EEE)"
                                    placeholderText="운행일을 선택하세요"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </DatePickerDiv>
                            {/*학생 id가 있을 경우 학생 상세 정보에서 운행 표기 없을 경우 버스 운행 관제 리스트 표기*/}
                            {Number(studentId) ? (
                                <BusServiceList studentId={Number(studentId)} />
                            ) : (
                                <AcademyBusList />
                            )}
                        </Li>
                        <Li active={stepActive.detail}>
                            <BusServiceDetail studentId={Number(studentId)} />
                        </Li>
                        <Li>
                            <BusServiceMap studentId={Number(studentId)} />
                        </Li>
                    </List>
                </Contents>
            </Container>
        </BusServiceContext.Provider>
    );
};
