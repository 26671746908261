import React, {
  ChangeEvent,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import styled from "styled-components";
import {
  isEmptyString,
  isNil,
  orElse,
} from "../../../components/ValidataionUtil";
import Search from "../../../assets/images/icons/search_icon_yellow.svg";
import Reset from "../../asset/image/close_yellow.svg";
import SVGImage from "components/common/SVGImage";
import { media } from "styles/media";

type TextInputProps = {
  readOnly: boolean;
  required: boolean;
  default?: string;
  containerStyle?: CSSProperties;
  resetOff?: boolean;
  onChange?(value: string): void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">;
export type TextInputRef = {
  getValue(): string;
  setValue(t: string): void;
  reset(): void;
};

function TextInputBase(
  props: TextInputProps,
  ref: ForwardedRef<TextInputRef>
): ReactElement {
  const [value, setValue] = useState<string>(orElse(props.default, ""));
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value;
      setValue(v);

      if (!isNil(props.onChange)) {
        props.onChange(v);
      }
    },
    [props.onChange, setValue]
  );

  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const onReset = useCallback(() => {
    setValue("");
    if (!isNil(props.onChange)) {
      props.onChange("");
    }
  }, [setValue, props.onChange]);

  useImperativeHandle(
    ref,
    () => ({
      getValue(): string {
        return value;
      },
      setValue(t: string): void {
        setValue(t);
      },
      reset(): void {
        setValue("");
      },
    }),
    [value, setValue]
  );
  return (
    <Container style={props.containerStyle}>
      <Input
        {...props}
        contentEditable={!props.readOnly}
        value={value}
        onChange={onChange}
        resetOff={props.resetOff}
        className={props.className}
        onFocus={onFocus}
        onBlur={onBlur}
        isFocused={isFocused}
      />
      <SearchImg source={Search} />
    </Container>
  );
}

const TextInput = forwardRef(TextInputBase);
export default TextInput;

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const ResetButton = styled.button<{ visible: boolean }>`
  display: ${(props) => (props.visible ? "block" : "none")};
  border: none;
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  right: 1rem;
`;

const Input = styled.input<{
  value: string;
  resetOff: boolean;
  isFocused: boolean;
}>`
  font-family: NanumSquareRound;
  width: 100%;
  height: 3rem;
  border: none;
  /* border: ${(props) =>
    props.isFocused ? "1px solid #FFD100" : "1px solid #dfdfdf"}; */
  padding-left: 16px;
  padding-right: ${(props) => (props.resetOff ? 0 : "30px")};
  border-radius: 6rem;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  background: ${(props) =>
    props.readOnly ? "#f5f5f5" : props.isFocused ? "#FFFBE5" : "#ffffff"};
  cursor: ${(props) => (props.readOnly ? "not-allowed" : "default")};
  color: ${(props) => (props.readOnly ? "#999999" : "#000000")};
  /* box-shadow: ${(props) =>
    props.isFocused ? "4px 0px 16px 0px rgba(0, 0, 0, 0.15)" : null}; */

  &::placeholder {
    color: #a7a9ac;
    font-size: 12px;
    font-weight: 400;
  }

  :focus-within {
    /* box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15); */
    /* border: 0.1rem solid #fff1b3; */
    background: #fffbe5;
  }
`;

const SearchImg = styled(SVGImage)`
  width: 22px;
  height: 22px;
  position: absolute;
  right: 25px;
  ${media.tablet} {
    right: 16px;
  }
`;
