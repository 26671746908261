import Moment from "moment";

export function weekdayOfFirst() {
    let tmpDate = Moment();
    let beginningOfCurrentWeek = tmpDate.isoWeekday() - 1;
    return tmpDate.add(-beginningOfCurrentWeek, "days").format("YYYY.MM.DD");
}

export function checkWeekday(startDate: string, endDate: string) {
    //console.log('startDate : '  + endDate)
    let tmpDate = Moment(endDate, "YYYY.MM.DD");
    let tmpStartDate = tmpDate.add(-6, "days").format("YYYY.MM.DD");
    return startDate !== tmpStartDate;
}

export function weekdayOfFirstFrom(str: string) {
    //console.log('startDate : '  + str)
    let tmpDate = Moment(str, "YYYY.MM.DD");
    return tmpDate.add(-6, "days").format("YYYY.MM.DD");
}

export function weekdayOfLast() {
    let tmpDate = Moment();
    let beginningOfCurrentWeek = tmpDate.isoWeekday() - 1;
    return tmpDate.add(-beginningOfCurrentWeek + 6, "days").format("YYYY.MM.DD");
}

export function calcWeekday(str: string, isAdd: boolean) {
    //console.log('startDate : '  + str)
    let tmpDate = Moment(str, "YYYY-MM-DD");
    if (isAdd) {
        return tmpDate.add(7, "days").format("YYYY.MM.DD");
    } else {
        return tmpDate.add(-7, "days").format("YYYY.MM.DD");
    }
}

export function calcStrDateForAPI(str: string) {
    //console.log('startDate : '  + str)
    let tmpDate = Moment(str, "YYYY-MM-DD");
    return tmpDate.format("YYYY-MM-DD");
}

export function formattingHHmm(str: string) {
    //console.log('startDate : '  + str)
    let tmpDate = Moment(str, "HH:mm:ss");
    return tmpDate.format("HH:mm");
}

export function getWeekdayStr(week: string) {
    switch (week) {
        case "2":
            return "월요일";
        case "3":
            return "화요일";
        case "4":
            return "수요일";
        case "5":
            return "목요일";
        case "6":
            return "금요일";
        case "7":
            return "토요일";
        default:
            return "일요일";
    }
}

export function compareToday(str: string) {
    let today = Moment();
    let tmpDate = Moment(str, "YYYY.MM.DD").add(1, "days");
    return today.isBefore(tmpDate);
}

export function compareTodayAfter(str: string) {
    let today = Moment();
    let tmpDate = Moment(str, "YYYY-MM-DD");
    return today.isBefore(tmpDate);
}

export function compareCurrentTime(day: string, time: string, compareDate: string) {
    let selectDay = Moment(day, "YYYY-MM-DD HH:mm:ss");
    let today = Moment();
    let todayTime = Moment(today, "HH:mm");
    let compareTime = Moment(compareDate, "HH:mm").add(-30, "minutes");

    if (selectDay.isAfter(today)) {
        return false;
    } else {
        return compareTime.isBefore(todayTime);
    }
}

export function formattingData(str: string) {
    let tmpDate = Moment(str, "YYYY-MM-DD[T]HH:mm:ss");
    return tmpDate.format("YYYY-MM-DD HH:mm:ss");
}

export function compareCurrentTimeBefore30m(time: string, compareDate: string) {
    let today = Moment();
    let todayTime = Moment(today, "HH:mm");
    let compareTime = Moment(compareDate, "HH:mm");
    let tmpTime = Moment(time, "HH:mm").add(-30, "minutes");

    if (todayTime.isBefore(compareTime)) {
        return tmpTime.isBefore(todayTime);
    } else {
        return tmpTime.isBefore(compareTime);
    }
}
