import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import _ from "lodash";
import ToolTipVector from "assets/images/icons/tooltip_box_vector.svg";

interface OnBoardProps {
    stationId: number;
    studentId: number | undefined;
    detail?: any;
}

export const OnBoard: React.FC<OnBoardProps> = ({
    stationId,
    studentId,
    detail,
}) => {
    const [showPeriod, setShowPeriod] = useState(false);
    const [studentName, setStudentName] = useState<string>("");
    let onBoardCounts = 0;

    if (_.isEmpty(detail) === false) {
        detail.map((student: any) => {
            if (stationId === Number(student?.pickUpStationId)) {
                onBoardCounts = onBoardCounts + 1;
            }
            return null;
        });
    }

    const hoverStudentName = (name: string) => {
        setShowPeriod(true);
        setStudentName(name);
    };

    const leaveStudentName = () => {
        setShowPeriod(false);
        setStudentName("");
    };

    return onBoardCounts > 0 ? (
        <BoardBox>
            <BoardTitle>
                <span>승차</span>
            </BoardTitle>
            <StudentList>
                {detail?.map((student: any, index: any) => {
                    if (stationId === Number(student?.pickUpStationId)) {
                        return (
                            <div key={student.id}>
                                <Student
                                    thisStudentId={student?.id}
                                    studentId={studentId}
                                    onMouseEnter={() =>
                                        hoverStudentName(student.name)
                                    }
                                    onMouseLeave={leaveStudentName}
                                >
                                    <Name
                                        to={`/students/${student?.id}`}
                                        title={"학생 페이지로 이동"}
                                        target={"blank"}
                                    >
                                        {student?.nickName === "" ||
                                        student?.nickName === "-"
                                            ? ""
                                            : `${student?.nickName} / `}
                                        {`${
                                            student?.name
                                        }(${student.parentPhone.slice(-4)})`}
                                    </Name>
                                    <div style={{ position: "relative" }}>
                                        {showPeriod &&
                                            studentName === student?.name && (
                                                <>
                                                    <ToolTipBoxVector />
                                                    <ToolTip>
                                                        <PeriodSpan>
                                                            승차 일자 :
                                                        </PeriodSpan>
                                                        <br />
                                                        {student?.tickets?.map(
                                                            (
                                                                item: any,
                                                                idx: number
                                                            ) => {
                                                                return (
                                                                    <PeriodSpan
                                                                        key={
                                                                            idx
                                                                        }
                                                                    >
                                                                        {item}
                                                                    </PeriodSpan>
                                                                );
                                                            }
                                                        )}
                                                    </ToolTip>
                                                </>
                                            )}
                                    </div>

                                    {/* B2C학생 생길 경우 활성화 필요 */}
                                    {/* {schedule.isB2CUser ? (
                                                    <p>
                                                        {showNickName
                                                            ? schedule.student?.nickName === ""
                                                                ? `${schedule.student?.name}(B2C)`
                                                                : `${schedule.student?.name} - ${schedule.student?.nickName}(B2C)`
                                                            : `${schedule.student?.name}(B2C)`}
                                                    </p>
                                                ) : (
                                     )} */}
                                </Student>
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
            </StudentList>
        </BoardBox>
    ) : null;
};

export const BoardBox = styled.div`
    display: flex;
`;

export const Name = styled(Link)`
    line-height: 18px;
    font-size: 12px;
    font-weight: 700;
`;

export const BoardTitle = styled.div`
    margin-right: 12px;
    min-width: 36px;
    max-width: 36px;
    height: 18px;
    padding: 4px 7px 4px 9px;
    background-color: ${({ theme }) => theme.colors.green};
    border-radius: 30px;
    display: flex;
    align-items: center;
    span {
        min-width: 20px;
        line-height: 18px;
        display: inline-flex;
        color: #fff;
        font-weight: 800;
        font-size: 10px;
    }
`;
export const StudentList = styled.ul`
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
`;

export const DisabledEditStudent = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 6px;
    height: 30px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(208, 208, 208, 0.6);
    border-radius: 4px;
    font-size: 12px;
    background-color: #e7e7e7;
    opacity: 0.6;
    span {
        padding: 7px 5px 5px 5px;
        font-family: NanumSquareRound;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;
    }
`;

const ToolTip = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    flex-direction: column;
    position: absolute;
    width: 163px;
    padding: 12px;
    border-radius: 4px;
    background: #333;
    top: 8px;
    left: -35px;
    z-index: 9;
`;

const ToolTipBoxVector = styled.div`
    position: absolute;
    top: 3px;
    left: 40px;
    width: 8px;
    height: 5px;
    background-image: url(${ToolTipVector});
`;

const PeriodSpan = styled.span`
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
`;

interface StudentProps {
    thisStudentId: number | undefined;
    studentId: number | undefined;
}

export const Student = styled.li<StudentProps>`
    line-height: 18px;
    margin-bottom: 6px;

    ${({ studentId, thisStudentId }) =>
        studentId &&
        Number(thisStudentId) !== studentId &&
        css`
            color: #dedede;
            a,
            span {
                color: #dedede !important;
            }
        `};
    p {
        display: block;
        line-height: 1.8;
    }
`;

export const NoShow = styled.span`
    color: ${({ theme }) => theme.colors.orange};
    margin-left: 4px;
`;
