/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";
import { media } from "styles/media";

// images
import SearchIcon from "components/atoms/icons/search.svg";
import XButton from "components/atoms/icons/xButton.svg";

type SearchInputProps = {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClickClear?: () => void;
};
const SearchInput = ({ value = "", onChange = () => {}, onClickClear = () => {} }: SearchInputProps) => {
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [isFocus, setIsFocus] = useState(false);

    const handleFocus = () => setIsFocus((prev: boolean) => !prev);

    const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            searchInputRef.current?.blur();
        }
    };

    return (
        <SearchInputWrap>
            <SearchIconWrap>
                <img src={SearchIcon} />
            </SearchIconWrap>

            <MasterDashboardSearchInput
                ref={searchInputRef}
                value={value}
                isFocus={isFocus}
                placeholder={"학원 검색"}
                onKeyPress={handleOnKeyPress}
                onFocus={handleFocus}
                onBlur={handleFocus}
                onChange={onChange}
            />
            {value && (
                <SearchClearIconWrap>
                    <img
                        src={XButton}
                        onClick={() => {
                            searchInputRef.current?.focus();
                            onClickClear();
                        }}
                    />
                </SearchClearIconWrap>
            )}
        </SearchInputWrap>
    );
};
export default SearchInput;

type MasterDashboardSearchInputType = {
    isFocus: boolean;
};
const MasterDashboardSearchInput = styled.input<MasterDashboardSearchInputType>`
    ${media.tablet} {
        padding: 6px 6px 6px 36px;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        box-shadow: none;

        ${({ isFocus }) =>
            isFocus &&
            css`
                border: 1px solid #ffd100;
                background-color: #fffae5;
                box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
            `}

        // value가 있는 noFocus 상태
        ${({ value, isFocus }) =>
            value &&
            isFocus === false &&
            css`
                background-color: #fffae5;
            `}
    }

    width: 100%;
    height: 100%;
    padding: 12px 20px 12px 50px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);

    outline: none;
    border: none;
    border-radius: 120px;

    ${({ isFocus }) =>
        isFocus &&
        css`
            border: 1px solid #ffd100;
        `}

    ::placeholder {
        ${media.tablet} {
            font-size: 13px;
        }
        font-size: 12px;
        font-weight: 700;
        color: #cccccc;
    }
`;
const SearchInputWrap = styled.div`
    ${media.tablet} {
        z-index: 10000;

        width: auto;
        max-width: 432px;
        height: 32px;
    }

    position: relative;
    height: 42px;
`;
const SearchIconWrap = styled.div`
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);

    display: flex;
`;
const SearchClearIconWrap = styled.div`
    ${media.tablet} {
        right: 8px;
    }
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);

    display: flex;

    cursor: pointer;
`;
