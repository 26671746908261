import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import _ from "lodash";
import ToolTipVector from "assets/images/icons/tooltip_box_vector.svg";
import { DeleteButton, DragIcon, PlusIcon } from "assets/images";
import { StudentList } from "./Modal/StudentList";
import { StudentPopUp } from "components/layout/StudentPopUp";
import { Draggable } from "react-beautiful-dnd";

type OverBookingDataProps = {
    year?: string;
    seatOccupancyRates?: any[];
};

interface OnBoardProps {
    stationId: number;
    schedules: any;
    studentId: number | undefined;
    isFirst?: any;
    isLast?: boolean;
    busType?: string;
    enrolledStudentList?: any;
    takeOffStationId?: number | string;
    overBookingWarning?: boolean;
    setOverBookingWarning?: React.Dispatch<React.SetStateAction<any>>;
    setDuplicateWarning?: React.Dispatch<React.SetStateAction<any>>;
    edit?: any;
    setEdit?: React.Dispatch<React.SetStateAction<any>>;
    overBookingData?: OverBookingDataProps[];
    duplicateData?: string[];
    detailsIds?: number[];
}

interface StudentProps {
    thisStudentId: number | undefined;
    studentId: number | undefined;
}

export const EditOnBoard: React.FC<OnBoardProps> = ({
    stationId,
    schedules,
    studentId,
    isFirst,
    isLast,
    busType,
    enrolledStudentList,
    takeOffStationId,
    overBookingWarning,
    edit,
    overBookingData,
    duplicateData,
    setEdit,
    setOverBookingWarning,
    setDuplicateWarning,
    detailsIds,
}) => {
    const [showPeriod, setShowPeriod] = useState(false);
    const [studentName, setStudentName] = useState<string>("");
    const [showModal, setShowModal] = useState(false);
    let onBoardCounts = 0;

    if (_.isEmpty(schedules) === false) {
        schedules.map((schedule: any) => {
            if (stationId === Number(schedule.pickUpStationId)) {
                onBoardCounts = onBoardCounts + 1;
            }
            return null;
        });
    }

    const hoverStudentName = (name: string) => {
        setShowPeriod(true);
        setStudentName(name);
    };

    const leaveStudentName = () => {
        setShowPeriod(false);
        setStudentName("");
    };

    const showStudentList = () => {
        setShowModal(true);
    };

    const keyToExtract = "id";
    let duplicateId = duplicateData?.map((item) => item[keyToExtract]);

    const handleDeleteStudent = (id: number) => {
        setEdit(edit.filter((item: any) => item?.id !== id));
    };

    useEffect(() => {
        for (const obj of overBookingData) {
            for (const item of obj?.seatOccupancyRates) {
                if (
                    enrolledStudentList?.length + edit?.length <=
                    item?.totalSeat
                ) {
                    setOverBookingWarning(false);
                }
            }
        }
    }, [edit]);

    useEffect(() => {
        const warningCnt = edit
            .filter((item: any) => duplicateId.includes(item.id))
            .map((item: any) => item.id);

        if (warningCnt?.length === 0) {
            setDuplicateWarning(false);
        }
    }, [duplicateId]);

    useEffect(() => {
        const filteredEdit = edit.filter(
            (item: any) => !detailsIds.includes(item.id)
        );
        setEdit(filteredEdit);
    }, [detailsIds]);

    return (
        <>
            {/* 하원 */}
            {busType === "하원" && isFirst && edit?.length > 0 && (
                <ButtonWrapper border={schedules?.length}>
                    <EditBoardBox>
                        <BoardTitle style={{ top: "6px" }}>
                            <span>승차</span>
                        </BoardTitle>
                        <EditStudentContainer>
                            {edit?.map((item: any) => {
                                return (
                                    <EditStudentWrapper
                                        key={item?.id}
                                        style={{
                                            position: "relative",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <MoveImg
                                                src={DragIcon}
                                                alt="drag"
                                            />
                                            <EditStudentSpan>
                                                {item?.nickName === "" ||
                                                item?.nickName === "-"
                                                    ? ""
                                                    : `${item?.nickName} / `}
                                                {`${
                                                    item?.name
                                                }(${item?.parentPhone.slice(
                                                    -4
                                                )})`}
                                            </EditStudentSpan>
                                        </div>
                                        <DeleteImg
                                            src={DeleteButton}
                                            alt="delete"
                                        />
                                        <Block />
                                    </EditStudentWrapper>
                                );
                            })}
                        </EditStudentContainer>
                    </EditBoardBox>
                </ButtonWrapper>
            )}
            {/* 등원 */}
            {busType === "등원" && !isLast && (
                <ButtonWrapper border={schedules?.length}>
                    {edit?.filter((item) => item.pickUpStationId === stationId)
                        ?.length > 0 && (
                        <EditBoardBox>
                            <BoardTitle style={{ top: "6px" }}>
                                <span>승차</span>
                            </BoardTitle>
                            <EditStudentContainer>
                                {edit?.map((editStudent: any, idx: number) => {
                                    if (
                                        stationId ===
                                        Number(editStudent?.pickUpStationId)
                                    ) {
                                        return (
                                            <Draggable
                                                key={
                                                    "inbound-draggable-" +
                                                    editStudent.id
                                                }
                                                draggableId={
                                                    "inbound-" + editStudent.id
                                                }
                                                data-studentId={editStudent.id}
                                                index={idx}
                                            >
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.dragHandleProps}
                                                        {...provided.draggableProps}
                                                    >
                                                        <EditStudentWrapper
                                                            key={`detail - ${editStudent?.id}`}
                                                            overBookingWarning={
                                                                overBookingWarning
                                                            }
                                                            duplicateId={
                                                                duplicateId
                                                            }
                                                            studentId={
                                                                editStudent?.id
                                                            }
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                <MoveImg
                                                                    src={
                                                                        DragIcon
                                                                    }
                                                                    alt="drag"
                                                                />
                                                                <EditStudentSpan>
                                                                    {editStudent?.nickName ===
                                                                        "" ||
                                                                    editStudent?.nickName ===
                                                                        "-"
                                                                        ? ""
                                                                        : `${editStudent?.nickName} / `}
                                                                    {`${
                                                                        editStudent?.name
                                                                    }(${editStudent?.parentPhone.slice(
                                                                        -4
                                                                    )})`}
                                                                </EditStudentSpan>
                                                            </div>
                                                            <DeleteImg
                                                                src={
                                                                    DeleteButton
                                                                }
                                                                alt="delete"
                                                                onClick={() =>
                                                                    handleDeleteStudent(
                                                                        editStudent?.id
                                                                    )
                                                                }
                                                            />
                                                        </EditStudentWrapper>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    }
                                })}
                            </EditStudentContainer>
                        </EditBoardBox>
                    )}
                    <AddButton onClick={() => showStudentList()}>
                        <AddImg />
                        <AddText>학생 추가</AddText>
                    </AddButton>
                </ButtonWrapper>
            )}

            {onBoardCounts > 0 && (
                <BoardBox edit={edit} busType={busType}>
                    <BoardTitle>
                        <span>승차</span>
                    </BoardTitle>
                    <StudentWrapper>
                        {schedules?.map((student: any, index: any) => {
                            if (
                                stationId === Number(student?.pickUpStationId)
                            ) {
                                return (
                                    <div key={student?.id}>
                                        <Student
                                            thisStudentId={student?.id}
                                            studentId={studentId}
                                            onMouseEnter={() =>
                                                hoverStudentName(student?.name)
                                            }
                                            onMouseLeave={leaveStudentName}
                                        >
                                            <Name
                                                to={`/students/${student?.id}`}
                                                title={"학생 페이지로 이동"}
                                                target={"blank"}
                                            >
                                                {student?.nickName === "" ||
                                                student?.nickName === "-"
                                                    ? ""
                                                    : `${student?.nickName} / `}
                                                {`${
                                                    student?.name
                                                }(${student.parentPhone.slice(
                                                    -4
                                                )})`}
                                            </Name>

                                            <ToolTipWrapper>
                                                {showPeriod &&
                                                    studentName ===
                                                        student?.name && (
                                                        <>
                                                            <ToolTipBoxVector />
                                                            <ToolTip>
                                                                <PeriodSpan>
                                                                    승차 일자 :
                                                                </PeriodSpan>
                                                                <br />
                                                                {student?.tickets?.map(
                                                                    (
                                                                        item: any,
                                                                        idx: number
                                                                    ) => {
                                                                        return (
                                                                            <PeriodSpan
                                                                                key={
                                                                                    idx
                                                                                }
                                                                            >
                                                                                {
                                                                                    item
                                                                                }
                                                                            </PeriodSpan>
                                                                        );
                                                                    }
                                                                )}
                                                            </ToolTip>
                                                        </>
                                                    )}
                                            </ToolTipWrapper>
                                        </Student>
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </StudentWrapper>
                </BoardBox>
            )}
            {showModal && (
                <StudentPopUp
                    id={showModal}
                    handler={setShowModal}
                    contents={
                        <StudentList
                            onClose={setShowModal}
                            enrolledStudentList={enrolledStudentList}
                            pickUpStationId={stationId}
                            takeOffStationId={takeOffStationId}
                            edit={edit}
                            setEdit={setEdit}
                        />
                    }
                />
            )}
        </>
    );
};

export const Student = styled.li<StudentProps>`
    margin-bottom: 4px;
    line-height: 1;
    :last-child {
        margin-bottom: 0;
    }
    ${({ studentId, thisStudentId }) =>
        studentId &&
        Number(thisStudentId) !== studentId &&
        css`
            color: #dedede;
            a,
            span {
                color: #dedede !important;
            }
        `};
    p {
        display: block;
        line-height: 1.8;
    }
`;

export const NoShow = styled.span`
    color: ${({ theme }) => theme.colors.orange};
    margin-left: 4px;
`;

export const BoardBox = styled.div<BoardBoxProps>`
    display: flex;
    border-radius: ${(props) =>
        props.edit?.length === 0 && props.busType === "하원"
            ? "8px"
            : "0px 0px 8px 8px"};
    border-top: ${(props) =>
        props.edit?.length === 0 && props.busType === "하원"
            ? "1px solid #e7e7e7"
            : "none"};
    border-right: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    border-left: 1px solid #e7e7e7;
    background: #f5f5f5;
    padding: 16px;
    row-gap: 12px;
    align-items: center;
    position: relative;
    min-width: 667px;
    max-width: 667px;
`;

type BoardBoxProps = {
    edit?: any;
    busType?: string;
};

type BorderProps = {
    border?: number;
};

export const ButtonWrapper = styled.div<BorderProps>`
    border-radius: ${(props) =>
        props?.border === 0 ? "8px" : "8px 8px 0px 0px"};
    border: 1px solid #e7e7e7;
    background-color: #fff;
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 667px;
    max-width: 667px;
`;

export const EditBoardBox = styled.div`
    display: flex;
    background: #fff;
    align-items: center;
    border-right: 1px solid #e7e7e7;
    border-left: 1px solid #e7e7e7;
    position: relative;
    min-width: 667px;
    max-width: 667px;
`;

export const Name = styled(Link)`
    line-height: 14px;
    color: #585858;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.36px;
    :visited {
        color: #585858;
    }
`;

export const BoardTitle = styled.div`
    min-width: 36px;
    height: 18px;
    margin-right: 10px;
    position: absolute;
    top: 15px;
    left: 16px;
    span {
        display: inline-flex;
        border-radius: 30px;
        background-color: ${({ theme }) => theme.colors.green};
        color: ${({ theme }) => theme.colors.white};
        padding: 0 8px;
        font-size: 10px;
        font-weight: 800;
        line-height: 18px;
    }
`;
export const StudentWrapper = styled.ul`
    width: 100%;
    max-width: 585px;
    margin-left: 62px;
    font-weight: bold;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 12px;
`;

export const EditStudentContainer = styled.ul`
    width: 100%;
    max-width: 600px;
    margin-left: 62px;
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 8px;
    column-gap: 8px;
`;

export const DisabledEditStudent = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 6px;
    height: 30px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(208, 208, 208, 0.6);
    border-radius: 4px;
    font-size: 12px;
    background-color: #e7e7e7;
    opacity: 0.6;
    span {
        padding: 7px 5px 5px 5px;
        font-family: NanumSquareRound;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;
    }
`;

const ToolTipWrapper = styled.div`
    position: relative;
`;

const ToolTip = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    flex-direction: column;
    position: absolute;
    width: 163px;
    padding: 12px;
    border-radius: 4px;
    background: #333;
    top: 8px;
    left: -35px;
    z-index: 9999999;
`;

const ToolTipBoxVector = styled.div`
    position: absolute;
    top: 3px;
    left: 40px;
    width: 8px;
    height: 5px;
    background-image: url(${ToolTipVector});
`;

const PeriodSpan = styled.span`
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
`;

const AddButton = styled.button`
    width: 83px;
    height: 24px;
    display: flex;
    padding: 3px 8px;
    align-items: center;
    gap: 2px;
    border-radius: 100px;
    background: #ffd100;
    border: none;
`;

const AddImg = styled.div`
    background: url(${PlusIcon}) center center no-repeat;
    width: 18px;
    height: 19px;
`;

const AddText = styled.span`
    font-family: NanumSquareRound;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
`;

type WarningProps = {
    overBookingWarning?: boolean;
    duplicateId?: Number[];
    studentId?: number;
};
export const EditStudentWrapper = styled.div<WarningProps>`
    border-radius: 4px;
    border: ${(props) =>
        props?.overBookingWarning ||
        props?.duplicateId?.includes(props.studentId)
            ? "1px solid #E74D41"
            : "1px solid rgba(208, 208, 208, 0.6)"};
    background-color: ${(props) =>
        props?.overBookingWarning ||
        props?.duplicateId?.includes(props.studentId)
            ? "#FDEDEC"
            : "#fff"};
    display: flex;
    width: 191px;
    padding: 8px 6px;
    justify-content: space-between;
    align-items: center;
`;

export const EditStudentSpan = styled.span`
    color: #000;
    font-family: NanumSquareRound;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
`;

export const DeleteImg = styled.img`
    width: 14px;
    height: 14px;
    cursor: pointer;
`;

export const MoveImg = styled.img`
    width: 14px;
    height: 14px;
    margin-right: 4px;
    cursor: pointer;
`;

export const Block = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(239, 239, 239, 0.6);
    z-index: 1;
    width: 100%;
    height: 100%;
`;
