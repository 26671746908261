import React, {
  ChangeEvent,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import styled from "styled-components";
import { isNil, orElse } from "../../../components/ValidataionUtil";

type TextInputProps = {
  readOnly: boolean;
  required: boolean;
  default?: string;
  containerStyle?: CSSProperties;
  resetOff?: boolean;
  onChange?(value: string): void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">;
export type TextInputRef = {
  getValue(): string;
  setValue(t: string): void;
  reset(): void;
};

function TextInputBase(
  props: TextInputProps,
  ref: ForwardedRef<TextInputRef>
): ReactElement {
  const [value, setValue] = useState<string>(orElse(props.default, ""));
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value;
      setValue(v);

      if (!isNil(props.onChange)) {
        props.onChange(v);
      }
    },
    [props.onChange, setValue]
  );

  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      getValue(): string {
        return value;
      },
      setValue(t: string): void {
        setValue(t);
      },
      reset(): void {
        setValue("");
      },
    }),
    [value, setValue]
  );
  return (
    <Container style={props.containerStyle}>
      <Input
        {...props}
        contentEditable={!props.readOnly}
        value={value}
        onChange={onChange}
        resetOff={props.resetOff}
        className={props.className}
        onFocus={onFocus}
        onBlur={onBlur}
        isFocused={isFocused}
      />
    </Container>
  );
}

const TextInput = forwardRef(TextInputBase);
export default TextInput;

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Input = styled.input<{
  value: string;
  resetOff: boolean;
  isFocused: boolean;
}>`
  font-family: NanumSquareRound;
  width: 100%;
  height: 100%;
  border: none;
  padding-left: 16px;
  padding-right: ${(props) => (props.resetOff ? 0 : "30px")};
  border-radius: 6px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  background: #fff;
  cursor: ${(props) => (props.readOnly ? "not-allowed" : "default")};
  color: ${(props) => (props.readOnly ? "#999999" : "#000000")};

  &::placeholder {
    color: #a7a9ac;
    font-size: 12px;
    font-weight: 400;
  }
`;
