import React from "react";
import styled from "styled-components";

import { media } from "styles/media";

// images
import AccountMenuIcon from "assets/images/icons/account_menu_icon.svg";

export type AcademyInfoTypes = {
    id: number;
    name: string;
};
interface MasterDashboardAcademyBoxProps {
    academyInfo: AcademyInfoTypes;
    isClicked?: boolean;
    onClick?: (academyInfo: AcademyInfoTypes) => void;
}
const MasterDashboardAcademyBox = ({
    academyInfo,
    isClicked = false,
    onClick = () => {}
}: MasterDashboardAcademyBoxProps) => {
    const handleAcademyBoxClick = () => onClick(academyInfo);

    return (
        <AcademyBox isClicked={isClicked} onClick={handleAcademyBoxClick}>
            <AccountMenuIconWrap>
                <img src={AccountMenuIcon} alt="Account Menu Icon" />
            </AccountMenuIconWrap>

            <AcademyNameLabel>{academyInfo.name}</AcademyNameLabel>
        </AcademyBox>
    );
};
export default MasterDashboardAcademyBox;

const AcademyBox = styled.div<{ isClicked: boolean }>`
    ${media.tablet} {
        flex-basis: 100%;

        min-width: 136px;
        max-width: 136px;
    }

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    width: 100%;
    max-width: calc(50% - 6px);

    height: auto;
    padding: 12px 12px 8px;

    border: ${({ isClicked }) => (isClicked ? "1px solid #FFD100" : "1px solid #D9D9D9")};
    border-radius: 12px;
    background-color: ${({ isClicked }) => (isClicked ? "#FFFAE5" : "#FFFFFF")};
    cursor: pointer;

    :hover {
        box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.2);
    }
`;
const AccountMenuIconWrap = styled.div`
    width: auto;
    height: auto;
    margin-bottom: 6px;
`;
const AcademyNameLabel = styled.label`
    font-size: 13px;
    font-weight: 700;
    word-break: break-all;
    cursor: pointer;
`;
