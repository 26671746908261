import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CheckIcon from "components/atoms/icons/check.svg";

type SelectBoxProps = {
    isSelected?: boolean;
    bold?: boolean;
    children?: string;
    onClick?: () => void;
    checked?: string | undefined;
};
const SelectBox = ({
    bold,
    children = "",
    isSelected = false,
    onClick = () => {},
    checked,
}: SelectBoxProps) => {
    return (
        <SelectBoxWrap
            // bold={bold}
            isSelected={isSelected}
            onClick={() => onClick()}
        >
            <CheckedWrap>
                {checked !== undefined && isSelected && (
                    <img src={CheckIcon} alt="" />
                )}
            </CheckedWrap>

            <TextWrap>{children}</TextWrap>
        </SelectBoxWrap>
    );
};
export default SelectBox;

type SelectBoxWrapProps = {
    bold?: boolean;
    isSelected?: boolean;
};

const SelectBoxWrap = styled.li<SelectBoxWrapProps>`
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    padding: 8px 20px 8px 4px;
    font-size: 12px;
    /* font-weight: 400; */
    /* font-weight: ${({ bold }) => bold && "700"}; */
    font-weight: ${({ isSelected }) => isSelected && "700"};
    border-radius: 4px;
    background-color: #ffffff;
    cursor: pointer;
    :hover {
        /* background-color: #fffbe5; */
        background-color: #f7f7f7;
        border-radius: 0px;
    }
`;

const TextWrap = styled.div`
    font-weight: 400;
    font-size: 12px;
    color: #1e2939;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    letter-spacing: -0.36px;
    font-family: NanumSquareRound;
`;

const CheckedWrap = styled.div`
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    margin-right: 6px;
`;
