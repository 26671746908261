import create from "zustand";
import { AcademyInfoTypes } from "components/molecules/masterDashboardAcademyBox";

type MasterDashboardState = {
    academies: AcademyInfoTypes[];
    setAcademies: (academies: AcademyInfoTypes[]) => void;

    clickedAcademyId: number;
    setClickedAcademyId: (clickedAcademyId: number) => void;

    clickedAcademyInfo: AcademyInfoTypes;
    setClickedAcademyInfo: (clickedAcademyInfo: AcademyInfoTypes) => void;

    recentAcademies: AcademyInfoTypes[];
    setRecentAcademies: (academies: AcademyInfoTypes[]) => void;
};

export const useMasterDashboardStore = create<MasterDashboardState>(set => ({
    academies: JSON.parse(localStorage.getItem("academies") ?? "[]"),
    setAcademies: (academies: AcademyInfoTypes[]) => set({ academies }),

    clickedAcademyId: Number(localStorage.getItem("academyId") ?? 0),
    setClickedAcademyId: (clickedAcademyId: number) => set({ clickedAcademyId }),

    clickedAcademyInfo: JSON.parse(localStorage.getItem("academyInfo") ?? `{ "id": 0, "name": "" }`),
    setClickedAcademyInfo: (clickedAcademyInfo: AcademyInfoTypes) => set({ clickedAcademyInfo }),

    recentAcademies: JSON.parse(localStorage.getItem("recentAcademies") ?? `[]`),
    setRecentAcademies: (recentAcademies: AcademyInfoTypes[]) => set({ recentAcademies })
}));
