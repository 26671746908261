import React, {Dispatch, SetStateAction, useMemo} from "react";
import styled from "styled-components";
import SVGImage from "./SVGImage";
import LeftArrow from "../../assets/images/icons/left_arrow.svg";
import DoubleLeftArrow from "../../assets/images/icons/double_left_arrow.svg";
import RightArrow from "../../assets/images/icons/right_arrow.svg";
import DoubleRightArrow from "../../assets/images/icons/double_right_arrow.svg";

type PaginationProps = {
    num: number;
    perPage: number;
    currentPage: number;
    setCurrentPage: Dispatch<SetStateAction<number>>;
    backgroundColor?: string;
    marginTop?: string;
    height?: string;
    borderRadius?: string;
    columnGap?: string;
    pageExposeCount: number;
};

function Pagination(props: PaginationProps) {
    const pageList: number[] = [];
    const totalPages = Math.ceil(props.num / props.perPage);

    for (let i = 1; i <= totalPages; i++) {
        pageList.push(i);
    }

    const goToFirstPage = () => {
        if (props.currentPage <= 1) {
            return;
        }

        props.setCurrentPage(1);
    };

    const goToLastPage = () => {
        if (props.currentPage > totalPages - 1) {
            return;
        }

        props.setCurrentPage(totalPages);
    };

    const goToNextPage = () => {
        if (props.currentPage >= totalPages) {
            return;
        }
        props.setCurrentPage(props.currentPage + 1);
    };

    const goToPrevPage = () => {
        if (props.currentPage <= 1) {
            return;
        }

        props.setCurrentPage(props.currentPage - 1);
    };

    const handleCurrentPageNumber = (idx: number) => {
        props.setCurrentPage(idx);
    };

    const pageNumbers = useMemo(() => {
        const halfWay = Math.floor(props.pageExposeCount / 2);
        let startPage = Math.max(props.currentPage - halfWay, 1);
        let endPage = startPage + props.pageExposeCount - 1;

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(endPage - props.pageExposeCount, 1);
        }

        if (totalPages > props.pageExposeCount) {
            if (props.currentPage > halfWay && props.currentPage < totalPages - halfWay + 1) {
                startPage = props.currentPage - halfWay;
                endPage = props.currentPage + halfWay;
            } else if (props.currentPage <= halfWay) {
                startPage = 1;
                endPage = props.pageExposeCount;
            } else {
                startPage = totalPages - props.pageExposeCount + 1;
                endPage = totalPages;
            }
        }

        return Array.from({length: endPage - startPage + 1}, (_, i) => startPage + i);
    }, [props.currentPage, totalPages, props.pageExposeCount]);

    return (
        <Container backgroundColor={props.backgroundColor} marginTop={props.marginTop} height={props.height} borderRadius={props.borderRadius} columnGap={props.columnGap}>
            <ImgWrapper onClick={goToFirstPage}>
                <Img source={DoubleLeftArrow} />
            </ImgWrapper>
            <ImgWrapper onClick={goToPrevPage}>
                <Img source={LeftArrow} />
            </ImgWrapper>

            {pageNumbers.map((page: number, idx: number) => (
                <PageNumber currentPage={props.currentPage} page={page} key={idx} onClick={() => handleCurrentPageNumber(page)}>
                    {page}
                </PageNumber>
            ))}
            <ImgWrapper onClick={goToNextPage}>
                <Img source={RightArrow} />
            </ImgWrapper>
            <ImgWrapper onClick={goToLastPage}>
                <Img source={DoubleRightArrow} />
            </ImgWrapper>
        </Container>
    );
}

export default Pagination;

type ContainerProps = {
    backgroundColor?: string;
    marginTop?: string;
    height?: string;
    borderRadius?: string;
    columnGap?: string;
};

const Container = styled.div<ContainerProps>`
    background: ${(props) => (props.backgroundColor ? props.backgroundColor : "#fff")};
    height: 22px;
    justify-content: center;
    display: flex;
    align-items: center;
    column-gap: ${(props) => (props.columnGap ? props.columnGap : "20px")};
    height: ${(props) => (props.height ? props.height : "auto")};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "13px")};
    border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
`;

const ImgWrapper = styled.div`
    width: 14px;
    height: 14px;
`;
const Img = styled(SVGImage)`
    width: 100%;
    height: 100%;
`;

type PageNumberProps = {
    currentPage: number;
    page: number;
};

const PageNumber = styled.div<PageNumberProps>`
    font-size: 11px;
    background: ${(props) => (props.currentPage === props.page ? "#ffcd00" : "")};
    width: 18px;
    height: 18px;
    border-radius: 100%;
    text-align: center;
    font-weight: ${(props) => (props.currentPage === props.page ? "800" : "300")};
    color: ${(props) => (props.currentPage === props.page ? "#000" : "#999")};
    line-height: 18px;

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    }
`;
