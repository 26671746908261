import React, {
    useContext,
    Fragment,
    useEffect,
    useState,
    useRef,
} from "react";
import styled, { css } from "styled-components";
import { BusServiceContext } from "context/busService";
import { media } from "styles/media";
import {
    Column,
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useRowSelect,
} from "react-table";
import { Box, BoxHeader } from "styles/layout";
import BusRequest from "http/bus";
import { TimeInput } from "../TimeInput";
import { BusSeatStatus } from "../students/BusSeatStatus";

const busRequest = new BusRequest();

const BoxBody = styled.div`
    position: relative;
`;

const TableBox = styled.div`
    /* overflow: auto;
    ${media.tablet} {
        overflow: visible;
    } */
    overflow: visible;
    padding: 0 15px;
    width: calc(100% + 30px);
    margin-left: -15px;
    ${media.tablet} {
        height: calc(100vh - 408px);
        overflow-x: visible;
        overflow-y: auto;
    }
    table {
        width: 100%;
        border-spacing: 0;
        border-top: 1px solid #e1e1e1;
        ${media.tablet} {
            min-width: auto;
        }
        thead {
            tr {
                color: ${({ theme }) => theme.colors.blue};
                font-weight: 800;
                :nth-child(1) {
                    display: none;
                }
                th {
                    background-color: white;
                    top: 70px;
                    box-shadow: 0px 1px 0px 0 rgba(0, 0, 0, 0.1);
                }
            }
        }
        tr {
            border-bottom: 1px solid #e1e1e1;
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }
        tbody {
            tr {
                transition: 0.3s all ease-out;
                :hover {
                    border-radius: 10px;
                    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                }
            }
        }

        th,
        td {
            text-align: center;
            margin: 0;
            padding: 15px 10px;
            border-bottom: 1px solid #e1e1e1;
            line-height: 1.6;
            vertical-align: middle;
        }

        .small {
            width: 76px;
        }
    }
`;

const BusSearch = styled.div`
    display: flex;
    width: 100%;
    padding: 12px 14px;
    margin-top: 20px;
    background: #f7f7f7;
    border-radius: 4px;
    .text {
        font-family: NanumSquareRound;
        font-style: normal;
        font-weight: 800;
        font-size: 13px;
        color: #174490;
        line-height: 15px;
        display: flex;
        padding: 2px;
        align-items: center;
        min-width: 30px;
    }

    .select-arrow {
        width: 9px;
        height: 9px;
        display: inline-block;
        position: absolute;
        top: 14px;
        right: 14px;
        span {
            width: 6px;
            height: 2px;
            position: absolute;
            background-color: #c4c4c4;
            display: inline-block;
            border-radius: 6px;
            transition: all 0.2s ease;
            &:first-of-type {
                right: 0;
                transform: rotate(-45deg);
            }
            &:last-of-type {
                left: 0;
                transform: rotate(45deg);
            }
        }
    }
`;

const Select = styled.select`
    display: block;
    padding: 2px;
    width: 100%;
    height: 30px;
    line-height: 16px;
    font-size: 14px;
    border: 1px solid #cacaca;
    border-radius: 60px;
    background: #f6f7f9;
    ::placeholder {
        color: #cecece;
        font-size: 14px;
        letter-spacing: 0;
    }
    :focus,
    :hover {
        outline: none;
        border: 1px solid ${({ theme }) => theme.colors.yellow};
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
        border: 1px solid ${({ theme }) => theme.colors.yellow};
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
    border-radius: 60px;
    padding: 0 12px;
    background: #fff;
`;

const SearchBtn = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 40px;
    margin-top: 16px;
    margin-bottom: 28px;
    div {
        padding: 12px 23px;
        position: absolute;
        left: 50%;
        width: 72px;
        height: 100%;
        font-family: NanumSquareRound;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        color: #000;
        line-height: 16px;
        margin: 0 -36px;
        /* identical to box height */

        background: #ffcd00;
        border-radius: 30px;
        cursor: pointer;

        :active {
            background: #ffae01;
        }
    }
`;

interface TableProps {
    columns: Array<Column>;
    data: any;
    selectedRow: number;
    getRowProps: any;
}

interface BusServiceStatusProps {
    status: number;
}

interface BusServiceStatusSelectedProps {
    status: number;
    selected: boolean;
}

const Tr = styled.tr<BusServiceStatusSelectedProps>`
    background-color: #fff;
    ${({ status }) =>
        status > 0
            ? css`
                  cursor: pointer;
              `
            : css`
                  cursor: pointer;
              `};
    ${({ selected }) =>
        selected
            ? css`
                  background-color: #f7f7f7;
              `
            : css`
                  background-color: #fff;
              `};
`;

const EmptyDiv = styled.div`
    padding-top: 20vh;
    background-color: #fff;
    width: 100%;
    height: calc(80vh - 308px);
    font-family: NanumSquareRound;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    align-items: center;
    text-align: center;
    letter-spacing: -0.03em;
`;

function Table({
    columns,
    data,
    selectedRow,
    getRowProps = () => ({}),
}: TableProps) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    }: any = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useRowSelect
    );

    // React.useEffect(() => {
    //         rows.forEach((row: any) => {
    //             //console.log(row)
    //         })
    //     }, [rows]
    // )
    // Render the UI for your table
    return (
        <Fragment>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup: any) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th
                                    {...column.getHeaderProps([
                                        column.getSortByToggleProps(),
                                        { className: column.className },
                                    ])}
                                >
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? " ▼"
                                                : " ▲"
                                            : ""}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                {rows.length === 1 && rows[0].original.search ? (
                    <tbody
                        style={{
                            backgroundColor: "#fff",
                            height: "10px",
                            width: "100%",
                            position: "absolute",
                        }}
                    ></tbody>
                ) : (
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row: any, i: any) => {
                            prepareRow(row);
                            return (
                                <Tr
                                    status={row.original.status}
                                    {...row.getRowProps(getRowProps(row))}
                                    selected={selectedRow === i}
                                >
                                    {row.cells.map((cell: any) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </tbody>
                )}
            </table>
            {rows.length === 0 && <EmptyDiv>검색 결과가 없습니다.</EmptyDiv>}
        </Fragment>
    );
}

const BusServiceStatus = styled.div<BusServiceStatusProps>`
    border-radius: 3px;
    font-size: 10px;
    font-weight: 800;
    color: ${({ theme }) => theme.colors.white};
    display: inline-block;
    padding: 3px 0;
    width: 55px;
    ${({ status }) =>
        status === -1
            ? css`
                  background-color: ${({ theme }: any) =>
                      theme.colors.operated};
                  color: ${({ theme }: any) => theme.colors.operatedFont};
              `
            : status === 0
            ? css`
                  background-color: ${({ theme }: any) =>
                      theme.colors.waitOperating};
                  color: ${({ theme }: any) => theme.colors.waitOperatingFont};
              `
            : status === 1
            ? css`
                  background-color: ${({ theme }: any) =>
                      theme.colors.operating};
                  color: ${({ theme }: any) => theme.colors.operatingFont};
              `
            : css`
                  background-color: #e9e9e9;
                  color: #afafaf;
              `};
`;

interface paramInterface {
    date: string;
    busId?: number;
    busTime?: string;
}

export const AcademyBusList = () => {
    const {
        date,
        setBusServiceId,
        setDispatchCode,
        setCourseCode,
        setStepActive,
        setStatus,
        setSelectedDispatchData,
    } = useContext(BusServiceContext);

    const [busList, setBusList] = useState([]);
    const [busId, setBusId] = useState(-1);
    const [busTime, setBusTime] = useState("00:00");
    const [data, setData] = useState<any[]>([]);
    const [timerOn, setTimerOn] = useState(false);
    const [busSearch, setBusSearch] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState(-1);

    const tmpDate = useRef<string>(date);
    const tmpBusId = useRef<number>(-1);
    const tmpBusTime = useRef<string>("00:00");

    const getData = async () => {
        //console.log("test:" + busTime)
        setTimerOn(false);

        let param: paramInterface = {
            date: date,
            busId: tmpBusId.current,
            busTime: tmpBusTime.current,
        };
        await busRequest.getDispatchListWithParam(
            param,
            (response: any) => {
                if (
                    response.status === -1 ||
                    (response.status === 200 && response.data.code !== "OK")
                ) {
                    alert(`[${response.data.code}] ${response.data.message}`);
                } else {
                    setData(response.data.data);
                }
            },
            () => {
                setData([]);
            }
        );
    };

    useEffect(() => {
        let interval: any = null;

        if (busSearch) {
            tmpDate.current = date;
            tmpBusId.current = busId;
            tmpBusTime.current = busTime;
        }

        if (timerOn) {
            interval = setInterval(async () => {
                //console.log("test:" + busTime)
                let param: paramInterface = {
                    date: tmpDate.current,
                    busId: tmpBusId.current,
                    busTime: tmpBusTime.current,
                };
                await busRequest.getDispatchListWithParam(
                    param,
                    (response: any) => {
                        setBusSearch(false);
                        if (
                            response.status === -1 ||
                            (response.status === 200 &&
                                response.data.code !== "OK")
                        ) {
                            alert(
                                `[${response.data.code}] ${response.data.message}`
                            );
                        } else {
                            setData(response.data.data);
                        }
                    },
                    () => {
                        setBusSearch(false);
                        setData([]);
                    }
                );
            }, 60000);
        } else if (!timerOn) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [timerOn, busTime, busId, date, busSearch]);

    useEffect(() => {
        busRequest.getBusListOfAcademy(
            date,
            (response: any) => {
                if (
                    response.status === -1 ||
                    (response.status === 200 && response.data.code !== "OK")
                ) {
                    console.log(
                        `[${response.data.code}] ${response.data.message}`
                    );
                } else {
                    if (response.data.data.length > 0) {
                        setBusList(response.data.data);
                        if (firstLoading) {
                            dispatchSearch();
                            setFirstLoading(false);
                        }
                    } else {
                        alert(
                            "해당 요일에 대한 배차가 존재하지 않습니다.\n다른 일자를 선택해주세요."
                        );
                        setBusList([]);
                    }
                }
            },
            (error: any) => {
                console.log("여기");
            }
        );
    }, [date, firstLoading]);

    // 60초 마다 운행리스트 갱신
    function dispatchSearch() {
        const isSearching = [{ search: true }];
        setData(isSearching);
        setBusServiceId(null);
        setTimeout(function () {
            //Start the timer
            getData().then(() => {
                setTimerOn(true);
                setBusSearch(true);
            });
        }, 300);
    }

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                columns: [
                    {
                        Header: "버스 / 배차명",
                        accessor: "dispatch.name",
                    },
                    {
                        Header: "상태",
                        accessor: "status",
                        className: "small",
                        Cell: (cellInfo: { row: any }) =>
                            cellInfo.row.original.status === -1 ? (
                                <BusServiceStatus status={-1}>
                                    운행전
                                </BusServiceStatus>
                            ) : cellInfo.row.original.status === 0 ? (
                                <BusServiceStatus status={0}>
                                    운행대기
                                </BusServiceStatus>
                            ) : cellInfo.row.original.status === 1 ? (
                                <BusServiceStatus status={1}>
                                    운행중
                                </BusServiceStatus>
                            ) : cellInfo.row.original.status === 3 ? (
                                <BusServiceStatus status={2}>
                                    미운행
                                </BusServiceStatus>
                            ) : (
                                <BusServiceStatus status={2}>
                                    운행완료
                                </BusServiceStatus>
                            ),
                    },
                    {
                        Header: "점유율",
                        accessor: "usedSeat",
                        className: "small",
                        Cell: (info: any) => {
                            return (
                                <BusSeatStatus
                                    data={info.data[info.row.index]}
                                />
                            );
                        },
                    },
                ],
            },
        ],
        []
    );

    console.log(data);

    return (
        <Fragment>
            <Box>
                <BoxHeader style={{ display: "block" }}>
                    <h2>운행 목록</h2>
                    <BusSearch>
                        <span className={"text"}>버스</span>
                        <div
                            style={{
                                position: "relative",
                                display: "flex",
                                width: "100%",
                            }}
                        >
                            <Select
                                autoFocus
                                id="busId"
                                onChange={(e) => {
                                    setBusId(parseInt(e.target.value));
                                    console.log(parseInt(e.target.value));
                                }}
                            >
                                <option value="-1">전체</option>
                                {busList.map((bus: any) => {
                                    return (
                                        <option key={bus.id} value={bus.id}>
                                            {bus.name}
                                        </option>
                                    );
                                })}
                            </Select>
                            <span className={"select-arrow"}>
                                <span />
                                <span />
                            </span>
                        </div>
                        <span className={"text"} style={{ marginLeft: "12px" }}>
                            시간
                        </span>
                        <TimeInput
                            onChange={(value) => {
                                if (value === null) return "00:00";
                                setBusTime(value);
                            }}
                            value={busTime}
                            validation="true"
                            onKeyPress={(e: any) => {
                                if (e.key === "Enter") {
                                    dispatchSearch();
                                }
                            }}
                        />
                    </BusSearch>
                    <SearchBtn>
                        <div onClick={() => dispatchSearch()}>검색</div>
                    </SearchBtn>
                </BoxHeader>
                <BoxBody>
                    <TableBox>
                        <Table
                            columns={columns}
                            data={data}
                            selectedRow={selectedRow}
                            getRowProps={(row: any) => ({
                                onClick: () => {
                                    row.toggleRowSelected();
                                    setSelectedRow(row.index);
                                    setStepActive({
                                        list: false,
                                        detail: true,
                                    });
                                    console.log(row.original);
                                    setBusServiceId(Number(row.original.id));
                                    setDispatchCode(
                                        row.original.dispatch?.code
                                    );
                                    setCourseCode(
                                        row.original.dispatch?.courseCode
                                    );
                                    setStatus(row.original.status);
                                    setSelectedDispatchData(row.original);
                                },
                            })}
                        />
                    </TableBox>
                </BoxBody>
            </Box>
        </Fragment>
    );
};
