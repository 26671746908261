import React from "react";
import ReactDOM from "react-dom";
import GlobalStyles from "./styles/globalStyles";
import theme from "./styles/theme";
import { ThemeProvider } from "./styles/theme-components";
import { App } from "App";

ReactDOM.render(
    <React.Fragment>
        <GlobalStyles />
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </React.Fragment>,
    document.getElementById("root")
);
