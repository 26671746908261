import Request from "./request";


class MasterDashboardRequest extends Request {
    getAcademies = (callback, errorCallback) => {
        this.get("/dashboard/academies", {
            callback,
            errorCallback
        });
    }
}

export default MasterDashboardRequest;
