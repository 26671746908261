import { BusServiceContext } from "context/busService";
import { GlobalContext } from "context/global";
import React, { useContext } from "react";
import { styled } from "styles/theme";
import { Button, ButtonGrey, ButtonGroup } from "styles/ui";
import ScheduleRequest from "../../http/schedule";

const scheduleRequest = new ScheduleRequest();

interface ConfirmEditScheduleProps {
    params: any;
    handler: any;
    chgSchedules: [];
}

const Title = styled.h3`
    margin-bottom: 1em;
    width: 100%;
    text-align: center;
`;
const Text = styled.p`
    font-weight: normal;
    width: 100%;
    text-align: center;
    line-height: 1.6;
    font-size: 14px;
    margin-bottom: 20px;
    span {
        color: ${({ theme }) => theme.colors.orange};
        font-weight: 800;
    }
`;

export const ConfirmEditSchedule: React.FC<ConfirmEditScheduleProps> = ({ params, handler, chgSchedules }) => {
    const { setToast } = useContext(GlobalContext);
    const { reloadTrigger, setReloadTrigger, setScheduleEdit } = useContext(BusServiceContext);

    const saveSchedules = () => {
        const data = getChangeSchedule();

        scheduleRequest.updateScheduleList(
            data,
            (response: any) => {
                console.log(response);
                if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                    console.log(`[${response.data.code}] ${response.data.message}`);
                    setToast({
                        toggle: true,
                        toastStatus: "fail",
                        message: `오류가 발생했습니다. ${response.data.message}`
                    });
                } else {
                    handler(false);
                    setToast({
                        toggle: true,
                        toastStatus: "success",
                        message: `저장되었습니다.`
                    });
                    setScheduleEdit(false);
                }
            },
            (error: any) => {
                console.log(error);
            }
        );
    };

    function getChangeSchedule() {
        let result: any[] = [];
        console.log(chgSchedules);

        chgSchedules.forEach((info: any) => {
            const schedule: any = {
                scheduleId: info.scheduleId,
                pickupStationId: info.pickUpStation.id,
                takeoffStationId: info.takeOffStation.id
            };
            result.push(schedule);
        });

        return { scheduleStationsList: result };
    }

    return (
        <div>
            <Title>{params.title}</Title>
            <Text>{params.msg}</Text>
            <ButtonGroup>
                <ButtonGrey
                    style={{ width: "90px", padding: "0" }}
                    onClick={e => {
                        if (params.isSave) {
                            e.preventDefault();
                            handler(false);
                        } else {
                            e.preventDefault();
                            handler(false);
                        }
                    }}
                >
                    아니요
                </ButtonGrey>
                <Button
                    style={{ width: "90px", padding: "0" }}
                    onClick={e => {
                        if (params.isSave) {
                            e.preventDefault();
                            saveSchedules();
                        } else {
                            e.preventDefault();
                            setScheduleEdit(false);
                            setReloadTrigger(reloadTrigger + 1);
                            handler(false);
                        }
                    }}
                >
                    예
                </Button>
            </ButtonGroup>
        </div>
    );
};
