import React from "react";

interface useFormatPhoneNumberProps {
    phone: any;
}

export const useFormatPhoneNumber: React.FC<useFormatPhoneNumberProps> = props => {
    let phone = props.phone.replace(/[^0-9]/g, "");

    let seoul = 0;
    if (phone.substring(0, 2) === "02") {
        seoul = 1;
    }

    let phoneCount = phone.length;
    if (phoneCount < 4 - seoul) {
        return phone;
    } else if (phoneCount < 7 - seoul) {
        return `${phone.substr(0, 3 - seoul)}-${phone.substr(3 - seoul)}`;
    } else if (phoneCount < 9 - seoul) {
        return `${phone.substr(0, 4 - seoul)}-${phone.substr(4 - seoul)}`;
    } else if (phoneCount < 11 - seoul) {
        return `${phone.substr(0, 3 - seoul)}-${phone.substr(3 - seoul, 3)}-${phone.substr(6 - seoul)}`;
    } else if (phoneCount < 12) {
        console.log(`${phone.substr(0, 3 - seoul)}-${phone.substr(3 - seoul, 4)}-${phone.substr(7 - seoul, 4)}`);
        return `${phone.substr(0, 3 - seoul)}-${phone.substr(3 - seoul, 4)}-${phone.substr(7 - seoul, 4)}`;
    }

    return phone;
};
