import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Optional } from "../../type/Common";
import styled from "styled-components";
import { isEmptyArray, isNil } from "../../components/ValidataionUtil";
import {
  alignCenter,
  color,
  flexColumn,
  flexRow,
  justifyCenter,
} from "../../styles/CommonStyle";
import SVGImage from "../../components/common/SVGImage";
import CheckedIcon from "../../assets/images/common/checkbox_on.svg";
import UncheckedIcon from "../../assets/images/common/checkbox_off.svg";

type TableListProps = {
  keys: string[];
  items: Optional<Map<string, ReactNode>[]>;
  useCheckBox?: boolean;
  useSorting?: boolean;
  checkedIconSource?: string;
  defaultCheckedIndices?: number[];
  disabledIndices?: number[];
  placeholder?: string;
  tableContainerStyle?: CSSProperties;
  tableStyle?: CSSProperties;
  tableHeaderStyle?: CSSProperties;
  tableHeaderRowStyle?: CSSProperties;
  tableBodyStyle?: CSSProperties;
  tableBodyRowStyle?: CSSProperties;
  checkBoxStyle?: CSSProperties;
  onClickRow(idx: number): void;
  onClickAll?(indices: number[]): void;
  onChangeIndices?(value: number[]): void;
  getSortingType?: React.Dispatch<React.SetStateAction<SortConfig>>;
};
export type TableListRef = {
  setItems(items: Map<string, ReactNode>[]): void;
  getCheckedIndices(): number[];
  reset(): void;
};

export type SortConfig = {
  key: number;
  direction: "increase" | "decrease";
} | null;

function TableListBase(
  props: TableListProps,
  ref: ForwardedRef<TableListRef>
): ReactElement {
  const { useCheckBox = false, disabledIndices = [] } = props;
  const [listItems, setListItems] = useState<
    Optional<Map<string, ReactNode>[]>
  >([]);
  const [checkedIndices, setCheckedIndices] = useState<number[]>([]);
  const [sortingType, setSortingType] = useState<SortConfig>(null);

  const getIsChecked = useCallback(
    (index: number): boolean => {
      return checkedIndices.some((idx) => idx === index);
    },
    [checkedIndices]
  );

  const toggleCheckBox = useCallback(
    (index: number): void => {
      if (disabledIndices.includes(index)) {
        return;
      }

      if (getIsChecked(index)) {
        setCheckedIndices((prev) => prev.filter((idx) => idx !== index));
      } else {
        setCheckedIndices((prev) => [...prev, index]);
      }
    },
    [getIsChecked, setCheckedIndices, disabledIndices]
  );

  const onClickRow = useCallback(
    (idx: number) => {
      props.onClickRow(idx);
      toggleCheckBox(idx);
    },
    [props.onClickRow, toggleCheckBox]
  );

  const requestSort = useCallback(
    (key: number) => {
      if (isNil(sortingType)) {
        setSortingType({ key: key, direction: "decrease" });
      }

      if (!isNil(sortingType)) {
        if (sortingType.direction === "increase" || sortingType.key !== key) {
          setSortingType({ key: key, direction: "decrease" });
        }
        if (sortingType.direction === "decrease" && sortingType.key === key) {
          setSortingType({ key: key, direction: "increase" });
        }
      }
    },
    [sortingType, setSortingType]
  );

  const isCheckedAll = useMemo(() => {
    return checkedIndices.length === listItems.length - disabledIndices.length;
  }, [checkedIndices, listItems, disabledIndices]);

  const toggleAll = useCallback(() => {
    if (isCheckedAll) {
      if (!isNil(props.onClickAll)) {
        props.onClickAll([]);
      }

      setCheckedIndices([]);
      return;
    }

    if (!isNil(props.onClickAll)) {
      props.onClickAll(
        listItems
          .filter((_, idx) => !disabledIndices.includes(idx))
          .map((_, idx) => idx)
      );
    }

    setCheckedIndices(
      listItems
        .filter((_, idx) => !disabledIndices.includes(idx))
        .map((_, idx) => idx)
    );
  }, [
    listItems,
    isCheckedAll,
    disabledIndices,
    props.onClickAll,
    setCheckedIndices,
  ]);

  const CheckedAllIconComponent = useMemo(() => {
    if (isCheckedAll) {
      const source = !isNil(props.checkedIconSource)
        ? props.checkedIconSource
        : CheckedIcon;

      return <CheckIcon source={source} />;
    }

    return <CheckIcon source={UncheckedIcon} />;
  }, [props.checkedIconSource, isCheckedAll]);

  const HeadComponent = useMemo(() => {
    return (
      <tr>
        {useCheckBox ? (
          <th style={{ width: 80 }}>
            <CheckBoxContainer>
              <CheckBox style={props.checkBoxStyle} onClick={toggleAll}>
                {CheckedAllIconComponent}
              </CheckBox>
            </CheckBoxContainer>
          </th>
        ) : null}
        {props.keys.map((key: string, idx: number) => {
          return (
            <th
              key={`${key}_${idx}`}
              style={props.tableHeaderRowStyle}
              onClick={() => requestSort(idx)}
            >
              <ContentContainer>
                {key}
                {props.useSorting &&
                sortingType?.key === idx &&
                sortingType.direction === "decrease"
                  ? " ▼"
                  : props.useSorting &&
                    sortingType?.key === idx &&
                    sortingType.direction === "increase"
                  ? " ▲"
                  : ""}
              </ContentContainer>
            </th>
          );
        })}
      </tr>
    );
  }, [props.keys, useCheckBox, toggleAll, CheckedAllIconComponent]);

  const renderCheckedIcon = useCallback(
    (idx: number) => {
      if (getIsChecked(idx)) {
        const source = !isNil(props.checkedIconSource)
          ? props.checkedIconSource
          : CheckedIcon;

        return <CheckIcon source={source} />;
      }

      return <CheckIcon source={UncheckedIcon} />;
    },
    [props.checkedIconSource, getIsChecked]
  );

  const getTableBodyStyle = useCallback(
    (idx: number) => {
      if (disabledIndices.includes(idx)) {
        return {
          ...props.tableBodyStyle,
          opacity: 0.3,
        };
      }

      return props.tableBodyStyle;
    },
    [disabledIndices]
  );

  const BodyComponent = useMemo(() => {
    return listItems.map((item, idx) => {
      return (
        <tr
          style={getTableBodyStyle(idx)}
          key={idx}
          onClick={() => onClickRow(idx)}
        >
          {useCheckBox ? (
            <td style={{ width: 80 }}>
              <CheckBoxContainer>
                <CheckBox
                  style={props.checkBoxStyle}
                  onClick={() => toggleCheckBox(idx)}
                >
                  {renderCheckedIcon(idx)}
                </CheckBox>
              </CheckBoxContainer>
            </td>
          ) : null}
          {props.keys.map((k) => {
            return (
              <td key={`${k}_${idx}`} style={props.tableBodyRowStyle}>
                <ContentContainer>{item.get(k)}</ContentContainer>
              </td>
            );
          })}
        </tr>
      );
    });
  }, [
    listItems,
    props.keys,
    props.checkedIconSource,
    props.checkBoxStyle,
    renderCheckedIcon,
    getTableBodyStyle,
    onClickRow,
    toggleCheckBox,
  ]);

  useEffect(() => {
    setListItems(props.items);
  }, [props.items]);

  useEffect(() => {
    if (isNil(sortingType)) {
      return;
    }

    setListItems(props.items);
  }, [sortingType]);

  useEffect(() => {
    if (!isNil(sortingType)) {
      props.getSortingType(sortingType);
    }
  }, [sortingType]);

  useEffect(() => {
    if (isNil(props.onChangeIndices)) {
      return;
    }

    props.onChangeIndices(checkedIndices);
  }, [checkedIndices]);

  useEffect(() => {
    if (isNil(props.defaultCheckedIndices)) {
      return;
    }

    setCheckedIndices(props.defaultCheckedIndices);
  }, [props.defaultCheckedIndices, setCheckedIndices]);

  useImperativeHandle(
    ref,
    () => ({
      setItems(items: Map<string, React.ReactNode>[]) {
        setListItems(items);
      },
      getCheckedIndices(): number[] {
        return checkedIndices;
      },
      reset() {
        setCheckedIndices([]);
      },
    }),
    [checkedIndices]
  );

  if (isNil(listItems)) {
    return null;
  }

  return (
    <Container style={props.tableContainerStyle}>
      {isEmptyArray(listItems) ? (
        <EmptyContainer>
          <Text>{props.placeholder}</Text>
        </EmptyContainer>
      ) : (
        <Table cellPadding={0} cellSpacing={0} style={props.tableStyle}>
          <Thead style={props.tableHeaderStyle}>{HeadComponent}</Thead>
          <TBody style={props.tableBodyStyle}>{BodyComponent}</TBody>
        </Table>
      )}
    </Container>
  );
}

const TableList = forwardRef(TableListBase);
export default TableList;

const Container = styled.div`
  border-collapse: collapse;
  overflow: scroll;
  flex: 1;
  background: #ffffff;

  ::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }
`;

const Table = styled.table`
  width: 100%;
  font-family: "NanumSquareRound", "Pretendard", "Noto Sans Mono", monospace;
`;

const Thead = styled.thead`
  tr {
    padding: 1.2rem;
  }

  th {
    font-size: 12px;
    text-align: center;
    height: 46px;
    overflow: hidden;
    color: #174490;
    font-weight: 800;
    border-top: 1px solid ${color.grey1};
    border-bottom: 1px solid ${color.grey1};
    cursor: pointer;
  }
`;

const TBody = styled.tbody`
  tr {
    cursor: pointer;
    border: none;
    height: 60px;
    line-height: 14px;
  }

  td {
    height: 60px;
    font-size: 12px;
    text-align: center;
    align-items: center;
    line-height: 14px;
    padding: 16px 10px;
    border-bottom: 1px solid ${color.grey1};

    :nth-child(3) {
      width: 320px;
      height: 60px;
      text-align: left;
      vertical-align: middle;
    }
  }
`;

const ContentContainer = styled.div`
  ${flexRow};
  ${justifyCenter};
  ${alignCenter};
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const EmptyContainer = styled.div`
  ${flexColumn};
  width: 100%;
  justify-content: center;
  text-align: center;
  height: 10rem;
`;

const Text = styled.div`
  font-size: 14px;
  color: ${color.grey3};
  line-height: 2rem;
`;
const CheckBoxContainer = styled.div`
  ${flexRow};
  ${justifyCenter};
  ${alignCenter};
  height: 100%;
`;

const CheckBox = styled.div`
  ${flexRow};
  ${alignCenter};
  ${justifyCenter};
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const CheckIcon = styled(SVGImage)`
  width: 100%;
  height: 100%;
`;
