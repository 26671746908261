import React, { useState } from "react";
import { styled } from "styles/theme";
import { Button } from "styles/ui";

const Title = styled.h3`
    margin-bottom: 1em;
    width: 100%;
    text-align: center;
`;
const Text = styled.div`
    font-weight: normal;
    width: 100%;
    min-width: 320px;
    font-family: NanumSquareRound;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    padding: 10px;
    span {
        color: #174490;
    }
`;

export const Arrow = styled.span`
    margin: 0
    width: 8px;
    height: 8px;
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 177px;
    span {
        width: 7px;
        height: 2px;
        position: absolute;
        background: #bbb;
        display: inline-block;
        border-radius: 6px;
        transition: all 0.2s ease;
        &:first-of-type {
                top: 1px;
                left: 0;
                transform: rotate(-45deg);
        }
        &:last-of-type{
                top: 5px;
                left: 0;
                transform: rotate(45deg);
        }
    }   
`;
export const PopUpButtonGroup = styled.div`
    text-align: center;
    margin-top: 30px;
    button {
        display: inline-block;
        margin: 0 2px;
    }
`;

interface WorningProps {
    setPopUpSeatFul: any;
}

const WarningPopUp: React.FC<WorningProps> = ({ setPopUpSeatFul }) => {
    return (
        <div>
            <Title>수정 불가</Title>
            <Text>빈 좌석이 없어 수정이 불가능합니다.</Text>

            <PopUpButtonGroup>
                <Button
                    style={{ width: "98px", padding: "0", backgroundColor: "#FFCD00" }}
                    onClick={e => {
                        e.preventDefault();
                        setPopUpSeatFul(false);
                    }}
                >
                    확인
                </Button>
            </PopUpButtonGroup>
        </div>
    );
};

export default WarningPopUp;
